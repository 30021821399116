import PropTypes from "prop-types";
import React, { useRef, useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Form,
  Label,
  Input,
} from "reactstrap";
import { useStore1Selector, useStore1Dispatch } from "./../../index";
import { loginUser, Login } from "../../Redux/Slices/userSlice";
import Breadcrumb from "../../components/Common/Breadcrumb";
import MetaTagComp from "../../components/Common/MetaTags";
import Avatar from "../../assets/images/Avatar.png";
import ProfileForm from "./ProfileDetailsForm";
import ResetPasswordForm from "./ResetPassword";
import BankDetailsForm from "./BankDetails";

const Profile = () => {
  const userDet = useStore1Selector(loginUser);
  const dispatch = useStore1Dispatch();
  const [picture, setPicture] = useState(userDet?.data?.profilePic);
  const [profileServer, setProfileServer] = useState();
  const [profile, setProfile] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const role = userDet?.data?.roles[0];
  const getRoleText = () => {
    switch (role) {
      case "admin":
        return "Admin";
      case "admin_assistant":
        return "Assistant";
      case "admin_marketing":
        return "Marketing";
      case "admin_sales":
        return "Sales";
      case "admin_finance":
        return "Finance";
      case "admin_support":
        return "Support";
      default:
        return null;
    }
  };
  const refFileUpload = useRef(null);
  const onThumbChangeClick = () => {
    if (refFileUpload) {
      refFileUpload.current.dispatchEvent(new MouseEvent("click"));
    }
  };
  const changeThumb = (event) => {
    if (event.target.files && event.target.files[0]) {
      setProfileServer(event.target.files[0]);

      const reader = new FileReader();
      reader.onload = (loadEvent) => {
        setProfile(loadEvent.target.result);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };
  const roleDef = getRoleText();

  return (
    <React.Fragment>
      <div className="page-content mt-5">
        <MetaTagComp meta_tags="Faspro 24 | Profile" />

        <Container fluid>
          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="d-flex mx-3 justify-content-center align-items-center">
                      <img
                        src={
                          profile
                            ? profile
                            : !profile && !picture
                            ? Avatar
                            : !profile && picture
                            ? `${picture}`
                            : profile && picture
                            ? profile
                            : null
                        }
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail "
                      />
                      <Button
                        size="sm"
                        variant="separator-light"
                        className="btn-icon btn-icon-only position-absolute rounded s-0 b-0 mt-2"
                        onClick={onThumbChangeClick}
                      >
                        <i className="ion ion-md-image"></i>
                      </Button>
                      <input
                        type="file"
                        ref={refFileUpload}
                        className="file-upload d-none"
                        accept="image/*"
                        onChange={changeThumb}
                      />
                    </div>
                    <div className="align-self-center flex-1">
                      <div className="text-muted">
                        <h5>{getRoleText()}</h5>
                        <p className="mb-1">
                          {userDet?.data?.firstName} {userDet?.data?.lastName}
                        </p>
                        <p className="mb-0">{userDet?.data?.email}</p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <h4 className="card-title mb-4">Update profile details</h4>

          <Card>
            <CardBody>
              <ProfileForm profileServer={profileServer} />
            </CardBody>
          </Card>

          <h4 className="card-title mb-4">Update password</h4>

          <Card>
            <CardBody>
              <ResetPasswordForm />
            </CardBody>
          </Card>
          {roleDef === "Admin" ? (
            <div>
              <h4 className="card-title mb-4">Settings</h4>

              <Card>
                <CardBody>
                  <BankDetailsForm />
                </CardBody>
              </Card>
            </div>
          ) : null}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Profile;

import React, { useState } from "react";
import "./../Style.scss";
import {
  AvForm,
  AvField,
  AvRadio,
  AvRadioGroup,
} from "availity-reactstrap-validation";
import { Row, Col, Card, CardBody, Spinner } from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import usePost from "../../../../hooks/usePost";

function Form() {
  let history = useHistory();
  const { execute, pending, data: dataPost } = usePost();
  function handleValidSubmit(e, v) {
    e.preventDefault();
    const Method = "POST",
      endPoint = "auth/forgotPassword";
    const raw = JSON.stringify({
      email: v.email,
    });
    execute(endPoint, raw, Method, "Please verify your email");
  }

  if (dataPost?.status === "success") {
    window.setTimeout(() => {
      history.push("/login");
    }, 2000);
  }

  return (
    <div className="login-content-container">
      <div className="forget-pass">
        <Card className="overflow-hidden">
          <div className="login-header">
            <div className="text-primary text-center p-4">
              <h5 className="text-white font-size-20 p-2">Forget Password</h5>
            </div>
          </div>
          <CardBody>
            <AvForm
              className="form-horizontal mt-4"
              onValidSubmit={(e, v) => handleValidSubmit(e, v)}
            >
              <div className="mb-3">
                <AvField
                  name="email"
                  label="Email"
                  className="form-control"
                  type="email"
                  required
                />
              </div>

              <Row className="mb-3">
                <Col className="text-center">
                  <button
                    className="btn login-btn  waves-effect waves-light"
                    type="submit"
                  >
                    {!pending ? (
                      <span className="me-2">Forget Password</span>
                    ) : null}
                    {!pending ? null : (
                      <span>
                        {" "}
                        <Spinner as="span" animation="border" size="sm" />{" "}
                        Loading...
                      </span>
                    )}
                  </button>
                </Col>
              </Row>
            </AvForm>
          </CardBody>
        </Card>
        <div className="mt-5 text-center">
          <p>
            Remember It ?{" "}
            <Link to="login" className="fw-medium remember">
              {" "}
              Sign In here{" "}
            </Link>{" "}
          </p>
          <p> © {new Date().getFullYear()} FASPRO24 </p>
        </div>
      </div>
    </div>
  );
}

export default Form;

/* eslint-disable no-unused-expressions */

import { useState } from "react";
import {
  dangerMessage,
  successMessage,
  warningMessage,
} from "../components/Toast";

const usePostLogin = () => {
  const [postData, updatePostData] = useState({
    pending: false,
    data: undefined,
    error: undefined,
    lengths: undefined,
    status: null,
  });

  const roles = [
    "admin",
    "admin_assistant",
    "admin_marketing",
    "admin_sales",
    "admin_finance",
    "admin_support",
  ];

  const execute = async (
    endPoint,
    raw,
    Method,
    displayMessage,
    token,
    isJSON
  ) => {
    updatePostData({
      pending: true,
      data: undefined,
      error: undefined,
      lengths: undefined,
      status: null,
    });

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    isJSON ? null : myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: Method,
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    // http://localhost:4800
    //
    //gh.prop.faspro24.com
    // gh.prop.faspro24.com

    https: return fetch(
      `https://gh.prop.faspro24.com/api/v1/${endPoint}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        if (res?.status === "success") {
          const userRoles = res?.data?.roles;
          const check = roles?.some((item) => userRoles?.includes(item));
          if (check) {
            updatePostData({
              pending: false,
              data: res,
              error: undefined,
              lengths: res.result,
              status: res?.status,
            });
            displayMessage === null
              ? null
              : successMessage(`${displayMessage}`);
          } else {
            updatePostData({
              pending: false,
              data: undefined,
              error: undefined,
              lengths: undefined,
              status: undefined,
            });
            warningMessage("Aunthorized Access");
          }
        }
        if (res?.status === "fail") {
          updatePostData({
            pending: false,
            data: undefined,
            error: res?.message,
            lengths: undefined,
            status: res?.status,
          });
          warningMessage(`${res?.message}`);
        }
        if (res?.status === "error") {
          updatePostData({
            pending: false,
            data: undefined,
            error: res?.message,
            lengths: undefined,
          });
          if (res?.message === "jwt malformed") {
            warningMessage("You need to login");
          } else {
            warningMessage("Network issue please try again");
          }
        }
      })
      .catch((error) => {
        updatePostData({
          pending: false,
          data: undefined,
          error: error.message,
          lengths: undefined,
        });
        dangerMessage(`${error.message}`);
      });
  };

  return {
    execute,
    pending: postData.pending,
    data: postData.data,
    status: postData.status,
    error: postData.error,
    lengths: postData.lengths,
  };
};

export default usePostLogin;

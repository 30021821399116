export const card_arr = [
    {
        id: 1,
        name: "Property Owner",
        link: "login/property-owner",
    },
    {
        id: 2,
        name: "Property Agency",
        link: "login/property-agency",
    },
    {
        id: 3,
        name: "Service Provider",
        link: "login/service-provider",
    },
    {
        id: 4,
        name: "Back Office",
        link: "login/back-office",
    },
]
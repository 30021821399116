import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Card,
  CardBody,
  Col,
  Spinner,
  Button,
} from "reactstrap";
import { Modal } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import MetaTagComp from "../../../components/Common/MetaTags";
import Slider from "react-rangeslider";
import "react-rangeslider/lib/index.css";
import { useStore1Selector } from "../../../index";
import { loginUser } from "../../../Redux/Slices/userSlice";
import usePost from "../../../hooks/usePost";
import useFecth from "../../../hooks/useFecth";
import LeadInfo from "../../Lead/ClientProfile";
import EditForm from "./EditForm";

function EditQuotation() {
  const { leadId, quotationId } = useParams();
  let history = useHistory();
  const userDet = useStore1Selector(loginUser);
  const token = userDet?.token;
  const { execute, pending, data } = usePost();

  const { data: getQuoteDetails, reFetch: reFetchGetQuoteDetails } = useFecth(
    `https://gh.prop.faspro24.com/api/v1/quotations/${quotationId}`,
    token
  );

  // const todayDate = new Date().toISOString().split("T")[0];
  // const [subtotal, setSubtotal] = useState(0);
  // const [viewModal, setViewModal] = useState(false);
  // const currentDate = new Date().toISOString().split("T")[0];

  const [formValues, setFormValues] = useState({
    facility: "",
    maintenance: "",
    security: "",
    asset: "",
    file: "",
    bookings: "",
  });

  const [displayModal, setDisplayModal] = useState(false);
  const [units, setUnits] = useState("");
  const [discount, setDiscount] = useState(0);
  const [vatPercentage, setVatPercentage] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [formattedData, setFormattedData] = useState({
    modulePrices: [],
    discountPercentage: "",
    vatPercentage: "",
    units: "",
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const { data: getSettingsDetails, reFetch: reFetchSettingsDetails } =
    useFecth(
      `https://gh.prop.faspro24.com/api/v1/invoiceSettings/669612408f9b6bd458081551`,
      token
    );
  const { data: getLeadDetails, reFetch: reFetchLeadById } = useFecth(
    `https://gh.prop.faspro24.com/api/v1/leads/${leadId}`,
    token
  );

  const handleUnitsChange = (e) => {
    setUnits(e.target.value);
  };

  const handleDiscountChange = (e) => {
    setDiscount(e.target.value);
  };

  const handleVatChange = (e) => {
    setVatPercentage(e.target.value);
  };

  useEffect(() => {
    if (getQuoteDetails && getQuoteDetails?.moduleAndPrice) {
      const initialFormValues = getQuoteDetails?.moduleAndPrice?.reduce(
        (acc, { module, price }) => {
          acc[module] = price;
          return acc;
        },
        {
          facility: "",
          maintenance: "",
          security: "",
          asset: "",
          file: "",
          bookings: "",
        }
      );
      setFormValues(initialFormValues);
      setUnits(getQuoteDetails?.numberOfUnits || 0);
      setDiscount(getQuoteDetails?.discountPercentage || 0);
      setVatPercentage(getQuoteDetails?.vatPercentage || 0);
    }
  }, [getQuoteDetails?._id]);

  useEffect(() => {
    const modulePrices = Object.entries(formValues)
      .filter(([module, price]) => price)
      .map(([module, price]) => ({ module, price: parseFloat(price) }));

    const totalModulesPrice = modulePrices.reduce(
      (acc, item) => acc + item.price,
      0
    );

    const unitCount = units ? parseInt(units, 10) : 0;
    const totalBeforeDiscountAndVat = totalModulesPrice * unitCount;

    setSubTotal(totalBeforeDiscountAndVat);

    setFormattedData({
      modulePrices,
      discountPercentage: discount ? parseInt(discount) : 0,
      vatPercentage: vatPercentage ? parseInt(vatPercentage) : 0,
      units: units ? parseInt(units) : 0,
    });
  }, [formValues, units, discount, vatPercentage]);

  const checkLowPrice = (value) => {
    return value &&
      parseInt(value, 10) < getSettingsDetails?.minimumPricePerModule
      ? "Low price"
      : "";
  };

  // const toggleModal = () => {
  //   setDisplayModal(!displayModal);
  // };

  const HandleCalculateFunc = (e) => {
    setDisplayModal(!displayModal);
  };

  if (data?.status === "success") {
    window.setTimeout(() => {
      history.push("/quotation");
    }, 2000);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTagComp meta_tags="FASPRO24 | Edit Quotation" />

        <Row>
          <Row>
            <div className="d-flex justify-content-start h4 mt-5 mb-4">
              Edit Quotation
            </div>
          </Row>
          <Row>
            <Col md={8}>
              <CardBody className="bg-white p-4">
                <div>
                  <form>
                    <Row className="mb-3">
                      <Col md={3}>
                        <label>Number of Units:</label>
                      </Col>
                      <Col md={6}>
                        <input
                          type="number"
                          value={units}
                          onChange={handleUnitsChange}
                          className="w-50"
                        />
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col md={3}>
                        <label>Facility Management:</label>
                      </Col>
                      <Col md={6}>
                        <input
                          className="w-50"
                          type="number"
                          name="facility"
                          value={formValues.facility}
                          onChange={handleInputChange}
                        />
                        <span className="text-danger mx-2">
                          {checkLowPrice(formValues.facility)}
                        </span>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col md={3}>
                        <label>Security Management:</label>
                      </Col>
                      <Col md={6}>
                        <input
                          className="w-50"
                          type="number"
                          name="security"
                          value={formValues.security}
                          onChange={handleInputChange}
                        />
                        <span className="text-danger mx-2">
                          {checkLowPrice(formValues.security)}
                        </span>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col md={3}>
                        <label>Maintenance Management: </label>
                      </Col>
                      <Col md={6}>
                        <input
                          className="w-50"
                          type="number"
                          name="maintenance"
                          value={formValues.maintenance}
                          onChange={handleInputChange}
                        />
                        <span className="text-danger mx-2">
                          {checkLowPrice(formValues.maintenance)}
                        </span>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col md={3}>
                        <label>Asset Management: </label>
                      </Col>
                      <Col md={6}>
                        <input
                          type="number"
                          name="asset"
                          value={formValues.asset}
                          onChange={handleInputChange}
                          className="w-50"
                        />
                        <span className="text-danger mx-2">
                          {checkLowPrice(formValues.asset)}
                        </span>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col md={3}>
                        <label>File Management: </label>
                      </Col>
                      <Col md={6}>
                        <input
                          type="number"
                          name="file"
                          value={formValues.file}
                          onChange={handleInputChange}
                          className="w-50"
                        />
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col md={3}>
                        <label> Bookings Management: </label>
                      </Col>
                      <Col md={6}>
                        <input
                          type="number"
                          name="bookings"
                          value={formValues.bookings}
                          onChange={handleInputChange}
                          className="w-50"
                        />
                        <span className="text-danger mx-2">
                          {checkLowPrice(formValues.bookings)}
                        </span>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col md={3}>
                        <label>Discount Percentage:</label>
                      </Col>
                      <Col md={6}>
                        <input
                          type="number"
                          value={discount}
                          onChange={handleDiscountChange}
                          className="w-25"
                        />
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col md={3}>
                        <label>VAT Percentage: </label>
                      </Col>
                      <Col md={6}>
                        <input
                          type="number"
                          value={vatPercentage}
                          onChange={handleVatChange}
                          className="w-25"
                        />
                      </Col>
                    </Row>
                  </form>
                  <div className="w-100 mb-3">
                    <h5 className="text-success">
                      SubTotal: $ {subTotal.toFixed(2)}
                    </h5>
                  </div>
                  <div className="w-100 mb-3">
                    <button
                      className="btn btn-dark w-md waves-effect waves-light"
                      onClick={HandleCalculateFunc}
                    >
                      <span className="me-2" style={{ color: "white" }}>
                        Calculate
                      </span>
                    </button>
                  </div>
                </div>
              </CardBody>
            </Col>

            <Col md={4}>
              <Row>
                <Col md={12}>
                  <CardBody className="bg-white d-flex justify-content-center">
                    <LeadInfo viewID={getLeadDetails?._id} />
                  </CardBody>
                </Col>
                <Col md={12}></Col>
              </Row>
            </Col>
          </Row>
        </Row>
      </div>
      <Modal
        show={displayModal}
        onHide={() => setDisplayModal(false)}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Quotation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="w-100">
            <Row>
              <EditForm
                formattedData={formattedData}
                leadId={leadId}
                quotationId={quotationId}
                settingId={getSettingsDetails?._id}
                getLeadDetails={getLeadDetails}
                token={token}
                discount={discount}
                vatPercentage={vatPercentage}
                subTotal={subTotal}
              />
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-end p-2">
          <Button
            className="bg-danger text-white"
            onClick={() => setDisplayModal(false)}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default EditQuotation;

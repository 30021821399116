import PropTypes from "prop-types";
import React, { useState, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "./Style.scss";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper";
import { Row, Col, Button } from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import {
  registerUser,
  apiError,
  registerUserFailed,
} from "../../../store/actions";
import { connect } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import DetailForm from "./components/Detail-form";
import BankAccount from "./components/Bank-account";
import {
  warningMessage,
  successMessage,
} from "../../../components/Notifications";
import Img_600 from "./../../../assets/images/img-size/600x400.gif";
import Img from "../../../assets/images/faspro24_logo_original.png";

const Individual = () => {
  const history = useHistory();
  const [phoneState, setPhoneState] = useState();
  const [loadBtn, setIloadBtn] = useState(false);
  const [profile, setProfile] = useState();
  const [profileServer, setProfileServer] = useState();

  const handleValidSubmit = (e, values) => {
    e.preventDefault();
    setIloadBtn(true);

    const formdata = new FormData();
    formdata.append("Email", values.email);
    formdata.append("Name", values.firstName);
    formdata.append("Surname", values.lastName);
    formdata.append("password", values.password);
    formdata.append("Phone_number", phoneState);
    formdata.append("Country", values.country);
    formdata.append("City", values.city);
    formdata.append("OwnerType", "individual");
    formdata.append("Id_number", values.ID);
    formdata.append("Building_Name", values.buildingName);
    formdata.append("Suburb", values.suburb);
    formdata.append("State_Province", values.state);
    formdata.append("Bank", values.accountName);
    formdata.append("Branch_Name", values.branchName);
    formdata.append("SWIFT_Code", values.branchCode);
    formdata.append("Account_Number", values.accountNumber);
    formdata.append("Account_type", values.accountType);
    formdata.append("Physical_address", values.address);
    formdata.append("ProfilePicture", profileServer);
    formdata.append("CompanyName", "");

    const requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(
      "https://gh.prop.faspro24.com/api/owner/NewIndividualOwner",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.data.original.status == "success") {
          setIloadBtn(false);
          successMessage(
            "Thank you for signing up with us, we have sent you a link to verified your email"
          );
          window.setTimeout(() => {
            history.push("/login");
          }, 3000);
        } else if (result.data.original.Email !== undefined) {
          setIloadBtn(false);
          warningMessage(result.data.original.Email[0]);
        } else if (result.data.original.password !== undefined) {
          setIloadBtn(false);
          warningMessage(result.data.original.password[0]);
        }
      })
      .catch((error) => {
        setIloadBtn(false);
        warningMessage("The was an error signing up.");
        // console.log('error', error)
      });
  };

  const refFileUpload = useRef(null);
  const onThumbChangeClick = () => {
    if (refFileUpload) {
      refFileUpload.current.dispatchEvent(new MouseEvent("click"));
    }
  };

  const changeThumb = (event) => {
    if (event.target.files && event.target.files[0]) {
      setProfileServer(event.target.files[0]);

      const reader = new FileReader();
      reader.onload = (loadEvent) => {
        setProfile(loadEvent.target.result);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '">' + (index + 1) + "</span>";
    },
  };

  return (
    <React.Fragment>
      <div className="account-pages">
        <h5 className="d-flex justify-content-end me-5">
          Already have an account ? <Link to="/login"> Login </Link>{" "}
        </h5>
        <AvForm
          className="mt-4 mb-5"
          onValidSubmit={(e, v) => {
            handleValidSubmit(e, v);
          }}
        >
          <div className="logo">
            <img src={Img} alt="" />
          </div>

          <Row className="justify-content-center">
            <h4 className="text-center">Individual</h4>

            <Col md={12} lg={12} xl={12}>
              <div className="property-content-container">
                <Swiper
                  pagination={pagination}
                  modules={[Pagination]}
                  className="mySwiper"
                >
                  <SwiperSlide>
                    <div>
                      <h5 className="d-flex justify-content-center align-items-center">
                        Upload profile Picture
                      </h5>
                      <div className="d-flex justify-content-center align-items-center mb-5">
                        <img
                          src={profile === undefined ? Img_600 : profile}
                          alt="user"
                          width={200}
                          height={150}
                          className="rounded"
                        />
                        <Button
                          size="sm"
                          variant="separator-light"
                          className="btn-icon btn-icon-only position-absolute rounded s-0 b-0 mt-5"
                          onClick={onThumbChangeClick}
                        >
                          <i className="ion ion-md-image"></i>
                        </Button>
                        <input
                          type="file"
                          ref={refFileUpload}
                          className="file-upload d-none"
                          accept="image/*"
                          onChange={changeThumb}
                        />
                      </div>

                      <DetailForm setPhoneState={setPhoneState} />
                      <br />
                      <br />
                    </div>
                  </SwiperSlide>

                  <SwiperSlide>
                    <BankAccount loadBtn={loadBtn} />
                  </SwiperSlide>

                  <br />
                </Swiper>
              </div>
            </Col>
          </Row>
        </AvForm>
      </div>
    </React.Fragment>
  );
};

Individual.propTypes = {
  registerUser: PropTypes.func,
  registerUserFailed: PropTypes.func,
  registrationError: PropTypes.any,
  user: PropTypes.any,
};

const mapStatetoProps = (state) => {
  const { user, registrationError, loading } = state.Account;
  return { user, registrationError, loading };
};

export default connect(mapStatetoProps, {
  registerUser,
  apiError,
  registerUserFailed,
})(Individual);

import React, { useEffect, useState } from "react";
import { Row, Col, CardBody } from "reactstrap";
import MetaTagComp from "../../components/Common/MetaTags";
import { Link, useParams } from "react-router-dom";
import { warningMessage } from "../../components/Notifications";

const VerifyEmail = () => {
  const { id } = useParams();
  const [verify, setVerify] = useState(false);

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `https://gh.prop.faspro24.com/api/owner/VerifyEmail/${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          setVerify(" Thank you, you have successful verified your email");
        }
        if (result.status === "error") {
          setVerify(result.message);
        }
      })
      .catch((error) => {
        warningMessage(error.message);
      });
  }, [id]);

  return (
    <React.Fragment>
      <MetaTagComp meta_tags="FASPRO 24 | Verify email" />
      <div className="account-pages">
        <Row className="justify-content-center mt-5">
          <Col md={12} lg={12} xl={12}>
            <CardBody className="text-center">
              <h3 className="text-success"> {verify} </h3>
              {verify === "invalid link" ? null : (
                <Link
                  to="/login"
                  className="btn login-btn w-md waves-effect waves-light mt-5"
                  type="submit"
                >
                  {" "}
                  Go to Log In{" "}
                </Link>
              )}
            </CardBody>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default VerifyEmail;

import React, { useState } from "react";
import "./../Style.scss";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Row, Col, Card, CardBody, Spinner } from "reactstrap";
import { useHistory } from "react-router-dom";
import usePost from "../../../../hooks/usePost";

function Form({ tokenId }) {
  const history = useHistory();
  const { execute, pending, data: postData } = usePost();
  // console.log("token....", tokenId);

  function handleBtnSend(e, values) {
    e.preventDefault();
    const formdata = new FormData();
    const Method = "PATCH",
      endPoint = `auth/resetPassword/${tokenId}`,
      isJSON = true;
    const raw = JSON.stringify({
      password: values?.newPassword,
      passwordConfirm: values?.confirmPassword,
    });
    execute(
      endPoint,
      raw,
      Method,
      "Password successfully updated",
      tokenId,
      null
    );
  }

  if (postData?.status === "success") {
    window.setTimeout(() => {
      history.push("/login");
    }, 3000);
  }

  return (
    <div className="login-content-container">
      <div className="forget-pass">
        <Card className="overflow-hidden">
          <div className="login-header">
            <div className="text-primary text-center p-4">
              <h5 className="text-white font-size-20 p-2">Reset Password</h5>
            </div>
          </div>
          <CardBody>
            <AvForm
              className="form-horizontal mt-4"
              onValidSubmit={(e, v) => handleBtnSend(e, v)}
            >
              <div className="mb-3">
                <AvField
                  name="newPassword"
                  label="Enter your new password"
                  className="form-control"
                  type="password"
                  required
                />
              </div>
              <div className="mb-3">
                <AvField
                  name="confirmPassword"
                  label="Confirm Password"
                  className="form-control"
                  type="password"
                  required
                />
              </div>
              <Row className="mb-3">
                <Col className="text-center">
                  <button
                    className="btn login-btn  waves-effect waves-light"
                    type="submit"
                  >
                    {!pending ? (
                      <span className="me-2">Reset Password</span>
                    ) : null}
                    {!pending ? null : (
                      <span>
                        {" "}
                        <Spinner as="span" animation="border" size="sm" />{" "}
                        Loading...
                      </span>
                    )}
                  </button>
                </Col>
              </Row>
            </AvForm>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}

export default Form;

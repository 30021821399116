import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Container, Row, Card, CardBody, Button } from "reactstrap";
import { Modal } from "react-bootstrap";
import { MDBDataTable } from "mdbreact";
import { withRouter, Link, useParams } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import MetaTagComp from "../../../components/Common/MetaTags";
import { CSVLink } from "react-csv";
import { useStore1Selector } from "./../../../index";
import { loginUser } from "../../../Redux/Slices/userSlice";
import Form from "./Form";
// import ViewModal from "./components/ViewModal";
// import TenantDetails from "./components/TenantDetails";
import Loading from "components/Loading";
// import { warningMessage, successMessage } from "../../components/Notifications";

function AddTeam() {
  const { id } = useParams();
  const userDet = useStore1Selector(loginUser);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTagComp meta_tags="FASPRO 24 | Team" />
        <Breadcrumb default="Team" title="Add Team" />
        <Container fluid>
          <div className="d-flex justify-content-center">
            <Card className="bd-rds w-50" data-aos="fade-bottom">
              <CardBody>
                <h4 className="card-title d-flex justify-contnet-center">
                  Add a Team
                </h4>
                <div className="form-horizontal form-wizard-wrapper wizard clearfix">
                  <Form />
                  <div></div>
                </div>
              </CardBody>
            </Card>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
}

AddTeam.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};
export default withRouter(withTranslation()(AddTeam));

import React from "react";
import { Row } from "reactstrap";

function viewForm({ viewQuotation }) {
  return (
    <div>
      <Row>
        <div className="col-12">
          <Row>
            <div className="col-6">
              <address>
                <strong>Bill To:</strong>
                <br />
                {viewQuotation?.leadId?.firstName}{" "}
                {viewQuotation?.leadId?.lastName}
                <br />
                {viewQuotation?.leadId?.email}
                <br />
                {`+${viewQuotation?.leadId?.phoneNumber}`}
                <br />
                {/* {todayDate} */}
              </address>
            </div>
            <div className="col-6 text-end">
              <strong></strong>
              <address></address>
            </div>
          </Row>
          <Row>
            <div className="col-6">
              {/* <strong>Date: {currentDate}</strong> */}
              <address></address>
            </div>
          </Row>
        </div>
      </Row>
      <Row>
        <div className="col-12">
          <div>
            <div className="p-2">
              <h3 className="font-size-16"></h3>
            </div>
            <div className="">
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <td>
                        <strong>Items(s)</strong>
                      </td>
                      <td className="text-center">
                        <strong>Price per Unit</strong>
                      </td>
                      <td className="text-end">
                        <strong>
                          Amount (X{viewQuotation?.numberOfUnits} Units)
                        </strong>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {viewQuotation?.moduleAndPrice?.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td>{item?.module}</td>
                          <td className="text-center">${item?.price}</td>
                          <td className="text-end">
                            $
                            {item?.price *
                              parseInt(viewQuotation?.numberOfUnits)}
                          </td>
                        </tr>
                      );
                    })}

                    <tr>
                      <td className="no-line"></td>
                      <td className="no-line text-center">
                        <strong>Subtotal</strong>
                      </td>
                      <td className="no-line text-end">
                        ${viewQuotation?.total}
                      </td>
                    </tr>
                    <tr>
                      <td className="no-line"></td>
                      <td className="no-line text-center">
                        <strong>Discount</strong>
                      </td>
                      <td className="no-line text-end">
                        ${viewQuotation?.discountAmount}
                      </td>
                    </tr>
                    {/* <tr>
                      <td className="no-line"></td>
                      <td className="no-line text-center">
                        <strong>Vat</strong>
                      </td>
                      <td className="no-line text-end">${viewQuotation?.}</td>
                    </tr> */}
                    <tr>
                      <td className="no-line"></td>
                      <td className="no-line text-center">
                        <strong>
                          Total Inc ({viewQuotation?.vatPercentage} % )Vat
                        </strong>
                      </td>
                      <td className="no-line text-end">
                        <h4 className="m-0">${viewQuotation?.subTotal}</h4>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </Row>
    </div>
  );
}

export default viewForm;

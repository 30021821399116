import PropTypes from "prop-types";
import React from "react";
import { Col, Row, Card, CardBody } from "reactstrap";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

import { FaUsers } from "react-icons/fa";

const UsersNav = ({
  isAllUsersActive,
  setIsAllUsersActive,
  setIsAllPropertyOwnerActive,
  isAllPropertyOwnerActive,
  isAllTenantActive,
  setIsAllTenantActive,
}) => {
  const handleUsers = () => {
    setIsAllUsersActive(true);
    setIsAllPropertyOwnerActive(false);
    setIsAllTenantActive(false);
  };

  const handleProperties = () => {
    setIsAllUsersActive(false);
    setIsAllPropertyOwnerActive(true);
    setIsAllTenantActive(false);
  };

  const handleTenants = () => {
    setIsAllUsersActive(false);
    setIsAllPropertyOwnerActive(false);
    setIsAllTenantActive(true);
  };

  return (
    <React.Fragment>
      <Row>
        <Col xl={4} md={4}>
          <Card
            className="mini-stat text-dark bd-rds"
            onClick={() => handleUsers()}
          >
            <CardBody
              className={
                isAllUsersActive ? "border border-danger rounded-4" : null
              }
            >
              <div className="mb-1">
                <div className="float-start mini-stat-img me-2 font-size-22">
                  <FaUsers size={28} className="text-danger" />
                </div>
                <h6 className="font-size-14 text-uppercase mt-0">All Users</h6>
                View all users
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col xl={4} md={4}>
          <Card
            className={
              isAllPropertyOwnerActive
                ? "mini-stat  text-dark bd-rds border border-primary"
                : "mini-stat  text-dark bd-rds"
            }
            onClick={() => handleProperties()}
          >
            <CardBody>
              <div className="mb-1">
                <div className="float-start mini-stat-img me-2 font-size-22">
                  <FaUsers size={32} className="text-primary" />
                </div>
                <h6 className="font-size-14 text-uppercase mt-0">
                  All Property owners
                </h6>
                View property owners
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col xl={4} md={4}>
          <Card
            className={
              isAllTenantActive
                ? "mini-stat  text-dark bd-rds border border-warning"
                : "mini-stat  text-dark bd-rds"
            }
            onClick={() => handleTenants()}
          >
            <CardBody>
              <div className="mb-1">
                <div className="float-start mini-stat-img me-2 font-size-22">
                  <FaUsers size={32} className="text-warning" />
                </div>
                <h6 className="font-size-14 text-uppercase mt-0">
                  All Tenants
                </h6>
                View all tenants
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};
UsersNav.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(UsersNav));

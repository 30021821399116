import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Container, Row, Card, CardBody, Button, Spinner } from "reactstrap";
import { Modal } from "react-bootstrap";
import { MDBDataTable } from "mdbreact";
import { withRouter, useHistory, Link, useParams } from "react-router-dom";
import { withTranslation } from "react-i18next";

import { CSVLink } from "react-csv";
import { useStore1Selector } from "../../../index";
import { loginUser } from "../../../Redux/Slices/userSlice";
import usePost from "../../../hooks/usePost";
import useFecth from "../../../hooks/useFecth";
import ClientForm from "../ClientProfile";
import UpdateLeadForm from "./Components/UpdateLeadForm";

function PendingLeads() {
  let history = useHistory();
  const userDet = useStore1Selector(loginUser);
  const token = userDet?.token;
  const {
    execute: executeQuot,
    pending: pendingQuot,
    data: dataQuot,
    status: statusQuot,
  } = usePost();
  const { data: getAllLeads, reFetch: reFetchGetAllLeads } = useFecth(
    `https://gh.prop.faspro24.com/api/v1/leads?status=pending`,
    token
  );
  const {
    execute: executeDelete,
    pending: pendingDelete,
    data: dataDelete,
  } = usePost();

  const [editModal, setEditModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [leadID, setLeadID] = useState();
  const [viewID, setViewID] = useState();
  const [editID, setEditID] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const listLeads = [];

  const sendQuotation = (id) => {
    setLeadID(id);
    const Method = "PATCH",
      endPoint = `leads/${id}`;
    const raw = JSON.stringify({
      status: "qualify",
    });
    executeQuot(endPoint, raw, Method, true, token);
  };

  getAllLeads?.forEach((res) => {
    listLeads.push({
      ...res,
      lead_status: (
        <label className="text-warning">
          {res?.status === "pending" ? "Pending" : null}
        </label>
      ),
      createdAt: `${res?.created_at?.substring(0, 10)}`,
      updatedAt: `${res?.updated_at?.substring(0, 10)}`,
      Phone_Number: `+${res?.phoneNumber}`,
      AssignedTo: `${
        res?.salesId?.firstName
      } ${res?.salesId?.lastName?.substring(0, 1)}.`,

      viewBtn: (
        <button className="btn btn-success" onClick={() => viewFunc(res?._id)}>
          view
        </button>
      ),
      // editBtn: (
      //   <button className="btn btn-primary" onClick={() => EditFunc(res?._id)}>
      //     <span className="me-2">Update Status</span>
      //     {!pending ? null : <Spinner as="span" animation="border" size="sm" />}
      //   </button>
      // ),
      deleteBtn: (
        <button
          className="btn btn-danger"
          onClick={() => DeleteFuncModal(res?._id)}
        >
          <span className="me-2">Delete </span>
        </button>
      ),
      sendQuotationBtn: (
        // <Link to={`/generate-quotation/${res?._id}`}>
        <button
          className="btn btn-dark"
          onClick={() => sendQuotation(res?._id)}
        >
          {pendingQuot && res?._id === leadID ? (
            <>
              <Spinner as="span" animation="border" size="sm" />{" "}
              <span>Loading</span>
            </>
          ) : (
            "Send Quotation"
          )}
        </button>
        // </Link>
      ),
    });
  });

  function viewFunc(id) {
    setViewModal(true);
    setViewID(id);
  }

  function EditFunc(id) {
    setEditModal(true);
    setEditID(id);
  }

  const DeleteFuncModal = (id) => {
    setDeleteModal(true);
    setViewID(id);
  };

  function deleteLead(id) {
    setDeleteModal(false);
    const Method = "DELETE",
      endPoint = `leads/${id}`;
    const raw = null;
    executeDelete(endPoint, raw, Method, "Lead successfully deleted", token);

    setTimeout(() => {
      reFetchGetAllLeads();
    }, 2000);
  }

  const column = [
    { label: "First name", field: "firstName", sort: "asc", width: 150 },
    { label: "Last name", field: "lastName", sort: "asc", width: 150 },
    { label: "Email", field: "email", sort: "asc", width: 150 },
    { label: "Status", field: "lead_status", sort: "asc", width: 150 },
    { label: "Assigned to", field: "AssignedTo", sort: "asc", width: 150 },
    { label: "Created at", field: "createdAt", sort: "asc", width: 150 },
    { label: "Updated at", field: "updatedAt", sort: "asc", width: 150 },
    {
      label: "Send quotation",
      field: "sendQuotationBtn",
      sort: "asc",
      width: 150,
    },
    { label: "Delete", field: "deleteBtn", sort: "asc", width: 150 },
  ];

  const newData = {
    columns: column,
    rows: listLeads.reverse(),
  };

  useEffect(() => {
    if (dataQuot?.status === "success") {
      history.push(`/generate-quotation/${leadID}`);
    }
  }, [statusQuot]);

  return (
    <React.Fragment>
      <CardBody>
        <Container fluid>
          {/* <Link to={`/property-detail/${id}`} className="btn login-btn mb-2">
              {" "}
              Back{" "}
            </Link> */}
          <Row className="d-flex justify-content-around align-items-center">
            <CardBody data-aos="fade-bottom">
              <Card className="bd-rds">
                <CardBody>
                  <MDBDataTable
                    entries={5}
                    entriesOptions={[5, 10, 50]}
                    responsive
                    bordered
                    striped
                    hover
                    data={newData}
                  />
                </CardBody>
              </Card>
            </CardBody>
          </Row>
        </Container>
      </CardBody>

      <Modal show={viewModal} onHide={() => setViewModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Client Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ClientForm viewID={viewID} />
        </Modal.Body>
      </Modal>

      <Modal show={editModal} onHide={() => setEditModal(false)} size="lg">
        <Modal.Body>
          <UpdateLeadForm
            editID={editID}
            reFetchGetAllLeads={reFetchGetAllLeads}
            closeModal={setEditModal}
          />
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-end p-2">
          <Button
            className="bg-danger text-white"
            onClick={() => setEditModal(false)}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={deleteModal} onHide={() => setDeleteModal(false)} size="sm">
        <Modal.Body className="text-danger">
          <h6> Are you sure you want to delete this ? </h6>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="bg-danger text-white"
            onClick={() => setDeleteModal(false)}
          >
            Cancel
          </Button>
          <Button
            className="bg-success text-white"
            onClick={() => deleteLead(viewID)}
          >
            {pendingDelete ? (
              <>
                <Spinner as="span" animation="border" size="sm" />{" "}
                <span>Loading</span>
              </>
            ) : (
              "Yes"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

PendingLeads.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};
export default withRouter(withTranslation()(PendingLeads));

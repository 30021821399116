import React, { useState } from 'react'
import { Row, Col } from "reactstrap"
import { AvField } from "availity-reactstrap-validation"
import { countryList } from "../../../../components/Countries";
import { var_num } from "../../../../components/Vatnumber";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

function DetailForm(props) {

    return (
        <Row className='mb-5'>
            <Col md={6} lg={6} >

                <Row>
                    <Col md={6} lg={6}>
                        <div className="mb-3">
                            <AvField name="companyName" label="Company / Trustee name" type="text" required />
                        </div>
                    </Col>
                    <Col md={6} lg={6}>
                        <div className="mb-3">
                            <AvField name="trading" label="Trading As" type="text" required />
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col md={6} lg={6}>
                        <div className="mb-3">
                            <AvField name="vatRate" label="VAT Rate(percentage)" type="select" required>
                                {
                                    var_num.map(num => (
                                        <option> {num} </option>
                                    ))
                                }
                            </AvField>
                        </div>
                    </Col>
                    <Col md={6} lg={6}>
                        <div className="mb-3">
                            <AvField name="vatNumber" label="VAT Number(if applicable)" type="text" />

                        </div>
                    </Col>
                </Row>


                <Row>
                    <Col md={12} lg={12}>
                        <div className="mb-3">
                            <AvField name="regNumber" label="Registration Number(if applicable)" type="text" />
                        </div>
                    </Col>
                </Row>

                <h6 style={{ padding: '1em 0' }}> Contact Person  </h6>
                <Row>
                    <Col md={6} lg={6}>
                        <div className="mb-3">
                            <AvField name="email" label="Email" className="form-control" type="email" required />
                        </div>
                    </Col>
                    <Col md={6} lg={6}>
                        <div className="mb-3">
                            <AvField name="password" label="Password" type="password" required />
                        </div>
                    </Col>
                </Row>


                <Row>
                    <Col md={6}>
                        <div className="mb-3">
                            <AvField name="firstName" label="First Name" type="text" required />
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="mb-3">
                            <AvField name="lastName" label="Last Name" type="text" required />
                        </div>
                    </Col>
                </Row>

            </Col>

            <Col md={6} lg={6}>
                <label>Phone number</label>
                <div className="mb-3">
                    <PhoneInput
                        buttonStyle={{
                            borderBottomLeftRadius: 5,
                            borderTopLeftRadius: 5,
                            height: 40,
                        }}
                        inputStyle={{
                            margin: 0,
                            width: "100%",
                            color: 'purple',
                            height: 1,
                            border: 'none'

                        }}
                        containerStyle={{
                            width: "100%",
                        }}
                        className="blue"
                        country={"za"}
                        enableSearch={true}
                        onChange={(phone) => props.setPhoneState(phone)}
                    />
                </div>

                <div className="mb-3">
                    <AvField name="buildingName" label="Building Name" type="text" required />
                </div>

                <div className="mb-3">
                    <AvField name="address" label="Address" type="text" required />
                </div>

                <Row>
                    <Col md={6}>
                        <div className="mb-3">
                            <AvField name="suburb" label="Suburb" type="text" required />
                        </div>
                    </Col>

                    <Col md={6}>
                        <div className="mb-3">
                            <AvField name="city" label="City" type="text" required />
                        </div>
                    </Col>
                </Row>

                <div className="mb-3">
                    <AvField name="state" label="State / Province " type="text" required />
                </div>

                <Row>
                    <Col md={12}>
                        <div className="mb-3">
                            <AvField name="country" label="Country" type="select" required>
                                {
                                    countryList.map(country => (
                                        <option> {country} </option>
                                    ))
                                }
                            </AvField>
                        </div>
                    </Col>
                </Row>

            </Col>
        </Row>
    )
}

export default DetailForm
import React, { useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Col, Row, Button, Spinner, Modal } from "reactstrap";
import {
  AvForm,
  AvField,
  AvCheckboxGroup,
  AvCheckbox,
} from "availity-reactstrap-validation";
import { countryList } from "../../components/Countries";
import Img_600 from "./../../assets/images/img-size/600x400.gif";
import { useStore1Selector } from "../../index";
import { loginUser } from "../../Redux/Slices/userSlice";
import usePost from "../../hooks/usePost";
import useFecth from "../../hooks/useFecth";

function BankDetailsForm() {
  let history = useHistory();
  const userDet = useStore1Selector(loginUser);
  const { execute, pending, data } = usePost();
  const token = userDet?.token;

  const [profile, setProfile] = useState();
  const userId = userDet?.data?._id;

  const { data: getSettings, reFetch: reFetchGetSettings } = useFecth(
    `https://gh.prop.faspro24.com/api/v1/invoiceSettings/669612408f9b6bd458081551`,
    token
  );

  const handleValidSubmit = (e, v) => {
    e.preventDefault();
    const Method = "PATCH",
      endPoint = `invoiceSettings/669612408f9b6bd458081551`,
      isJSON = true;
    const raw = JSON.stringify({
      ...(v.termsAndCondition && { termsAndCondition: v.termsAndCondition }),
      ...(v.taxAmount && { taxAmount: v.taxAmount }),
      ...(v.bankName && { bankName: v.bankName }),
      ...(v.accountName && { accountName: v.accountName }),
      ...(v.accountType && { accountType: v.accountType }),
      ...(v.accountNumber && { accountNumber: v.accountNumber }),
      ...(v.swiftCode && { swiftCode: v.swiftCode }),
      ...(v.branchName && { branchName: v.branchName }),
      ...(v.branchCode && { branchCode: v.branchCode }),
      ...(v.email && { email: v.email }),
      ...(v.address && { address: v.address }),
      ...(v.minimumPricePerModule && {
        minimumPricePerModule: v.minimumPricePerModule,
      }),
      ...(v.minimumPricePerUser && {
        minimumPricePerUser: v.minimumPricePerUser,
      }),
      ...(v.phoneNumber && { phoneNumber: v.phoneNumber }),
    });
    execute(endPoint, raw, Method, "Bank details successfully updated", token);
    reFetchGetSettings();
  };

  const bankNumber = getSettings?.accountNumber?.slice(-3);
  return (
    <React.Fragment>
      <AvForm
        className="form-horizontal"
        onValidSubmit={(e, v) => {
          handleValidSubmit(e, v);
        }}
      >
        <Row>
          <Col md={4}>
            <div className="mb-3">
              <AvField
                name="bankName"
                value={getSettings?.bankName}
                label="Bank Name"
                className="form-control"
                type="text"
                required
              />
            </div>
          </Col>
          <Col md={4}>
            <div className="mb-3">
              <AvField
                name="accountName"
                value={getSettings?.accountName}
                label="Account Name"
                className="form-control"
                type="text"
                required
              />
            </div>
          </Col>
          <Col md={4}>
            <div className="mb-3">
              <AvField
                name="accountType"
                value={getSettings?.accountType}
                label="Account Type"
                className="form-control"
                type="text"
                required
              />
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-3">
              <AvField
                name="accountNumber"
                // value={`***********${bankNumber}`}
                value={getSettings?.accountNumber}
                label="Account Number"
                className="form-control"
                type="text"
                required
              />
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-3">
              <AvField
                name="swiftCode"
                value={getSettings?.swiftCode}
                label="Swift Code"
                className="form-control"
                type="text"
                required
              />
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-3">
              <AvField
                name="branchName"
                value={getSettings?.branchName}
                label="Branch Name"
                className="form-control"
                type="text"
                required
              />
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-3">
              <AvField
                name="branchCode"
                value={getSettings?.branchCode}
                label="Branch Code"
                className="form-control"
                type="text"
                required
              />
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-3">
              <AvField
                name="email"
                value={getSettings?.email}
                label="Email"
                className="form-control"
                type="text"
                required
              />
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-3">
              <AvField
                name="address"
                value={getSettings?.address}
                label="Address"
                className="form-control"
                type="text"
                required
              />
            </div>
          </Col>
          <Col md={12}>
            <div className="mb-3">
              <AvField
                name="minimumPricePerModule"
                value={getSettings?.minimumPricePerModule}
                label="Minimum Price Per Module"
                className="form-control"
                type="number"
                required
              />
            </div>
          </Col>
          {/* <Col md={6}>
            <div className="mb-3">
              <AvField
                name="minimumPricePerUser"
                value={getSettings?.minimumPricePerUser}
                label="Minimum Price Per User"
                className="form-control"
                type="number"
                required
              />
            </div>
          </Col> */}

          <Col md={12}>
            <div className="mb-3">
              <AvField
                name="note"
                value={getSettings?.note}
                label="Note"
                className="form-control"
                type="textarea"
                rows={5}
              />
            </div>
          </Col>
          <Col md={12}>
            <div className="mb-3">
              <AvField
                name="termsAndCondition"
                value={getSettings?.termsAndCondition}
                label="Terms and Conditions"
                className="form-control"
                type="textarea"
                rows={5}
                required
              />
            </div>
          </Col>
        </Row>

        <div className="mb-3 row ">
          <div className="col-12 d-flex justify-content-center">
            <button
              className="btn btn-dark w-md waves-effect waves-light"
              type="submit"
            >
              {!pending ? <span className="me-2"> Update Details</span> : null}

              {!pending ? null : (
                <span>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    className="me-2"
                  />
                  Loading...
                </span>
              )}
            </button>
          </div>
        </div>
      </AvForm>
    </React.Fragment>
  );
}

export default BankDetailsForm;

import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Card,
  CardBody,
  Col,
  Spinner,
  Button,
} from "reactstrap";
import { Modal } from "react-bootstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { withRouter, Link, useHistory, useParams } from "react-router-dom";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import MetaTagComp from "../../../components/Common/MetaTags";
import Slider from "react-rangeslider";
import "react-rangeslider/lib/index.css";
import { useStore1Selector } from "../../../index";
import { loginUser } from "../../../Redux/Slices/userSlice";
import useFecth from "../../../hooks/useFecth";
import InputForm from "./InputsForm";

function Generate() {
  const { leadId } = useParams();
  let history = useHistory();
  const userDet = useStore1Selector(loginUser);
  const token = userDet?.token;
  const [viewModal, setViewModal] = useState(false);
  const [noUnits, setNoUnits] = useState(1);
  const [noUsers, setNoUsers] = useState(1);
  const [discount, setDiscount] = useState(0);
  const [discountPrice, setDiscountPrice] = useState(0);
  const [vatValue, setVatValue] = useState(0);
  const [vatAmount, setVatAmount] = useState(0);
  const [facility, setFacility] = useState(0);
  const [facilityError, setFacilityError] = useState(false);
  const [maintenance, setMaintenance] = useState(0);
  const [maintenanceError, setMaintenanceError] = useState(false);
  const [security, setSecurity] = useState(0);
  const [securityError, setSecurityError] = useState(false);
  const [asset, setAsset] = useState(0);
  const [assetError, setAssetError] = useState(false);
  const [file, setFile] = useState(0);
  const [fileError, setFileError] = useState(false);
  const [booking, setBooking] = useState(0);
  const [bookingError, setBookingError] = useState(false);
  const todayDate = new Date().toISOString().split("T")[0];
  const [total, setTotal] = useState(0);
  const [totalWithVat, setTotalWithVat] = useState(0);
  const [subtotal, setSubtotal] = useState(0);
  const [displayCalc, setDisplayCalc] = useState(false);
  const currentDate = new Date().toISOString().split("T")[0];

  const { data: getSettingsDetails, reFetch: reFetchSettingsDetails } =
    useFecth(
      `https://gh.prop.faspro24.com/api/v1/invoiceSettings/669612408f9b6bd458081551`,
      token
    );

  // const { data: getLeadDetails, reFetch: reFetchLeadById } = useFecth(
  //   `
  //gh.prop.faspro24.com/api/v1/leads/${leadId}`,
  //   token
  // );

  function handleFacility(e) {
    const value = e.target.value ? e.target.value : 0;
    setFacility(value);
    if (value && Number(value) <= getSettingsDetails?.minimumPricePerModule) {
      setFacilityError(true);
    } else {
      setFacilityError(false);
    }
  }

  function handleMaintenance(e) {
    const value = e.target.value ? e.target.value : 0;
    setMaintenance(value);

    if (value && Number(value) <= getSettingsDetails?.minimumPricePerModule) {
      setMaintenanceError(true);
    } else {
      setMaintenanceError(false);
    }
  }
  function handleSecurity(e) {
    const value = e.target.value ? e.target.value : 0;
    setSecurity(value);
    if (value && Number(value) <= getSettingsDetails?.minimumPricePerModule) {
      setSecurityError(true);
    } else {
      setSecurityError(false);
    }
  }
  function handleAsset(e) {
    const value = e.target.value ? e.target.value : 0;
    setAsset(value);
    if (value && Number(value) <= getSettingsDetails?.minimumPricePerModule) {
      setAssetError(true);
    } else {
      setAssetError(false);
    }
  }
  function handleFile(e) {
    const value = e.target.value ? e.target.value : 0;
    setFile(value);
    if (value && Number(value) <= getSettingsDetails?.minimumPricePerModule) {
      setFileError(true);
    } else {
      setFileError(false);
    }
  }
  function handleBooking(e) {
    const value = e.target.value ? e.target.value : 0;
    setBooking(value);
    if (value && Number(value) <= getSettingsDetails?.minimumPricePerModule) {
      setBookingError(true);
    } else {
      setBookingError(false);
    }
  }
  const discountFunc = (event) => {
    setDiscount(event.target.value);
  };
  const vatFunc = (event) => {
    setVatValue(event.target.value);
  };

  const nbrUnitsFunc = (event) => {
    setNoUnits(event.target.value);
  };
  const nbrUserFunc = (event) => {
    // getSettingsDetails?._id;
    setNoUsers(event.target.value);
  };

  const roundToTwoDecimalPlaces = (num) => {
    return Number.isInteger(num) ? num : parseFloat(num.toFixed(2));
  };

  const SubTotalFunc = () => {
    const Sum_Modules =
      parseInt(facility) +
      parseInt(maintenance) +
      parseInt(security) +
      parseInt(asset) +
      parseInt(file) +
      parseInt(booking);
    const sum1 = Sum_Modules * noUnits;
    const sum2 = Sum_Modules * noUsers;
    const Price = sum1 + sum2;
    setSubtotal(Price);
    return Price;
  };

  const discFunc = () => {
    if (discount > 0) {
      const discAmount = SubTotalFunc() * (discount / 100);
      const discTotalPrice = SubTotalFunc() - discAmount;

      const roundedDisc = roundToTwoDecimalPlaces(discAmount);
      const roundedtotal = roundToTwoDecimalPlaces(discTotalPrice);

      setDiscountPrice(roundedDisc);
      setTotal(roundedtotal);
      return roundedtotal;
    } else {
      const Price = SubTotalFunc();
      const roundedtotal = roundToTwoDecimalPlaces(Price);
      setTotal(roundedtotal);
      return roundedtotal;
    }
  };

  const handleVatPrice = () => {
    if (vatValue > 0) {
      const vatPrice = discFunc() * (vatValue / 100);
      const roundedVatAmount = roundToTwoDecimalPlaces(vatPrice);
      setVatAmount(roundedVatAmount);
      return roundedVatAmount;
    } else {
      const price = 0;
      return price;
    }
  };

  const HandleCalculateFunc = () => {
    const vatPrice = handleVatPrice();

    if (vatPrice > 0) {
      const totalPrice = discFunc() + vatPrice;
      const roundedtotalPrice = roundToTwoDecimalPlaces(totalPrice);
      setTotalWithVat(roundedtotalPrice);
      setViewModal(true);
    } else {
      const totalPrice = discFunc();
      setTotalWithVat(totalPrice);
      setViewModal(true);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTagComp meta_tags="FASPRO24 | Quotation" />
        {/* <Breadcrumb default="Billing" title="Generate Quotation" /> */}

        <Row>
          <Row>
            <div className="d-flex justify-content-start h4 mt-5 mb-4">
              Generate Quotation
            </div>
          </Row>
          <InputForm
            leadId={leadId}
            token={token}
            nbrUnitsFunc={nbrUnitsFunc}
            nbrUserFunc={nbrUserFunc}
            handleFacility={handleFacility}
            facilityError={facilityError}
            handleMaintenance={handleMaintenance}
            maintenanceError={maintenanceError}
            handleSecurity={handleSecurity}
            securityError={securityError}
            handleAsset={handleAsset}
            assetError={assetError}
            handleFile={handleFile}
            fileError={fileError}
            handleBooking={handleBooking}
            bookingError={bookingError}
            discountFunc={discountFunc}
            vatFunc={vatFunc}
            total={total}
            setVatValue={setVatValue}
            vatAmount={vatAmount}
            setDiscount={setDiscount}
            discountPrice={discountPrice}
            subtotal={subtotal}
            setTotal={setTotal}
            setTotalWithVat={setTotalWithVat}
            setSubtotal={setSubtotal}
            totalWithoutVat={total}
            totalWithVat={totalWithVat}
            HandleCalculateFunc={HandleCalculateFunc}
            displayModal={viewModal}
            setDisplayModal={setViewModal}
            noUnits={noUnits}
            noUsers={noUsers}
            facility={facility}
            maintenance={maintenance}
            security={security}
            asset={asset}
            file={file}
            booking={booking}
            discount={discount}
            vatValue={vatValue}
            minimumPricePerModule={getSettingsDetails?.minimumPricePerModule}
          />
        </Row>
      </div>
    </React.Fragment>
  );
}

export default Generate;

import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Col, Container, Row, Card, Modal } from "react-bootstrap";
import { withRouter, useParams, Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Breadcrumb from "../../components/Common/Breadcrumb";
import MetaTagComp from "../../components/Common/MetaTags";
import { useStore1Selector } from "./../../index";
import { loginUser } from "../../Redux/Slices/userSlice";
import Loading from "components/Loading";
import ReportCards from "./ReportCards";

function PrpertyDetails() {
  const { id } = useParams();
  const userDet = useStore1Selector(loginUser);
  const propId = userDet?.original?.userDetails?.OwnerID;
  const [unit, setUnit] = useState(false);
  const [notice, setNotice] = useState(false);
  const [block, setBlock] = useState(false);
  const [myProperties, setMyProperties] = useState();
  const [serviceProvider, setServiceProvider] = useState();

  function addUnit() {
    setUnit(true);
  }
  function sendNotice() {
    setNotice(true);
  }
  function addBlock() {
    setBlock(true);
  }

  useEffect(() => {
    getAllProperties();
  }, []);

  const getAllProperties = async () => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `https://gh.prop.faspro24.com/api/property/GetPropertyList/${propId}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setMyProperties(result.data);
      })
      .catch((error) => console.log("error", error));
  };

  if (!myProperties) {
    return <Loading />;
  }
  const propDetails = myProperties?.filter((prop) => {
    return prop.PropertyID === parseInt(id);
  });
  const img = propDetails[0]?.Picture?.split("/");

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTagComp meta_tags="FASPRO 24 | Property detail" />
        <Breadcrumb default="Property" title="Property detail" />

        <Card.Body>
          <Card.Body>
            <Link className="btn btn-success mb-3" to="/properties">
              {" "}
              Back{" "}
            </Link>
            <ReportCards id={propDetails[0].PropertyID} />

            <Card className="bd-rds">
              <Card.Body>
                <Container fluid>
                  <Row className="d-flex justify-content-around align-items-center">
                    <Col sm={12} md={6} lg={6}>
                      <h3 className="mb-5 text-success">
                        {propDetails[0].Name}
                      </h3>
                      <p>
                        <b> Property Code </b> {propDetails[0].PropertyCode}
                      </p>
                      <p>
                        <b>Address</b> {propDetails[0].Address}
                      </p>
                      <p>
                        <b> Province </b> {propDetails[0].StateProvince}
                      </p>
                      <p>
                        <b> Postal Code </b> {propDetails[0].PostalCode}
                      </p>
                      <p>
                        <b> Country </b> {propDetails[0].Country}
                      </p>
                      <p>
                        <b> Suburb </b> {propDetails[0].Suburb}
                      </p>
                    </Col>

                    <Col sm={12} md={6} lg={6}>
                      <div className="property-img">
                        <img
                          src={`https://prop.faspro24.com/storage/${img[1]}`}
                          alt=""
                          className="img"
                        />
                      </div>
                    </Col>
                  </Row>
                </Container>
              </Card.Body>
            </Card>
          </Card.Body>
        </Card.Body>
      </div>
    </React.Fragment>
  );
}

PrpertyDetails.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(PrpertyDetails));

import PropTypes from "prop-types";
import React, { useEffect, useCallback, useRef } from "react";

// //Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
// import withRouter from "components/Common/withRouter";
import { withRouter } from "react-router-dom";
import { Link, useLocation } from "react-router-dom";
import { useStore1Selector } from "./../../index";
import { loginUser } from "../../Redux/Slices/userSlice";

//i18n
import { withTranslation } from "react-i18next";

const SidebarContent = (props) => {
  const location = useLocation();
  const ref = useRef();
  const path = location.pathname;
  const userDet = useStore1Selector(loginUser);
  const roles = userDet?.data?.roles[0];

  const activateParentDropdown = useCallback((item) => {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag
        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  const removeActivation = (items) => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      const parent = items[i].parentElement;

      if (item && item.classList.contains("active")) {
        item.classList.remove("active");
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null;
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show");
        }

        parent.classList.remove("mm-active");
        const parent2 = parent.parentElement;

        if (parent2) {
          parent2.classList.remove("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove("mm-active"); // li
            parent3.childNodes[0].classList.remove("mm-active");

            const parent4 = parent3.parentElement; // ul
            if (parent4) {
              parent4.classList.remove("mm-show"); // ul
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.remove("mm-show"); // li
                parent5.childNodes[0].classList.remove("mm-active"); // a tag
              }
            }
          }
        }
      }
    }
  };

  const activeMenu = useCallback(() => {
    const pathName = location.pathname;
    const fullPath = pathName;
    let matchingMenuItem = null;
    const ul = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");
    removeActivation(items);

    for (let i = 0; i < items.length; ++i) {
      if (fullPath === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, [path, activateParentDropdown]);

  useEffect(() => {
    ref.current.recalculate();
  }, []);

  useEffect(() => {
    new MetisMenu("#side-menu");
    activeMenu();
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    activeMenu();
  }, [activeMenu]);

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="fas fa-home"></i>
                <span>{props.t("Properties")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/properties">
                    <i className="fas fa-home"></i>
                    {props.t("Properties")}
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="fas fa-user"></i>
                {props.t("All Users")}
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/users">
                    <i className="fas fa-group"></i>
                    {props.t("All Users")}
                  </Link>
                </li>
              </ul>
            </li>
            {roles === "admin" ||
            roles === "admin_finance" ||
            roles === "admin_assistant" ||
            roles === "admin_support" ? (
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="fas fa-money"></i>
                  <span>{props.t("Billing")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/invoice">
                      <i className="fas fa-file"></i>
                      {props.t("Generate invoice")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/paid-invoice">
                      <i className="fas fa-file"></i>
                      {props.t("paid invoice")}
                    </Link>
                  </li>
                </ul>
              </li>
            ) : null}

            {roles === "admin" ||
            roles === "admin_sales" ||
            roles === "admin_assistant" ||
            roles === "admin_support" ? (
              <li>
                <Link to="/#" className="waves-effect">
                  <i className="fas fa-group"></i>
                  {/* <span className="badge rounded-pill bg-danger float-end">
                    11
                  </span> */}
                  <span>{props.t("Leads")}</span>
                </Link>

                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/Leads">
                      <i className="fa fa-group"></i>
                      {/* <span className="badge rounded-pill bg-danger float-end">
                      11
                    </span> */}
                      {props.t("Leads")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/quotation">
                      <i className="fas fa-file"></i>
                      {props.t("Quotation")}
                    </Link>
                  </li>
                </ul>
              </li>
            ) : null}

            <li>
              <Link to="/#" className="waves-effect">
                <i className="fas fa-box"></i>
                {/* <span className="badge rounded-pill bg-danger float-end">
                  5
                </span> */}
                {props.t("Provision")}
              </Link>

              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/send-registration-link">
                    <i className="fas fa-group"></i>
                    {props.t("Register customer")}
                  </Link>
                </li>
                <li>
                  <Link to="/register-user">
                    <i className="fas fa-user"></i>
                    {props.t("Create license")}
                  </Link>
                </li>
              </ul>
            </li>
            {roles === "admin" || roles === "admin_assistant" ? (
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="fas fa-group"></i>
                  <span>{props.t("Teams")}</span>
                </Link>

                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/team">
                      <i className="fa fa-group"></i>
                      {props.t("View Team")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/add-team">
                      <i className="fas fa-user"></i>
                      {props.t("Add a Team")}
                    </Link>
                  </li>
                </ul>
              </li>
            ) : null}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));

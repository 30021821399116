import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Container, Row, Card, CardBody, Col, Button } from "reactstrap";
import Breadcrumb from "../../components/Common/Breadcrumb";
import MetaTagComp from "../../components/Common/MetaTags";
import Slider from "react-rangeslider";
import "react-rangeslider/lib/index.css";
// import { BsCheckLg } from "react-icons/bs";

function index() {
  const [def, setdef] = useState(1);
  const [value2, setValue2] = useState(0);
  const [discount, setDiscount] = useState(1);
  const facility = 500;
  const [maintenace, setMaintenace] = useState(0);
  const [security, setSecurity] = useState(0);
  const [asset, setAsset] = useState(0);
  const [file, setFile] = useState(0);
  const [booking, setBooking] = useState(0);
  const [show, setShow] = useState(false);
  const [total, setTotal] = useState(0);

  //   const [allchecked, setAllChecked] = useState([]);
  //   function handleChange(e) {
  //     if (e.target.checked) {
  //       setAllChecked([...allchecked, e.target.value]);
  //     } else {
  //       setAllChecked(allchecked.filter((item) => item !== e.target.value));
  //     }
  //   }

  function handleMaintenance(e) {
    setMaintenace(100);
  }
  function handleSecurity(e) {
    setSecurity(100);
  }
  function handleAsset(e) {
    setAsset(100);
  }
  function handleFile(e) {
    setFile(100);
  }
  function handleBooking(e) {
    setBooking(100);
  }
  const discountFunc = (event) => {
    setDiscount(event.target.value);

    console.log("value is:", event.target.value);
  };
  const generateInvoice = () => {
    setShow(true);
    setTotal(
      facility * (def / 500) +
        facility * (value2 / 500) +
        maintenace * (def / 500) +
        maintenace * (value2 / 500) +
        security * (def / 500) +
        security * (value2 / 500) +
        asset * (def / 500) +
        asset * (value2 / 500) +
        file * (def / 500) +
        file * (value2 / 500) +
        booking * (def / 500) +
        booking * (value2 / 500)
    );
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTagComp meta_tags="FASPRO24 | Invoices" />
        <Breadcrumb default="Billing" title="Generate Invoice" />

        <Row>
          <Row>
            <div className="d-flex justify-content-center h4 mb-3">
              Generate Invoice
            </div>
          </Row>
          <Row>
            <Col md={8}>
              <CardBody className="bg-white">
                <div>
                  <Col md={12}>
                    <div className="p-3">
                      <div className="h5">
                        <span>Number of Units</span>
                        <span className="mx-3">{def}</span>
                      </div>
                      <Slider
                        value={def}
                        min={1}
                        max={3000}
                        step={1}
                        orientation="horizontal"
                        onChange={(value) => {
                          setdef(value);
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="p-3">
                      <div className="h5">
                        <span>Manage users</span>
                        <span className="mx-3">{value2}</span>
                      </div>
                      <Slider
                        value={value2}
                        min={0}
                        max={3000}
                        step={1}
                        orientation="horizontal"
                        onChange={(value2) => {
                          setValue2(value2);
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={12}>
                    <Row>
                      <Col md={6}>
                        <h5 className="ms-3">Maintenance Management</h5>
                      </Col>
                      <Col md={6}>
                        <input
                          className="mt-1"
                          value={200}
                          type="checkbox"
                          onChange={handleMaintenance}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <h5 className="ms-3">Security Management</h5>
                      </Col>
                      <Col md={6}>
                        <input
                          className="mt-1"
                          value={300}
                          type="checkbox"
                          onChange={handleSecurity}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <h5 className="ms-3">Asset Management</h5>
                      </Col>
                      <Col md={6}>
                        <input
                          className="mt-1"
                          value={400}
                          type="checkbox"
                          onChange={handleAsset}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <h5 className="ms-3">File Management</h5>
                      </Col>
                      <Col md={6}>
                        <input
                          className="mt-1"
                          value={450}
                          type="checkbox"
                          onChange={handleFile}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <h5 className="ms-3">Booking Management</h5>
                      </Col>
                      <Col md={6}>
                        <input
                          className="mt-1"
                          value={500}
                          type="checkbox"
                          onChange={handleBooking}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col md={6}>
                        <h5 className="ms-3">Discount</h5>
                      </Col>
                      <Col md={6}>
                        <span>
                          <input
                            className="mt-1 w-25"
                            type="number"
                            onChange={discountFunc}
                          />
                        </span>
                        <span className="ms-2 h4">%</span>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col md={6}>
                        <h5 className="ms-3">Total</h5>
                      </Col>
                      <Col md={6}>
                        <h5 className="">$ {total === 0 ? null : total}</h5>
                      </Col>
                    </Row>
                  </Col>
                  {/* <div>The all checked values are {allchecked.join(" , ")}</div> */}
                </div>
                <div className="d-flex justify-content-center mt-5">
                  <Button
                    className="btn bg-dark text-white"
                    onClick={generateInvoice}
                  >
                    Generate Invoice
                  </Button>
                </div>
              </CardBody>
            </Col>
            {!show ? null : (
              <Col md={4}>
                <CardBody className="bg-white d-flex justify-content-center">
                  <div className="w-100">
                    <div>
                      <Row>
                        <Col md={12}>
                          <div className="h3">
                            $ {total === 0 ? null : total}
                          </div>
                        </Col>
                      </Row>
                      {/* <Row>
                      <Col md={8}>
                        <div>Facility Management</div>
                      </Col>
                      <Col md={4}>{500 * def}</Col>
                    </Row>
                    <Row>
                      <Col md={8}>
                        <div>Maintenace Management</div>
                      </Col>
                      <Col md={4}>{}</Col>
                    </Row>
                    <Row>
                      <Col md={8}>
                        <div>Security Management</div>
                      </Col>
                      <Col md={4}>{}</Col>
                    </Row>
                    <Row>
                      <Col md={8}>
                        <div>Asset Management</div>
                      </Col>
                      <Col md={4}>{}</Col>
                    </Row>
                    <Row>
                      <Col md={8}>
                        <div>File Management</div>
                      </Col>
                      <Col md={4}>{}</Col>
                    </Row>
                    <Row>
                      <Col md={8}>
                        <div>Booking Management</div>
                      </Col>
                      <Col md={4}>{}</Col>
                    </Row> */}

                      <div className="d-flex justify-content-center mt-4">
                        <Button className="btn bg-success text-white">
                          Send Invoice
                        </Button>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Col>
            )}
          </Row>
        </Row>
      </div>
    </React.Fragment>
  );
}

export default index;

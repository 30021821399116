import React, { useState } from "react";
import { Container, Row, Col, Spinner } from "reactstrap";
import User_Img from "../../../../assets/images/profile_Img.png";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useStore1Selector } from "./../../../../index";
import { loginUser } from "../../../../Redux/Slices/userSlice";
import useFecth from "../../../../hooks/useFecth";
import usePost from "../../../../hooks/usePost";

function ClientProfile({ editID, reFetchGetAllLeads, closeModal }) {
  const userDet = useStore1Selector(loginUser);
  const token = userDet?.token;
  const { execute, pending, data } = usePost();
  const { data: getClientProfile, reFetch: reFetchGetClientProfile } = useFecth(
    `https://gh.prop.faspro24.com/api/v1/leads/${editID}`,
    token
  );
  const [selectedStatus, setSelectedStatus] = useState("pending");
  const status = ["pending", "qualify", "unqualify"];
  const handleSelectStatus = (event) => {
    setSelectedStatus(event.target.value);
  };
  const handleValidSubmit = () => {
    const Method = "PATCH",
      endPoint = `leads/${editID}`;
    const raw = JSON.stringify({
      status: selectedStatus,
    });
    execute(endPoint, raw, Method, "Lead Successfully Updated", token);
    setTimeout(() => {
      reFetchGetAllLeads();
    }, 2000);
    closeModal(false);
  };
  return (
    <div>
      <AvForm
        className="form-horizontal"
        onValidSubmit={(e, v) => {
          handleValidSubmit(e, v);
        }}
      >
        <Row>
          <Row>
            <Col md={12} className="d-flex justify-content-center mb-4">
              <div
                style={{
                  width: "100px",
                }}
              >
                <img src={User_Img} className="w-100" />
              </div>
            </Col>
          </Row>
          <Row>
            <div className="mb-4">
              <label htmlFor="selectOption">Status:</label>
              <select
                id="selectOption"
                className="btn btn-outline-success mx-2"
                value={selectedStatus}
                onChange={handleSelectStatus}
              >
                {status?.map((item, i) => {
                  return (
                    <option
                      value={item === "unqualify" ? "un-qualify" : item}
                      key={i}
                    >
                      {item}
                    </option>
                  );
                })}
              </select>
              {/* status */}
            </div>
          </Row>
          <Row>
            <Col md={6}>
              <div className="mb-3">
                <AvField
                  name="First Name"
                  value={getClientProfile?.firstName}
                  label="First Name"
                  className="form-control"
                  type="text"
                  disabled
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="mb-3">
                <AvField
                  name="Last Name"
                  value={getClientProfile?.lastName}
                  label="Last Name"
                  className="form-control"
                  type="text"
                  disabled
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="mb-3">
                <AvField
                  name="Email"
                  value={getClientProfile?.email}
                  label="Email"
                  className="form-control"
                  type="text"
                  disabled
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="mb-3">
                <AvField
                  name="Cellphone"
                  value={`+${getClientProfile?.phoneNumber}`}
                  label="Cellphone"
                  className="form-control"
                  type="text"
                  disabled
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="mb-3">
                <AvField
                  name="companyName"
                  value="N/A"
                  label="Company name"
                  className="form-control"
                  type="text"
                  disabled
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="mb-3">
                <AvField
                  name="companySize"
                  value={getClientProfile?.companySize}
                  label="Company size"
                  className="form-control"
                  type="text"
                  disabled
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="mb-3">
                <b>Interested modules :</b>
                <div></div>
                {getClientProfile?.interestedModules?.map((item, i) => {
                  return (
                    <span className="badge bg-success p-2 m-1" key={i}>
                      {item}
                    </span>
                  );
                })}
              </div>
            </Col>
          </Row>
        </Row>
        <div className="mb-3 row ">
          <div className="col-12 d-flex justify-content-center">
            <button
              className="btn login-btn w-md waves-effect waves-light"
              type="submit"
            >
              {!pending ? <span className="me-2"> Update </span> : null}

              {!pending ? null : (
                <span>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    className="me-2"
                  />
                  Loading...
                </span>
              )}
            </button>
          </div>
        </div>
      </AvForm>
    </div>
  );
}

export default ClientProfile;

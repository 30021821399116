import React, { useEffect } from "react";
import { Row, Spinner } from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import { useHistory } from "react-router-dom";
import usePost from "../../../hooks/usePost";

function EditForm({
  formattedData,
  leadId,
  quotationId,
  getLeadDetails,
  token,
  discount,
  vatPercentage,
  subTotal,
}) {
  const { execute, pending, data: dataPost } = usePost();
  let history = useHistory();
  const todayDate = new Date().toISOString().split("T")[0];
  const currentDate = new Date().toISOString().split("T")[0];

  const settingId = "669612408f9b6bd458081551";

  const totalAmount = subTotal.toFixed(2);
  const discountedPrice = (totalAmount * discount) / 100;
  const discountedTotalPrice = totalAmount - discountedPrice;
  const vatTotalPrice = (discountedTotalPrice * vatPercentage) / 100;
  const generalTotalPrice = discountedTotalPrice + vatTotalPrice;

  const handleValidSubmit = (e, v) => {
    e.preventDefault();

    const Method = "PATCH",
      endPoint = `quotations/${quotationId}/${leadId}/${settingId}`;
    const raw = JSON.stringify({
      numberOfUnits: formattedData?.units,
      discountPercentage: formattedData?.discountPercentage,
      vatPercentage: formattedData?.vatPercentage,
      moduleAndPrice: formattedData?.modulePrices,
    });
    execute(endPoint, raw, Method, "Quotation successfully generated", token);
  };

  useEffect(() => {
    if (dataPost?.status === "success") {
      history.push("/quotation");
    }
  }, [dataPost?.status]);

  return (
    <div className="w-100">
      <AvForm
        className="form-horizontal mt-4"
        onValidSubmit={(e, v) => {
          handleValidSubmit(e, v);
        }}
      >
        <div>
          <Row>
            <div className="col-12">
              <Row>
                <div className="col-6">
                  <address>
                    <strong>Bill To:</strong>
                    <br />
                    {getLeadDetails?.firstName} {getLeadDetails?.lastName}
                    <br />
                    {getLeadDetails?.email}
                    <br />
                    {`+${getLeadDetails?.phoneNumber}`}
                    <br />
                    {todayDate}
                  </address>
                </div>
                <div className="col-6 text-end">
                  <strong></strong>
                  <address></address>
                </div>
              </Row>
              <Row>
                <div className="col-6">
                  <strong>Date: {currentDate}</strong>
                  <address></address>
                </div>
              </Row>
            </div>
          </Row>

          <Row>
            <div className="col-12">
              <div>
                <div className="p-2">
                  <h3 className="font-size-16"></h3>
                </div>
                <div className="">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <td>
                            <strong>Items(s)</strong>
                          </td>
                          <td className="text-center">
                            <strong>Price per Unit</strong>
                          </td>
                          <td className="text-end">
                            <strong>
                              Amount (X{formattedData?.units} Units)
                            </strong>
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {formattedData?.modulePrices?.map((item, i) => {
                          return (
                            <tr key={i}>
                              <td>{item?.module}</td>
                              <td className="text-center">${item?.price}</td>
                              <td className="text-end">
                                ${item?.price * formattedData?.units}
                              </td>
                            </tr>
                          );
                        })}

                        <tr>
                          <td className="no-line"></td>
                          <td className="no-line text-center">
                            <strong>Subtotal</strong>
                          </td>
                          <td className="no-line text-end">${subTotal}</td>
                        </tr>
                        <tr>
                          <td className="no-line"></td>
                          <td className="no-line text-center">
                            <strong>Discount</strong>
                          </td>
                          <td className="no-line text-end">
                            ${discountedPrice}
                          </td>
                        </tr>
                        <tr>
                          <td className="no-line"></td>
                          <td className="no-line text-center">
                            <strong>Vat</strong>
                          </td>
                          <td className="no-line text-end">${vatTotalPrice}</td>
                        </tr>
                        <tr>
                          <td className="no-line"></td>
                          <td className="no-line text-center">
                            <strong>Total </strong>
                          </td>
                          <td className="no-line text-end">
                            <h4 className="m-0">
                              ${generalTotalPrice?.toFixed(2)}
                            </h4>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="d-flex justify-content-center">
                      <button
                        className="btn login-btn w-md waves-effect waves-light"
                        type="submit"
                      >
                        {!pending ? (
                          <span className="me-2"> Update Quotation </span>
                        ) : null}

                        {!pending ? null : (
                          <span>
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              className="me-2"
                            />
                            Loading...
                          </span>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Row>
        </div>
      </AvForm>
    </div>
  );
}

export default EditForm;

import React from "react";
import { Row, Col } from "react-bootstrap";
import { ThreeCircles } from "react-loader-spinner";

function LoadingTable() {
  return (
    <div className="w-100 d-flex justify-content-center align-items-center">
      <Row>
        <Col md={12}>
          <ThreeCircles
            height="300"
            width="300"
            color="#23B25A"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="three-circles-rotating"
            outerCircleColor=""
            innerCircleColor=""
            middleCircleColor=""
          />
        </Col>
      </Row>
    </div>
  );
}

export default LoadingTable;

import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Container, Row, Card, CardBody, Button, Spinner } from "reactstrap";
import { Modal } from "react-bootstrap";
import { MDBDataTable } from "mdbreact";
import { withRouter, Link, useParams } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { CSVLink } from "react-csv";
import { useStore1Selector } from "../../../index";
import { loginUser } from "../../../Redux/Slices/userSlice";
import usePost from "../../../hooks/usePost";
import useFecth from "../../../hooks/useFecth";
import ClientForm from "../ClientProfile";

function QualifiedLeads() {
  // const { id } = useParams();
  const userDet = useStore1Selector(loginUser);
  const token = userDet?.token;
  const { execute, pending } = usePost();
  const { data: getAllLeads, reFetch: reFetchGetAllLeads } = useFecth(
    `https://gh.prop.faspro24.com/api/v1/leads?status=qualify`,
    token
  );
  const ownerId = userDet?.original?.userDetails?.OwnerID;

  const [takeLeadModal, setTakeLeadModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [viewID, setViewID] = useState();
  const [delteID, setDeleteID] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const listLeads = [];
  getAllLeads?.forEach((res) => {
    listLeads.push({
      ...res,
      lead_status: (
        <label className="text-success">
          {res?.status === "qualify" ? "Qualified" : null}
        </label>
      ),
      Phone_Number: `+${res?.phoneNumber}`,

      viewBtn: (
        <button className="btn btn-success" onClick={() => viewFunc(res?._id)}>
          view
        </button>
      ),
      editBtn: (
        <button
          className="btn btn-primary"
          onClick={() => TakeLeadFunc(res?._id)}
        >
          <span className="me-2">Take Lead</span>
          {!pending ? null : <Spinner as="span" animation="border" size="sm" />}
        </button>
      ),
      deleteBtn: (
        <button className="btn btn-danger" onClick={() => deleteFunc(res?._id)}>
          Delete
        </button>
      ),
    });
  });

  function viewFunc(id) {
    setViewModal(true);
    setViewID(id);
  }

  function TakeLeadFunc(id) {
    const Method = "PATCH",
      endPoint = `leads/${id}`;
    const raw = JSON.stringify({
      status: "pending",
    });
    execute(endPoint, raw, Method, "Action Successfully Taken", token);
    setTimeout(() => {
      reFetchGetAllLeads();
    }, 2000);
  }

  function deleteFunc(id) {
    setDeleteID(id);
    setDeleteModal(true);
  }

  const deleteLeads = () => {
    const Method = "DELETE",
      endPoint = `leads/${delteID}`,
      isJSON = true;
    var formdata = new FormData();
    execute(
      endPoint,
      formdata,
      Method,
      "Lead Successfully Deleted",
      token,
      isJSON
    );
    setTimeout(() => {
      reFetchGetAllLeads();
    }, 2000);
    setDeleteModal(false);
  };

  const column = [
    { label: "First name", field: "firstName", sort: "asc", width: 150 },
    { label: "Last name", field: "lastName", sort: "asc", width: 150 },

    { label: "Email", field: "email", sort: "asc", width: 150 },
    { label: "Phone number", field: "Phone_Number", sort: "asc", width: 150 },
    { label: "Company size", field: "companySize", sort: "asc", width: 150 },
    // { label: "Assigned To", field: "", sort: "asc", width: 150 },
    { label: "Status", field: "lead_status", sort: "asc", width: 150 },
    // { label: "Action", field: "editBtn", sort: "asc", width: 150 },
    { label: "View", field: "viewBtn", sort: "asc", width: 150 },

    // { label: "Delete", field: "deleteBtn", sort: "asc", width: 150 },
  ];

  const newData = {
    columns: column,
    rows: listLeads.reverse(),
  };
  // console.log("get getAllLeads...", getAllLeads);
  return (
    <React.Fragment>
      <CardBody>
        <Container fluid>
          {/* <Link to={`/property-detail/${id}`} className="btn login-btn mb-2">
              {" "}
              Back{" "}
            </Link> */}
          <Row className="d-flex justify-content-around align-items-center">
            <CardBody data-aos="fade-bottom">
              <Card className="bd-rds">
                <CardBody>
                  <MDBDataTable
                    entries={5}
                    entriesOptions={[5, 10, 50]}
                    responsive
                    bordered
                    striped
                    hover
                    data={newData}
                  />
                </CardBody>
              </Card>
            </CardBody>
          </Row>
        </Container>
      </CardBody>

      <Modal show={viewModal} onHide={() => setViewModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Client Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ClientForm viewID={viewID} />
          {/* <TenantDetails TenantDetail={TenantDetailRes} /> */}
        </Modal.Body>
      </Modal>

      <Modal
        show={takeLeadModal}
        onHide={() => setTakeLeadModal(false)}
        size="lg"
      >
        <Modal.Body>
          {/* <ViewModal id={viewID} tenantList={myTenant} /> */}
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-end p-2">
          <Button
            className="bg-danger text-white"
            onClick={() => setTakeLeadModal(false)}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={deleteModal} onHide={() => setDeleteModal(false)} size="sm">
        <Modal.Body className="text-danger">
          <h6> Are you sure you want to delete this ? </h6>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="bg-danger text-white"
            onClick={() => setDeleteModal(false)}
          >
            Cancel
          </Button>
          <Button className="bg-success text-white" onClick={deleteLeads}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

QualifiedLeads.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};
export default withRouter(withTranslation()(QualifiedLeads));

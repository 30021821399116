import React, { useState, useEffect } from "react";
import { Row, CardBody, Col, Button } from "reactstrap";
import { Modal } from "react-bootstrap";
import useFecth from "../../../hooks/useFecth";
import LeadInfo from "../../Lead/ClientProfile";
import ModalForm from "./ModalForm";

const InputsForm = ({ leadId, token }) => {
  const [formValues, setFormValues] = useState({
    facility: "",
    maintenance: "",
    security: "",
    asset: "",
    file: "",
    bookings: "",
  });

  const { data: getLeadDetails, reFetch: reFetchLeadById } = useFecth(
    `https://gh.prop.faspro24.com/api/v1/leads/${leadId}`,
    token
  );

  const { data: getSettingsDetails, reFetch: reFetchGetSettingsDetails } =
    useFecth(
      `https://gh.prop.faspro24.com/api/v1/invoiceSettings/669612408f9b6bd458081551`,
      token
    );
  const [displayModal, setDisplayModal] = useState(false);
  const [units, setUnits] = useState("");
  const [discount, setDiscount] = useState(0);
  const [vatPercentage, setVatPercentage] = useState(0);
  const [subTotal, setSubTotal] = useState(0);

  // New state for the formatted data
  const [formattedData, setFormattedData] = useState({
    modulePrices: [],
    discountPercentage: "",
    vatPercentage: "",
    units: "",
  });

  // Function to handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleUnitsChange = (e) => {
    setUnits(e.target.value);
  };

  const handleDiscountChange = (e) => {
    setDiscount(e.target.value);
  };

  const handleVatChange = (e) => {
    setVatPercentage(e.target.value);
  };

  useEffect(() => {
    const modulePrices = Object.entries(formValues)
      .filter(([module, price]) => price)
      .map(([module, price]) => ({ module, price: parseFloat(price) }));

    const totalModulesPrice = modulePrices.reduce(
      (acc, item) => acc + item.price,
      0
    );

    const unitCount = units ? parseInt(units, 10) : 0;
    const totalBeforeDiscountAndVat = totalModulesPrice * unitCount;

    setSubTotal(totalBeforeDiscountAndVat);
    // Update formattedData state
    setFormattedData({
      modulePrices,
      discountPercentage: discount ? parseInt(discount) : 0,
      vatPercentage: vatPercentage ? parseInt(vatPercentage) : 0,
      units: units ? parseInt(units) : 0,
    });
  }, [formValues, units, discount, vatPercentage]);

  const checkLowPrice = (value) => {
    return value &&
      parseInt(value, 10) < getSettingsDetails?.minimumPricePerModule
      ? "Low price"
      : "";
  };

  const toggleModal = () => {
    setDisplayModal(!displayModal);
  };
  const HandleCalculateFunc = (e) => {
    setDisplayModal(true);
  };

  return (
    <Row>
      <Col md={8}>
        <CardBody className="bg-white p-4">
          <div>
            <form>
              <Row className="mb-3">
                <Col md={3}>
                  <label>Number of Units:</label>
                </Col>
                <Col md={6}>
                  <input
                    type="number"
                    value={units}
                    onChange={handleUnitsChange}
                    className="w-50"
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={3}>
                  <label>Facility Management:</label>
                </Col>
                <Col md={6}>
                  <input
                    className="w-50"
                    type="number"
                    name="facility"
                    value={formValues.facility}
                    onChange={handleInputChange}
                  />

                  <span className="text-danger mx-2">
                    {checkLowPrice(formValues.facility)}
                  </span>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={3}>
                  <label>Security Management:</label>
                </Col>
                <Col md={6}>
                  <input
                    className="w-50"
                    type="number"
                    name="security"
                    value={formValues.security}
                    onChange={handleInputChange}
                  />
                  <span className="text-danger mx-2">
                    {checkLowPrice(formValues.security)}
                  </span>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={3}>
                  <label>Maintenance Management: </label>
                </Col>
                <Col md={6}>
                  <input
                    className="w-50"
                    type="number"
                    name="maintenance"
                    value={formValues.maintenance}
                    onChange={handleInputChange}
                  />
                  <span className="text-danger mx-2">
                    {checkLowPrice(formValues.maintenance)}
                  </span>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={3}>
                  <label>Asset Management: </label>
                </Col>
                <Col md={6}>
                  <input
                    type="number"
                    name="asset"
                    value={formValues.asset}
                    onChange={handleInputChange}
                    className="w-50"
                  />
                  <span className="text-danger mx-2">
                    {checkLowPrice(formValues.asset)}
                  </span>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={3}>
                  <label>File Management: </label>
                </Col>
                <Col md={6}>
                  <input
                    type="number"
                    name="file"
                    value={formValues.file}
                    onChange={handleInputChange}
                    className="w-50"
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={3}>
                  <label> Bookings Management: </label>
                </Col>
                <Col md={6}>
                  <input
                    type="number"
                    name="bookings"
                    value={formValues.bookings}
                    onChange={handleInputChange}
                    className="w-50"
                  />
                  <span className="text-danger mx-2">
                    {checkLowPrice(formValues.bookings)}
                  </span>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={3}>
                  <label>Discount Percentage:</label>
                </Col>
                <Col md={6}>
                  <input
                    type="number"
                    value={discount}
                    onChange={handleDiscountChange}
                    className="w-25"
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={3}>
                  <label>VAT Percentage: </label>
                </Col>
                <Col md={6}>
                  <input
                    type="number"
                    value={vatPercentage}
                    onChange={handleVatChange}
                    className="w-25"
                  />
                </Col>
              </Row>
            </form>
            <div className="w-100 mb-3">
              <h5 className="text-success">
                SubTotal: $ {subTotal.toFixed(2)}
              </h5>
            </div>
            <div className="w-100 mb-3">
              <button
                className="btn btn-dark w-md waves-effect waves-light"
                onClick={HandleCalculateFunc}
              >
                <span className="me-2" style={{ color: "white" }}>
                  Calculate
                </span>
              </button>
            </div>
          </div>
        </CardBody>
      </Col>
      <Col md={4}>
        <Row>
          <Col md={12}>
            <CardBody className="bg-white d-flex justify-content-center">
              <LeadInfo
                viewID={getLeadDetails?._id}
                getLeadDetails={getLeadDetails}
                leadId={leadId}
              />
            </CardBody>
          </Col>
        </Row>
      </Col>
      <Modal show={displayModal} onHide={toggleModal} size="lg">
        <Modal.Header>
          <Modal.Title>Generate Quotation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModalForm
            formattedData={formattedData}
            leadId={leadId}
            getLeadDetails={getLeadDetails}
            token={token}
            discount={discount}
            vatPercentage={vatPercentage}
            subTotal={subTotal}
          />
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-end p-2">
          <Button className="bg-danger text-white" onClick={toggleModal}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </Row>
  );
};

export default InputsForm;

import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import React from "react"
import { Row, Col } from "reactstrap"
import "./Style.scss"
import MetaTagComp from "../../../components/Common/MetaTags";
import Slide from "../../../components/Slide";
import Cards from "./Components/Cards";

const ChooseLogin = () => {
  return (
    <React.Fragment>
      <MetaTagComp meta_tags="FASPRO24 | Login" />

      <div className="account-pages">
        <Row className="login">

          <Col md={6} className="login-left">
            <Cards />
          </Col>

          <Col md={6} className="login-container">
            <div className="login-img-container">
              <Slide />
            </div>
          </Col>

        </Row>

      </div>
    </React.Fragment>
  )
}

export default ChooseLogin
import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Row, Col, Card, CardBody, Spinner } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import logoSm from "../../../../assets/images/favicon.png";
import { useStore1Dispatch } from "./../../../../index";
import { Login } from "../../../../Redux/Slices/userSlice";
import usePost from "../../../../hooks/usePostLogin";
import { LoginMsg } from "../../../../components/NotifyMessage";

function AdminLogin() {
  let history = useHistory();
  const dispatch = useStore1Dispatch();
  const { execute, pending, data, status } = usePost();

  const handleValidSubmit = async (e, v) => {
    e.preventDefault();

    const Method = "POST",
      endPoint = "auth/login";
    const raw = JSON.stringify({
      email: v.email,
      password: v.password,
    });
    execute(endPoint, raw, Method, LoginMsg);
  };
  useEffect(() => {
    if (status === "success") {
      dispatch(Login(data));
      window.setTimeout(() => {
        history.push("/properties");
      }, 2000);
    }
  }, [status]);

  return (
    <div className="login-content-container">
      <div>
        <Card className="overflow-hidden">
          <div className="login-header">
            <div className="text-primary text-center p-4">
              <p className="text-white-50">Login as admin</p>
              <Link to="/" className="logo logo-admin">
                <img src={logoSm} alt="logo" className="favicon-logo" />
              </Link>
            </div>
          </div>

          <CardBody className="p-4">
            <div className="p-3">
              <AvForm
                className="form-horizontal mt-4"
                onValidSubmit={(e, v) => {
                  handleValidSubmit(e, v);
                }}
              >
                <div className="mb-3">
                  <AvField
                    name="email"
                    label="Email"
                    className="form-control"
                    type="email"
                    required
                  />
                </div>

                <div className="mb-3">
                  <AvField
                    name="password"
                    label="Password"
                    type="password"
                    required
                  />
                </div>

                <Row className="mb-3">
                  <Col sm={6} className="text-end">
                    <button
                      className="btn login-btn w-md waves-effect waves-light"
                      type="submit"
                    >
                      <span className="me-2">Log In </span>
                      {!pending ? null : (
                        <Spinner as="span" animation="border" size="sm" />
                      )}
                    </button>
                  </Col>
                </Row>

                <Row className="mt-2 mb-0 row">
                  <div className="col-12 mt-4">
                    <Link to="/forgot-password" className="remember">
                      <i className="mdi mdi-lock"></i> Forgot your password?
                    </Link>
                  </div>
                </Row>
              </AvForm>
            </div>
          </CardBody>
        </Card>
        <div className="mt-5 text-center">
          <p> © {new Date().getFullYear()} FASPRO24 </p>
        </div>
      </div>
    </div>
  );
}

export default AdminLogin;

import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Container, Row, Card, CardBody, Spinner } from "reactstrap";
import { Modal } from "react-bootstrap";
import { MDBDataTable } from "mdbreact";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import MetaTagComp from "../../../components/Common/MetaTags";
import { useStore1Selector } from "../../../index";
import { loginUser } from "../../../Redux/Slices/userSlice";

import useCustomFecthQuery from "../../../hooks/useCustomFecthQuery";
import useCustomMutation from "../../../hooks/useCustomMutation";
import LoadingTable from "../../../components/LoadingTable";

import LeadProfile from "../../Lead/ClientProfile";
// import ClientForm from "../ClientProfile";

// import TopMenu from "./Components/TopMenu";

function LeadInvoice() {
  const userDet = useStore1Selector(loginUser);
  const token = userDet?.token;
  const user = userDet?.data?.id;
  const queryClient = new QueryClient();
  const key = ["LeadInvoice", user];
  // const mutationDelete = useCustomMutation(key);
  const mutationSent = useCustomMutation(key);

  // const {
  //   data: getSettingsDetails,
  //   error,
  //   isLoading,
  // } = useCustomFecthQuery(
  //   ["invoiceSettings", user],
  //   `https://gh.prop.faspro24.com/api/v1/invoiceSettings/669612408f9b6bd458081551`,
  //   token,
  //   { enabled: !!user }
  // );

  const {
    data: getAllQuotation,
    error: errorAllQuotation,
    isLoading: isLoadingAllQuotation,
  } = useCustomFecthQuery(
    ["quotations", user],
    `https://gh.prop.faspro24.com/api/v1/quotations?status=paid`,
    token,
    { enabled: !!user }
  );

  const [viewModal, setViewModal] = useState(false);

  const [viewID, setViewID] = useState();
  const [invoiceID, setInvoiceID] = useState(null);
  const listQuotations = [];
  getAllQuotation?.forEach((res) => {
    listQuotations.push({
      ...res,
      firstName: res?.leadId?.firstName,
      lastName: res?.leadId?.lastName,
      email: res?.leadId?.email,
      generatedBy: `${
        res?.leadId?.salesId?.firstName
      } ${res?.leadId?.salesId?.lastName?.substring(0, 1)}.`,
      createdAt: res?.created_at?.split("T")[0],
      quotation_status: (
        <label className="text-success">
          {res?.status === "paid" ? "Complete" : null}
        </label>
      ),

      viewBtn: (
        <button
          className="btn btn-dark"
          onClick={() => viewFunc(res?.leadId?._id)}
        >
          view
        </button>
      ),
      sendBtn: (
        <button
          className={!res?.invoiceStatus ? "btn btn-primary" : "btn btn-danger"}
          onClick={() => SendInvoiceFunc(res?.leadId?._id, res?._id)}
        >
          {invoiceID === res?._id && mutationSent?.isLoading ? (
            <>
              <Spinner as="span" animation="border" size="sm" />{" "}
              <span>Loading</span>
            </>
          ) : (
            <>
              {!res?.invoiceStatus ? "Generate invoice" : "Regenerate invoice"}
            </>
          )}
        </button>
      ),
    });
  });

  function viewFunc(id) {
    setViewModal(true);
    setViewID(id);
  }

  // endPoint = `leadInvoices/${leadId}/${getSettingsDetails?._id}/${quotationId}`;

  function SendInvoiceFunc(leadId, quotationId) {
    setInvoiceID(quotationId);
    const currentDate = new Date().toISOString().split("T")[0];
    const Method = "POST",
      endPoint = `leadInvoices/${leadId}/669612408f9b6bd458081551/${quotationId}`;
    const raw = JSON.stringify({
      dueDate: currentDate,
    });
    const data = {
      endPoint: endPoint,
      bodyContent: raw,
      Method: Method,
      displayMessage: "Invoice successfully sent",
      token: token,
      isJSON: null,
    };
    mutationSent.mutate(data);
  }

  const column = [
    { label: "First name", field: "firstName", sort: "asc", width: 150 },
    { label: "Last name", field: "lastName", sort: "asc", width: 150 },
    { label: "Email", field: "email", sort: "asc", width: 150 },
    { label: "Generated By", field: "generatedBy", sort: "asc", width: 150 },
    // { label: "Date", field: "createdAt", sort: "asc", width: 150 },
    {
      label: "Quotation Number",
      field: "quotationNumber",
      sort: "asc",
      width: 150,
    },
    { label: "Status", field: "quotation_status", sort: "asc", width: 150 },
    { label: "View", field: "viewBtn", sort: "asc", width: 150 },
    { label: "Action", field: "sendBtn", sort: "asc", width: 150 },
  ];

  const newData = {
    columns: column,
    rows: listQuotations.reverse(),
  };

  useEffect(() => {
    if (mutationSent?.isSuccess) {
      queryClient.invalidateQueries(["quotations", user]);
    }
  }, [mutationSent?.isSuccess]);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTagComp meta_tags="FASPRO 24 | Billing" />
        <Breadcrumb default="Invoice" title="Send invoice" />

        <CardBody>
          <Container fluid>
            <Row className="d-flex justify-content-around align-items-center">
              <CardBody data-aos="fade-bottom">
                <Card className="bd-rds">
                  <CardBody>
                    {isLoadingAllQuotation ? (
                      <LoadingTable />
                    ) : (
                      <MDBDataTable
                        entries={5}
                        entriesOptions={[5, 10, 50]}
                        responsive
                        bordered
                        striped
                        hover
                        data={newData}
                      />
                    )}
                  </CardBody>
                </Card>
              </CardBody>
            </Row>
          </Container>
        </CardBody>
      </div>
      <Modal show={viewModal} onHide={() => setViewModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Client Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <LeadProfile viewID={viewID} />
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}

LeadInvoice.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};
export default withRouter(withTranslation()(LeadInvoice));

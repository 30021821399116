import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Container, Row, Card } from "react-bootstrap";
import { MDBDataTable } from "mdbreact";
import { withRouter, Link, useParams } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Breadcrumb from "../../components/Common/Breadcrumb";
import MetaTagComp from "../../components/Common/MetaTags";
import Loading from "components/Loading";
import { warningMessage, successMessage } from "../../components/Notifications";

function MyUnits() {
  const { id } = useParams();
  const [editDet, setEdit] = useState(false);
  const [deleteUnit, setDeleteUnit] = useState(false);
  const [myUnits, setMyUnits] = useState();
  const [dataDb, setData] = useState([]);

  function editFunc(Id_unit) {
    setEdit(true);
    setMyUnits(Id_unit);
  }

  function deleteFunc(Id_unit) {
    setDeleteUnit(true);
    setMyUnits(Id_unit);
  }

  useEffect(() => {
    let list = [];
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `https://gh.prop.faspro24.com/api/unit/GetUnitsByPropertyID/${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        const resArr = result?.data;
        resArr.forEach((res) => {
          list.push({
            ...res,
            // viewBtn: <button className="btn btn-success"> <Link to={`/unit-details/${id}/${res.UnitID}`} className="text-white"> View </Link>  </button>,
            // editBtn: <button className="btn btn-primary" onClick={() => editFunc(`${res.UnitID}`)}> Edit </button>,
            deleteBtn: (
              <button
                className="btn btn-danger"
                onClick={() => deleteFunc(`${res.UnitID}`)}
              >
                {" "}
                Delete{" "}
              </button>
            ),
          });
        });
        setData(list.sort().reverse());
      })
      .catch((error) => console.log("error", error));
  }, []);

  const unitDetails = dataDb?.filter((unit) => {
    return unit.UnitID === parseInt(myUnits);
  });

  const deleteUnitFunc = () => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `https://gh.prop.faspro24.com/api/unit/DeleteUnit/${myUnits}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          successMessage("Successful added");
          setDeleteUnit(false);
        }
      })
      .catch((error) => {
        warningMessage(`try again something went wrong${error.message}`);
      });
  };

  if (!dataDb) {
    return <Loading />;
  }

  const column = [
    { label: "Unit Name", field: "Name", sort: "asc", width: 150 },
    { label: "Unit Type", field: "Type", sort: "asc", width: 150 },
    { label: "Unit Number", field: "UnitNumber", sort: "asc", width: 150 },
    { label: "Unit Owner", field: "UnitOwner", sort: "asc", width: 150 },
    { label: "Parking", field: "Parking", sort: "asc", width: 150 },
    // { label: "View", field: "viewBtn", sort: "asc", width: 150 },
    // { label: "Edit", field: "editBtn", sort: "asc", width: 150 },
    { label: "Delete", field: "deleteBtn", sort: "asc", width: 150 },
  ];

  const data = {
    columns: column,
    rows: dataDb,
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTagComp meta_tags="FASPRO 24 | Unit" />
        <Breadcrumb default="Dashboard" title="My Units" />

        <Card.Body>
          <Link to={`/property-detail/${id}`} className="btn login-btn mb-3">
            {" "}
            Back{" "}
          </Link>
          <Container fluid>
            <Row
              className="d-flex justify-content-around align-items-center"
              data-aos="fade-bottom"
            >
              <Card className="bd-rds">
                <Card.Body>
                  <MDBDataTable
                    entries={5}
                    entriesOptions={[5, 10, 50]}
                    responsive
                    bordered
                    striped
                    hover
                    data={data}
                    fullPagination
                  />
                </Card.Body>
              </Card>
            </Row>
          </Container>
        </Card.Body>
      </div>
    </React.Fragment>
  );
}

MyUnits.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(MyUnits));

import React from "react";
import { Redirect } from "react-router-dom";
import PropertyOwner from "../pages/Authentication/Login/PropertyOwner";
import PropertyAgency from "../pages/Authentication/Login/PropertyAgency";
import ServiceProvider from "../pages/Authentication/Login/ServiceProvider";
import BackOffice from "../pages/Authentication/Login/BackOffice";
import ChooseLogin from "../pages/Authentication/Login-type/Index";
import ForgetPwd from "../pages/Authentication/Forget-password/Index";
import ResetPwd from "../pages/Authentication/Reset Password/index";
import ContinueRegister from "../pages/Authentication/Signup/Components/Choose-signup-method";
import PropertyManagement from "../pages/Authentication/Index";
import EastateManagement from "../pages/Authentication/Estate/Index";

import verifyEmail from "../pages/Authentication/verifyEmail";
import Dashboard from "../pages/Dashboard/index";
import Properties from "../pages/All properties/index";
import AddProperty from "../pages/Properties/Add-property";
import AdminLogin from "../pages/Authentication/Login/AdminLogin";
import Users from "../pages/Users/Users";
import MyProfile from "../pages/Profile/Profile";
import Modules from "../pages/Modules/index";
import PropertyDetails from "../pages/Admin Properties/PropertyDetails";
import Units from "../pages/Units/index";
import Tenants from "../pages/Tenants/index";

import Billing from "../pages/Billing/index";
import PaidLeadInvoice from "../pages/Invoice/Table/PaidInvoice";
import NewLeadTable from "../pages/Lead/Table/Index";
// import QualifiedTable from "../pages/Lead/Table/QualifiedLead";
// import PendingTable from "../pages/Lead/Table/PendingLeads";
// import UnqualifiedTable from "../pages/Lead/Table/UnqualifiedLeads";
import SendRegLinkTable from "../pages/QualifiedCustomer/Table/RegisterCustomer";
import CreateLicenseTable from "../pages/QualifiedCustomer/Table/CreateLicense";

import GenerateQuotation from "../pages/Quotation/GenerateQuotation/Generate";
import EditQuotation from "../pages/Quotation/EditQuotation/EditQuotation";

import AllQuotationTable from "../pages/Quotation/Table/AllQuotation";
import SendLeadInvoiceTable from "../pages/Invoice/Table/LeadInvoice";
import TeamTable from "../pages/Team/Table/AllTeam";
import AddTeamMember from "../pages/Team/AddTeam/addTeam";

const userRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/properties", component: Properties },
  // { path: "/add-property-owner", component: AddPropertyOwner },

  { path: "/users", component: Users },
  { path: "/all-modules", component: Modules },
  { path: "/add-property", component: AddProperty },
  { path: "/property-detail/:id", component: PropertyDetails },
  { path: "/units/:id", component: Units },
  { path: "/tenants/:id", component: Tenants },
  { path: "/billing", component: Billing },
  { path: "/paid-invoice", component: PaidLeadInvoice },

  { path: "/Leads", component: NewLeadTable },
  // { path: "/qualified-leads", component: QualifiedTable },
  // { path: "/pending-leads", component: PendingTable },
  // { path: "/unqualified-leads", component: UnqualifiedTable },

  { path: "/generate-quotation/:leadId", component: GenerateQuotation },
  { path: "/edit-quotation/:leadId/:quotationId", component: EditQuotation },
  { path: "/quotation", component: AllQuotationTable },
  { path: "/invoice", component: SendLeadInvoiceTable },
  { path: "/send-registration-link", component: SendRegLinkTable },
  { path: "/register-user", component: CreateLicenseTable },
  { path: "/team", component: TeamTable },
  { path: "/add-team", component: AddTeamMember },
  { path: "/my-profile", component: MyProfile },

  { path: "/", exact: true, component: () => <Redirect to="/login" /> },
];

const authRoutes = [
  { path: "/login", component: AdminLogin },
  { path: "/login/property-owner", component: PropertyOwner },
  { path: "/login/property-agency", component: PropertyAgency },
  { path: "/login/service-provider", component: ServiceProvider },
  { path: "/login/back-office", component: BackOffice },
  { path: "/login", component: ChooseLogin },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/resetPassword/:tokenId", component: ResetPwd },
  { path: "/signup/property-owner", component: PropertyManagement },
  { path: "/signup/property-agency", component: EastateManagement },
  { path: "/signup", component: ContinueRegister },
  { path: "/modules", component: Modules },
  { path: "/VerifyEmail/:id", component: verifyEmail },
];

export { userRoutes, authRoutes };

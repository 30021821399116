import React from "react";
import "../Style.scss";
import { Row, Col, Card, CardBody } from "reactstrap";
import { card_arr } from "./../Components/Object";
import { Link } from "react-router-dom";
import Logo from "../../../../assets/images/faspro24_logo_original.png";

function Cards() {
  return (
    <CardBody>
      <CardBody>
        <div className="logo w-50">
          <img src={Logo} alt="" />
        </div>

        <h2 className="text-center text-success mt-4"> Login as </h2>

        <Row className="justify-content-center mt-5">
          <Row>
            {card_arr.map((card, idx) => (
              <Col md={6} key={idx}>
                <Link to={`/${card.link}`}>
                  <Card className="bd-rds card-hover" data-aos="fade-bottom">
                    <CardBody>
                      <h4 className="text-center mb-3 mt-3 text-success color">
                        {" "}
                        {card.name}{" "}
                      </h4>
                    </CardBody>
                  </Card>
                </Link>
              </Col>
            ))}
          </Row>
        </Row>
      </CardBody>
      <div className="mt-5 text-center">
        <p>
          {" "}
          Don&#39;t have an account?{" "}
          <Link
            to="/signup"
            className="fw-medium remember"
          >{`  Signup now`}</Link>{" "}
        </p>
        <p> © {new Date().getFullYear()} FASPRO24 </p>
      </div>
    </CardBody>
  );
}

export default Cards;

const LoginMsg = "Successfully logged In";
const ForgotPasswordMsg = "Successfully send ";
const ResetPasswordMsg = "Successfully update your password";
const LogoutMsg = "Successfully logout";
const RegisterMsg = "Successfully register";
const verifyMsg = "Successfully verify";
const UpdatePasswordMsg = "Password successfully updated";

export {
  LoginMsg,
  ForgotPasswordMsg,
  ResetPasswordMsg,
  LogoutMsg,
  RegisterMsg,
  verifyMsg,
  UpdatePasswordMsg,
};

import PropTypes from "prop-types"
import React from 'react'
import { Col, Row, Card, CardBody } from "reactstrap";
import { withRouter, Link } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { BsEye } from 'react-icons/bs';
import { BsFillHouseFill } from 'react-icons/bs';
import { FaUsers } from 'react-icons/fa';

function ReportCards(id) {
    return (
        <React.Fragment>
            <Row>
                <Col xl={6} md={6}>
                    <Link to={`/units/${id.id}`}>
                        <Card className="mini-stat text-dark bd-rds">
                            <CardBody>
                                <div className="mb-1">
                                    <div className="float-start mini-stat-img me-2 font-size-22">
                                        <BsFillHouseFill size={28} />
                                    </div>
                                    <h6 className="font-size-14 text-uppercase mt-0">
                                        All units
                                    </h6>
                                    <BsEye size={25} /> View all units
                                </div>
                            </CardBody>
                        </Card>
                    </Link>
                </Col>
                <Col xl={6} md={6}>
                    <Link to={`/tenants/${id.id}`}>
                        <Card className="mini-stat  text-dark bd-rds">
                            <CardBody>
                                <div className="mb-1">
                                    <div className="float-start mini-stat-img me-2 font-size-22">
                                        <FaUsers size={32} />
                                    </div>
                                    <h6 className="font-size-14 text-uppercase mt-0">
                                        All Tenants
                                    </h6>
                                    <BsEye size={25} /> View all tenants
                                </div>
                            </CardBody>
                        </Card>
                    </Link>
                </Col>
            </Row>
        </React.Fragment>
    )
}
ReportCards.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
}

export default withRouter(withTranslation()(ReportCards))
import PropTypes from "prop-types";
import React from "react";
import { Container } from "reactstrap";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Breadcrumb from "../../components/Common/Breadcrumb";
import MetaTagComp from "../../components/Common/MetaTags";

import MyProperties from "./components/MyProperties";

const AllProperties = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTagComp meta_tags="FASPRO24 | Properties" />
        <Breadcrumb default="Properties" title="All Properties" />

        <Container fluid>
          <div className="page-title-box">
            <MyProperties />
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

AllProperties.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};
export default withRouter(withTranslation()(AllProperties));

import React, { useState } from "react";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import MetaTagComp from "../../../components/Common/MetaTags";
import NewLeads from "./NewLeads";
import PendingLeads from "./PendingLeads";
import QualifiedLeads from "./QualifiedLead";
import UnqualifiedLeads from "./UnqualifiedLeads";
import TopMenu from "./Components/TopMenu";

function Index() {
  const [viewNewLeads, setViewNewLeads] = useState(true);
  const [viewPendingLeads, setViewPendingLeads] = useState(false);
  const [viewQualifiedLeads, setViewQualifiedLeads] = useState(false);
  const [viewUnqualifiedLeads, setViewUnqualifiedLeads] = useState(false);
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTagComp meta_tags="FASPRO 24 | Team" />
        <Breadcrumb
          default="Leads"
          title={
            viewNewLeads
              ? "New Leads"
              : viewPendingLeads
              ? "Pending Leads"
              : viewQualifiedLeads
              ? "Qualified Leads"
              : viewUnqualifiedLeads
              ? "Unqualified Leads"
              : null
          }
        />
        <TopMenu
          viewNewLeads={viewNewLeads}
          newLeads={setViewNewLeads}
          viewPendingLeads={viewPendingLeads}
          pending={setViewPendingLeads}
          viewQualifiedLeads={viewQualifiedLeads}
          qualify={setViewQualifiedLeads}
          viewUnqualifiedLeads={viewUnqualifiedLeads}
          unqualify={setViewUnqualifiedLeads}
        />
        {viewNewLeads ? (
          <NewLeads newLeadsTo={setViewNewLeads} moveTo={setViewPendingLeads} />
        ) : null}
        {viewPendingLeads ? <PendingLeads /> : null}
        {viewQualifiedLeads ? <QualifiedLeads /> : null}
        {viewUnqualifiedLeads ? <UnqualifiedLeads /> : null}
      </div>
    </React.Fragment>
  );
}

export default Index;

import React from "react";
import { useParams } from "react-router-dom";
import { Row, Col } from "reactstrap";
import { connect } from "react-redux";
import LoginImg from "../../../assets/images/Faspro24_illustrations_login_3.png";
import MetaTagComp from "../../../components/Common/MetaTags";
import Form from "./Components/Form";
import Slide from "../../../components/Slide";

const ResetPassword = () => {
  const { tokenId } = useParams();
  return (
    <React.Fragment>
      <MetaTagComp meta_tags="FASPRO24 | Reset Password" />

      <div className="account-pages">
        <Row className="login">
          <Col md={6} className="login-left">
            <Form tokenId={tokenId} />
          </Col>

          <Col md={6} className="login-container">
            <div className="login-img-container">
              <Slide />
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default ResetPassword;

import React, { useState, useEffect } from "react";
import { Row, Card, CardBody, Button, Spinner } from "reactstrap";
import { Modal } from "react-bootstrap";
import { MDBDataTable } from "mdbreact";
import useCustomFecthQuery from "../../../../hooks/useCustomFecthQuery";
import useCustomMutation from "../../../../hooks/useCustomMutation";
import LoadingTable from "../../../../components/LoadingTable";

const AllTenants = ({ user, token }) => {
  const key = ["allTenants", user];
  const mutationDelete = useCustomMutation(key);
  const mutationSuspend = useCustomMutation(key);
  const mutationUnsuspend = useCustomMutation(key);

  const [userID, setUserID] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [suspendModal, setSuspendModal] = useState(false);
  const [unSuspendModal, setUnSuspendModal] = useState(false);

  const {
    data: allTenants,
    error: errorGetAllTenants,
    isLoading: isLoadingGetAllTenants,
  } = useCustomFecthQuery(
    ["allTenants", user],
    `https://gh.prop.faspro24.com/api/v1/users/admin?roles=admin`,
    token,
    {
      enabled: !!user,
    }
  );
  let list = [];
  allTenants?.forEach((res) => {
    if (res?.roles?.some((role) => ["tenant", "co_tenant"].includes(role))) {
      list.push({
        ...res,
        role: res.roles[0].replace(/_/g, " "),
        fullName: `${res?.firstName} ${res?.lastName}`,
        suspendBtn:
          res.status === true ? (
            <button
              className="btn btn-dark"
              onClick={() => toggleSuspendModal(res.id)}
            >
              Suspend
            </button>
          ) : (
            <button
              className="btn btn-info"
              onClick={() => toggleUnsuspendModal(res.id)}
            >
              Unsuspend
            </button>
          ),
        deleteBtn: (
          <button
            className="btn btn-danger"
            onClick={() => toggleDeleteModal(res.id)}
          >
            Delete
          </button>
        ),
      });
    }
  });

  const toggleSuspendModal = (id) => {
    setUserID(id);
    setSuspendModal(true);
  };

  const toggleUnsuspendModal = (id) => {
    setUserID(id);
    setUnSuspendModal(true);
  };

  const toggleDeleteModal = (id) => {
    setUserID(id);
    setDeleteModal(true);
  };
  const deleteUserFunc = () => {
    const Method = "DELETE",
      endPoint = `users/${userID}`;

    const raw = "";
    const data = {
      endPoint: endPoint,
      bodyContent: raw,
      Method: Method,
      displayMessage: "Tenant Successfully Deleted",
      token: token,
      isJSON: null,
    };
    mutationDelete.mutate(data);
  };

  const suspendUserFunc = () => {
    const Method = "PATCH",
      endPoint = `users/${userID}/suspended`;
    const raw = "";
    const data = {
      endPoint: endPoint,
      bodyContent: raw,
      Method: Method,
      displayMessage: "User successfully suspended",
      token: token,
      isJSON: null,
    };
    mutationSuspend.mutate(data);
  };

  const unSuspendUserFunc = () => {
    const Method = "PATCH",
      endPoint = `users/${userID}/un-suspended`;
    const raw = "";
    const data = {
      endPoint: endPoint,
      bodyContent: raw,
      Method: Method,
      displayMessage: "User successfully unsuspended",
      token: token,
      isJSON: null,
    };
    mutationUnsuspend.mutate(data);
  };

  const column = [
    { label: "Fll name", field: "fullName", sort: "asc", width: 150 },
    { label: "Email", field: "email", sort: "asc", width: 150 },
    { label: "Role", field: "role", sort: "asc", width: 150 },
    { label: "Action", field: "suspendBtn", sort: "asc", width: 150 },
    { label: "Delete", field: "deleteBtn", sort: "asc", width: 150 },
  ];

  const tbdata = {
    columns: column,
    rows: list.reverse(),
  };

  useEffect(() => {
    if (mutationDelete.isSuccess) {
      setDeleteModal(false);
    }
    if (mutationSuspend.isSuccess) {
      setSuspendModal(false);
    }
    if (mutationUnsuspend.isSuccess) {
      setUnSuspendModal(false);
    }
  }, [
    mutationDelete.isSuccess,
    mutationUnsuspend.isSuccess,
    mutationSuspend.isSuccess,
  ]);

  return (
    <React.Fragment>
      <Row className="d-flex justify-content-around align-items-center">
        <CardBody data-aos="fade-bottom">
          <Card className="bd-rds">
            <CardBody>
              {isLoadingGetAllTenants ? (
                <LoadingTable />
              ) : (
                <MDBDataTable
                  entries={5}
                  entriesOptions={[5, 10, 50]}
                  responsive
                  bordered
                  striped
                  hover
                  data={tbdata}
                />
              )}
            </CardBody>
          </Card>
        </CardBody>
      </Row>

      <Modal
        show={suspendModal}
        onHide={() => setSuspendModal(false)}
        size="sm"
      >
        <Modal.Body className="text-danger">
          <h6> Are you sure you want to Suspend this user ? </h6>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="bg-danger text-white"
            onClick={() => setSuspendModal(false)}
          >
            Cancel
          </Button>
          <Button className="bg-success text-white" onClick={suspendUserFunc}>
            {!mutationSuspend.isLoading ? (
              <>{"Yes"}</>
            ) : (
              <>
                <span>
                  <Spinner as="span" animation="border" size="sm" /> Loading...
                </span>
              </>
            )}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={unSuspendModal}
        onHide={() => setUnSuspendModal(false)}
        size="sm"
      >
        <Modal.Body className="text-danger">
          <h6> Are you sure you want to un Suspend this property owner? </h6>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="bg-danger text-white"
            onClick={() => setUnSuspendModal(false)}
          >
            Cancel
          </Button>
          <Button className="bg-success text-white" onClick={unSuspendUserFunc}>
            {!mutationUnsuspend.isLoading ? (
              <>{"Yes"}</>
            ) : (
              <>
                <span>
                  <Spinner as="span" animation="border" size="sm" /> Loading...
                </span>
              </>
            )}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={deleteModal} onHide={() => setDeleteModal(false)} size="sm">
        <Modal.Body className="text-danger">
          <h6> Are you sure you want to delete this property owner ? </h6>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="bg-danger text-white"
            onClick={() => setDeleteModal(false)}
          >
            Cancel
          </Button>
          <Button className="bg-success text-white" onClick={deleteUserFunc}>
            {!mutationDelete.isLoading ? (
              <>{"Yes"}</>
            ) : (
              <>
                <span>
                  <Spinner as="span" animation="border" size="sm" /> Loading...
                </span>
              </>
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default AllTenants;

import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Container, Row, Card, CardBody, Button, Spinner } from "reactstrap";
import { Modal } from "react-bootstrap";
import { MDBDataTable } from "mdbreact";
import { withRouter, Link, useParams, useHistory } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import MetaTagComp from "../../../components/Common/MetaTags";
import { CSVLink } from "react-csv";
import { useStore1Selector } from "../../../index";
import { loginUser } from "../../../Redux/Slices/userSlice";
import usePost from "../../../hooks/usePost";
import useFecth from "../../../hooks/useFecth";
import ClientForm from "../ClientProfile";

import useCustomQuery from "../../../hooks/useCustomQuery";
import useCustomMutation from "../../../hooks/useCustomMutation";
import LoadingTable from "../../../components/LoadingTable";

// import TopMenu from "./Components/TopMenu";

function NewLeads({ newLeadsTo, moveTo }) {
  let history = useHistory();
  const userDet = useStore1Selector(loginUser);
  const token = userDet?.token;
  const user = userDet?.data?.id;
  const key = ["leads", user];
  const mutationPending = useCustomMutation(key);
  // const mutationSuspend = useCustomMutation(key);
  // const mutationUnsuspend = useCustomMutation(key);

  const {
    data: getAllLeads,
    error,
    isLoading,
  } = useCustomQuery(
    [key],
    `https://gh.prop.faspro24.com/api/v1/leads?status=new`,
    token
  );

  // console.log("getAllLeads...", getAllLeads);

  const { execute, pending, data: dataNewLead } = usePost();
  const {
    execute: executeDelete,
    pending: pendingDelete,
    data: dataDelete,
  } = usePost();

  const [takeLeadModal, setTakeLeadModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [viewID, setViewID] = useState();
  const [leadId, setLeadId] = useState(null);
  const listLeads = [];
  getAllLeads?.forEach((res) => {
    // console.log("Id...")
    listLeads.push({
      ...res,
      lead_status: (
        <label className="text-danger">
          {res?.status === "new" ? "New" : null}
        </label>
      ),
      Phone_Number: `+${res?.phoneNumber}`,

      viewBtn: (
        <button className="btn btn-success" onClick={() => viewFunc(res?._id)}>
          view
        </button>
      ),
      deleteBtn: (
        <button
          className="btn btn-danger"
          onClick={() => DeleteFuncModal(res?._id)}
        >
          <span className="me-2">Delete </span>
        </button>
      ),
      editBtn: (
        <button
          className="btn btn-primary"
          onClick={() => TakeLeadFunc(res?._id)}
        >
          {leadId === res?._id && pending ? (
            <>
              <Spinner as="span" animation="border" size="sm" />{" "}
              <span>Loading</span>
            </>
          ) : (
            <span className="me-2">Assign Lead</span>
          )}
        </button>
      ),
    });
  });

  function viewFunc(id) {
    setViewModal(true);
    setViewID(id);
  }
  const DeleteFuncModal = (id) => {
    setDeleteModal(true);
    setViewID(id);
  };

  function deleteLead(id) {
    setDeleteModal(false);
    const Method = "DELETE",
      endPoint = `leads/${id}`;
    const raw = null;
    executeDelete(endPoint, raw, Method, "Lead successfully deleted", token);

    // setTimeout(() => {
    //   reFetchGetAllLeads();
    // }, 2000);
  }

  function TakeLeadFunc(id) {
    setLeadId(id);
    const Method = "PATCH",
      endPoint = `leads/${id}`;
    const raw = JSON.stringify({
      status: "pending",
    });
    execute(endPoint, raw, Method, "Successfully moved to pending", token);
    // setTimeout(() => {
    //   reFetchGetAllLeads();
    // }, 2000);
  }

  const column = [
    { label: "First name", field: "firstName", sort: "asc", width: 150 },
    { label: "Last name", field: "lastName", sort: "asc", width: 150 },
    { label: "Email", field: "email", sort: "asc", width: 150 },
    { label: "Phone number", field: "Phone_Number", sort: "asc", width: 150 },
    { label: "Company size", field: "companySize", sort: "asc", width: 150 },
    { label: "Status", field: "lead_status", sort: "asc", width: 150 },
    { label: "Action", field: "editBtn", sort: "asc", width: 150 },
    { label: "View", field: "viewBtn", sort: "asc", width: 150 },
    { label: "Delete", field: "deleteBtn", sort: "asc", width: 150 },
  ];

  const newData = {
    columns: column,
    rows: listLeads.reverse(),
  };
  if (dataNewLead?.status === "success") {
    window.setTimeout(() => {
      newLeadsTo(false);
      moveTo(true);
      // history.push("/pending-leads");
    }, 2000);
  }

  return (
    <React.Fragment>
      {/* <div className="page-content">
        <MetaTagComp meta_tags="FASPRO 24 | Team" />
        <Breadcrumb default="Leads" title="Leads" /> */}
      {/* <TopMenu /> */}
      <CardBody>
        <Container fluid>
          {/* <Link to={`/property-detail/${id}`} className="btn login-btn mb-2">
              {" "}
              Back{" "}
            </Link> */}
          <Row className="d-flex justify-content-around align-items-center">
            <CardBody data-aos="fade-bottom">
              <Card className="bd-rds">
                <CardBody>
                  {isLoading ? (
                    <LoadingTable />
                  ) : (
                    <MDBDataTable
                      entries={5}
                      entriesOptions={[5, 10, 50]}
                      responsive
                      bordered
                      striped
                      hover
                      data={newData}
                    />
                  )}
                </CardBody>
              </Card>
            </CardBody>
          </Row>
        </Container>
      </CardBody>
      {/* </div> */}

      <Modal show={viewModal} onHide={() => setViewModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Client Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ClientForm viewID={viewID} />
        </Modal.Body>
      </Modal>
      <Modal show={deleteModal} onHide={() => setDeleteModal(false)} size="sm">
        <Modal.Body className="text-danger">
          <h6> Are you sure you want to delete this ? </h6>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="bg-danger text-white"
            onClick={() => setDeleteModal(false)}
          >
            Cancel
          </Button>
          <Button
            className="bg-success text-white"
            onClick={() => deleteLead(viewID)}
          >
            {pendingDelete ? (
              <>
                <Spinner as="span" animation="border" size="sm" />{" "}
                <span>Loading</span>
              </>
            ) : (
              "Yes"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

NewLeads.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};
export default withRouter(withTranslation()(NewLeads));

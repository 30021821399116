import React, { useState } from "react";
import { Spinner } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useStore1Selector } from "./../../../index";
import { loginUser } from "../../../Redux/Slices/userSlice";
import {
  warningMessage,
  successMessage,
} from "../../../components/Notifications";

function BlockForm(props) {
  const userDet = useStore1Selector(loginUser);
  const propId = userDet?.original?.userDetails?.OwnerID;
  const [loadBtn, setIloadBtn] = useState(false);

  const handleValidSubmit = (e, values) => {
    e.preventDefault();
    setIloadBtn(true);

    const formdata = new FormData();
    formdata.append("created_by", propId);
    formdata.append("PropertyID", props.id);
    formdata.append("BlockName", values.blockName);

    const requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch("https://gh.prop.faspro24.com/api/property/AddBlock", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          successMessage("Successful add a block");
          setIloadBtn(false);
          props.closeModal(false);
          window.setTimeout(() => {
            location.reload();
          }, 3000);
        }
        if (result.status === "fail") {
          warningMessage("try again something went wrong");
          setIloadBtn(false);
          props.closeModal(false);
        }
      })
      .catch((error) => {
        warningMessage(`try again something went wrong${error.message}`);
        setIloadBtn(false);
        props.closeModal(false);
      });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <AvForm
          className="form-horizontal"
          onValidSubmit={(e, v) => {
            handleValidSubmit(e, v);
          }}
        >
          <div className="mb-3">
            <AvField
              name="blockName"
              label="Enter block name / number"
              className="form-control"
              type="text"
              required
            />
          </div>

          <button
            className="btn login-btn w-md waves-effect waves-light"
            type="submit"
          >
            {!loadBtn ? <span className="me-2"> Submit </span> : null}
            {!loadBtn ? null : (
              <span>
                {" "}
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  className="me-2"
                />
                Loading...
              </span>
            )}
          </button>
        </AvForm>
      </div>
    </React.Fragment>
  );
}
export default BlockForm;

import React, { useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Col, Row, Button, Spinner, Modal } from "reactstrap";
import {
  AvForm,
  AvField,
  AvCheckboxGroup,
  AvCheckbox,
} from "availity-reactstrap-validation";
import { countryList } from "./../../../components/Countries";
import Img_600 from "./../../../assets/images/img-size/600x400.gif";
import { useStore1Selector } from "./../../../index";
import { loginUser } from "../../../Redux/Slices/userSlice";
import usePost from "../../../hooks/usePost";

function Form() {
  let history = useHistory();
  const userDet = useStore1Selector(loginUser);
  const { execute, pending, data } = usePost();
  const token = userDet?.token;
  const [profileServer, setProfileServer] = useState();

  const [profile, setProfile] = useState();
  const userId = userDet?.data?._id;

  const handleValidSubmit = (e, v) => {
    e.preventDefault();
    const Method = "POST",
      endPoint = `auth/admin_team/register/${userId}`,
      isJSON = true;
    var formdata = new FormData();
    formdata.append("firstName", v.firstName);
    formdata.append("lastName", v.firstName);
    formdata.append("terms", "true");
    formdata.append("email", v.email);
    formdata.append("roles", v.roles);
    if (profileServer) {
      formdata.append("profilePic", profileServer);
    }

    execute(
      endPoint,
      formdata,
      Method,
      "Team successfully added",
      token,
      isJSON
    );
  };

  const refFileUpload = useRef(null);
  const onThumbChangeClick = () => {
    if (refFileUpload) {
      refFileUpload.current.dispatchEvent(new MouseEvent("click"));
    }
  };

  const changeThumb = (event) => {
    if (event.target.files && event.target.files[0]) {
      setProfileServer(event.target.files[0]);

      const reader = new FileReader();
      reader.onload = (loadEvent) => {
        setProfile(loadEvent.target.result);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };
  if (data?.status === "success") {
    window.setTimeout(() => {
      history.push("/team");
    }, 2000);
  }
  return (
    <React.Fragment>
      <AvForm
        className="form-horizontal"
        onValidSubmit={(e, v) => {
          handleValidSubmit(e, v);
        }}
      >
        <h5 className="text-center mb-3 mt-3">Upload image</h5>
        <Row>
          <div className="d-flex justify-content-center align-items-center">
            <img
              src={profile === undefined ? Img_600 : profile}
              alt="user"
              width={150}
              height={150}
              className="rounded"
            />
            <Button
              size="sm"
              variant="separator-light"
              className="btn-icon btn-icon-only position-absolute rounded s-0 b-0 mt-5"
              onClick={onThumbChangeClick}
            >
              <i className="ion ion-md-image"></i>
            </Button>
            <input
              type="file"
              ref={refFileUpload}
              className="file-upload d-none"
              accept="image/*"
              onChange={changeThumb}
            />
          </div>
        </Row>
        <Row>
          <Col md={12}>
            <div className="mb-3">
              <AvField
                name="firstName"
                label="First Name"
                className="form-control"
                type="text"
                required
              />
            </div>
          </Col>
          <Col md={12}>
            <div className="mb-3">
              <AvField
                name="lastName"
                label="Last Name"
                className="form-control"
                type="text"
                required
              />
            </div>
          </Col>
          <Col md={12}>
            <div className="mb-3">
              <AvField
                name="email"
                label="Email"
                className="form-control"
                type="text"
                required
              />
            </div>
          </Col>
          <Col md={12}>
            <div className="mb-3">
              <AvField
                name="roles"
                label="Specify team member role"
                className="form-control"
                type="select"
                required
              >
                <option value="">Select...</option>
                <option value="admin_assistant">Assistant</option>
                <option value="admin_finance">Finance</option>
                <option value="admin_marketing">Marketing</option>
                <option value="admin_sales">Sales</option>
                <option value="admin_support">Support</option>
              </AvField>
            </div>
          </Col>
        </Row>

        <div className="mb-3 row ">
          <div className="col-12 d-flex justify-content-center">
            <button
              className="btn login-btn w-md waves-effect waves-light"
              type="submit"
            >
              {!pending ? <span className="me-2"> Submit </span> : null}

              {!pending ? null : (
                <span>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    className="me-2"
                  />
                  Loading...
                </span>
              )}
            </button>
          </div>
        </div>
      </AvForm>
    </React.Fragment>
  );
}

export default Form;

import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Container, Row, Card, CardBody, Button, Spinner } from "reactstrap";
import { Modal } from "react-bootstrap";
import { MDBDataTable } from "mdbreact";
import { withRouter, Link, useParams } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import MetaTagComp from "../../../components/Common/MetaTags";
// import { CSVLink } from "react-csv";
import { useStore1Selector } from "../../../index";
import { loginUser } from "../../../Redux/Slices/userSlice";

// to delete
import usePost from "../../../hooks/usePost";
// import useFecth from "../../../hooks/useFecth";

import useCustomFecthQuery from "../../../hooks/useCustomFecthQuery";
import useCustomMutation from "../../../hooks/useCustomMutation";
import LoadingTable from "../../../components/LoadingTable";
import ViewInvoice from "../Components/ViewInvoice";

// import ClientForm from "../ClientProfile";
// import { disableCursor } from "@fullcalendar/core";

// import TopMenu from "./Components/TopMenu";

function PaidInvoice() {
  const userDet = useStore1Selector(loginUser);
  const token = userDet?.token;
  const user = userDet?.data?.id;

  const key = ["invoice", user];
  const mutationEdit = useCustomMutation(key);
  const mutationDelete = useCustomMutation(key);
  const mutationPaid = useCustomMutation(key);
  const mutationCancel = useCustomMutation(key);

  const {
    data: getAllLeadInvoices,
    error,
    isLoading,
    refetch,
  } = useCustomFecthQuery(
    [key],
    `https://gh.prop.faspro24.com/api/v1/LeadInvoices?status=pending`,
    token,
    { enabled: !!user }
  );

  const { execute, data: dataPost } = usePost();
  // const { data: getAllLeadInvoices, reFetch: reFetchGetAllLeadInvoices } =
  //   useFecth(
  //     `https://gh.prop.faspro24.com/api/v1/LeadInvoices?status=pending`,
  //     token
  //   );

  // const [EditModal, setEditModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [delteID, setDeleteID] = useState();
  const [paidID, setPaidID] = useState();
  const [cancelID, setCancelID] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [paidModal, setPaidModal] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const [leadID, setLeadID] = useState();

  const [viewInvoice, setViewInvoice] = useState();
  const listLeadInvoice = [];
  getAllLeadInvoices?.forEach((res) => {
    listLeadInvoice.push({
      ...res,
      firstName: res?.leadId?.firstName,
      lastName: res?.leadId?.lastName,
      email: res?.leadId?.email,
      generatedBy: `${
        res?.leadId?.salesId?.firstName
      } ${res?.leadId?.salesId?.lastName?.substring(0, 1)}.`,
      invoice_status: (
        <label
          className={
            res?.status === "paid"
              ? "text-success"
              : res?.status === "pending"
              ? "text-warning"
              : res?.status === "unpaid"
              ? "text-danger"
              : res?.status === "canceled"
              ? "text-danger"
              : null
          }
        >
          {res?.status === "paid"
            ? "Paid"
            : res?.status === "pending"
            ? "Pending"
            : res?.status === "unpaid"
            ? "Unpaid"
            : res?.status === "canceled"
            ? "Canceled"
            : null}
        </label>
      ),

      viewBtn: (
        <button className="btn btn-dark" onClick={() => viewFunc(res)}>
          view
        </button>
      ),
      editBtn: (
        <Link
        //           to={`/edit-quotation/${res?.leadId?.id}/${res?._id}
        // `}
        >
          <button
            className="btn btn-primary"
            // onClick={() => EditFunc(res?.leadId?.id)}
          >
            Edit
          </button>
        </Link>
      ),
      DeleteBtn: (
        <button className="btn btn-danger" onClick={() => deleteFunc(res?._id)}>
          Delete
        </button>
      ),
      PaidBtn: (
        <button
          className="btn btn-success"
          onClick={() => paidFunc(res?._id, res?.leadId?._id)}
        >
          Paid
        </button>
      ),
      CancelBtn: (
        <button
          className="btn btn-warning"
          onClick={() => cancelFunc(res?._id, res?.leadId?._id)}
        >
          Cancel
        </button>
      ),
    });
  });

  function viewFunc(invoice) {
    setViewModal(true);
    setViewInvoice(invoice);
  }

  function EditFunc(id) {
    // const Method = "PATCH",
    //   endPoint = `leads/${id}`;
    // const raw = JSON.stringify({
    //   status: "pending",
    // });
    // execute(endPoint, raw, Method, "Successfully moved to pending", token);
    // setTimeout(() => {
    //   reFetchGetAllQuotation();
    // }, 2000);
  }
  function deleteFunc(id) {
    setDeleteID(id);
    setDeleteModal(true);
  }
  const deleteQuotation = () => {
    const Method = "DELETE",
      endPoint = `leadInvoices/${delteID}`,
      isJSON = true;
    var formdata = new FormData();
    const data = {
      endPoint: endPoint,
      bodyContent: formdata,
      Method: Method,
      displayMessage: "Quotation successfully deleted",
      token: token,
      isJSON: isJSON,
    };
    mutationDelete.mutate(data);
  };

  function paidFunc(invoiceId, idLead) {
    setPaidID(invoiceId);
    setLeadID(idLead);
    setPaidModal(true);
  }
  const paidInvoice = () => {
    // endPoint = `leadInvoices/${paidID}/${leadID}`;
    // start with LeadId, then settingID, the last ID is the lead Lead Invoice ID
    const Method = "PATCH",
      endPoint = `leadInvoices/${leadID}/669612408f9b6bd458081551/${paidID}`;
    const raw = JSON.stringify({
      invoiceStatus: "paid",
    });
    const data = {
      endPoint: endPoint,
      bodyContent: raw,
      Method: Method,
      displayMessage: "Invoice successfully paid",
      token: token,
      isJSON: null,
    };
    mutationPaid.mutate(data);
  };
  function cancelFunc(invoiceId, idLead) {
    setCancelID(invoiceId);
    setLeadID(idLead);
    setCancelModal(true);
  }
  const cancelQuotation = () => {
    const Method = "PATCH",
      endPoint = `leadInvoices/${cancelID}/${leadID}`;
    const raw = JSON.stringify({
      reasonForCancelInvoice:
        "Tell the reason why you are canceling the invoice",
      invoiceStatus: "cancel",
    });
    const data = {
      endPoint: endPoint,
      bodyContent: raw,
      Method: Method,
      displayMessage: "Invoice successfully canceled",
      token: token,
      isJSON: null,
    };
    mutationCancel.mutate(data);
  };

  const column = [
    { label: "First name", field: "firstName", sort: "asc", width: 150 },
    { label: "Last name", field: "lastName", sort: "asc", width: 150 },
    { label: "Email", field: "email", sort: "asc", width: 150 },
    { label: "Generated By", field: "generatedBy", sort: "asc", width: 150 },
    {
      label: "Invoice Number",
      field: "invoiceNumber",
      sort: "asc",
      width: 150,
    },
    { label: "Status", field: "invoice_status", sort: "asc", width: 150 },
    { label: "View", field: "viewBtn", sort: "asc", width: 150 },
    // { label: "Edit", field: "editBtn", sort: "asc", width: 150 },
    { label: "Delete", field: "DeleteBtn", sort: "asc", width: 150 },
    { label: "Paid", field: "PaidBtn", sort: "asc", width: 150 },
    { label: "cancel", field: "CancelBtn", sort: "asc", width: 150 },
  ];

  const newData = {
    columns: column,
    rows: listLeadInvoice.reverse(),
  };

  useEffect(() => {
    if (mutationCancel.isSuccess) {
      refetch();
      setCancelModal(false);
    }
    if (mutationPaid.isSuccess) {
      refetch();
      setPaidModal(false);
    }
    if (mutationDelete.isSuccess) {
      refetch();
      setDeleteModal(false);
    }
    // if (mutationEdit.isSuccess) {
    //   setPaidModal(false);
    // }
  }, [
    mutationCancel?.isSuccess,
    mutationPaid?.isSuccess,
    mutationDelete?.isSuccess,
    mutationEdit?.isSuccess,
  ]);

  console.log(".....getAllLeadInvoices....", getAllLeadInvoices);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTagComp meta_tags="FASPRO 24 | Leads" />
        <Breadcrumb default="Billing" title="Paid Invoice" />

        <CardBody>
          <Container fluid>
            {/* <Link to={`/property-detail/${id}`} className="btn login-btn mb-2">
              {" "}
              Back{" "}
            </Link> */}
            <Row className="d-flex justify-content-around align-items-center">
              <CardBody data-aos="fade-bottom">
                <Card className="bd-rds">
                  <CardBody>
                    {isLoading ? (
                      <LoadingTable />
                    ) : (
                      <MDBDataTable
                        entries={5}
                        entriesOptions={[5, 10, 50]}
                        responsive
                        bordered
                        striped
                        hover
                        data={newData}
                      />
                    )}
                  </CardBody>
                </Card>
              </CardBody>
            </Row>
          </Container>
        </CardBody>
      </div>
      <Modal show={viewModal} onHide={() => setViewModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>View Invoice</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ViewInvoice viewInvoice={viewInvoice} />
        </Modal.Body>
      </Modal>

      <Modal show={deleteModal} onHide={() => setDeleteModal(false)} size="sm">
        <Modal.Body className="text-danger">
          <h6> Are you sure you want to delete this invoice ? </h6>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="bg-danger text-white"
            onClick={() => setDeleteModal(false)}
          >
            Cancel
          </Button>
          <Button className="bg-success text-white" onClick={deleteQuotation}>
            {!mutationDelete.isLoading ? (
              <>{"Yes"}</>
            ) : (
              <>
                <span>
                  <Spinner as="span" animation="border" size="sm" /> Loading...
                </span>
              </>
            )}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={paidModal} onHide={() => setPaidModal(false)} size="sm">
        <Modal.Body className="text-success">
          <h6> You are about to set this invoice to paid </h6>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="bg-danger text-white"
            onClick={() => setPaidModal(false)}
          >
            Cancel
          </Button>
          <Button className="bg-success text-white" onClick={paidInvoice}>
            {!mutationPaid.isLoading ? (
              <>{"Yes"}</>
            ) : (
              <>
                <span>
                  <Spinner as="span" animation="border" size="sm" /> Loading...
                </span>
              </>
            )}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={cancelModal} onHide={() => setCancelModal(false)} size="sm">
        <Modal.Body className="text-danger">
          <h6> Are you sure you want to cancel this invoice ? </h6>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="bg-danger text-white"
            onClick={() => setCancelModal(false)}
          >
            Cancel
          </Button>
          <Button className="bg-success text-white" onClick={cancelQuotation}>
            {!mutationCancel.isLoading ? (
              <>{"Yes"}</>
            ) : (
              <>
                <span>
                  <Spinner as="span" animation="border" size="sm" /> Loading...
                </span>
              </>
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

PaidInvoice.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};
export default withRouter(withTranslation()(PaidInvoice));

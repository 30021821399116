async function fetcher(url, token) {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  const result = await fetch(url, requestOptions)
    .then((response) => response.json())
    .then((res) => {
      console.log(res);
      if (res?.status === "success") {
        return res?.data;
      } else {
        const data = [];
        return data;
      }
    });

  return result;
}
export default fetcher;

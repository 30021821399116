import PropTypes from 'prop-types'
import React from "react"
import { Row, Col } from "reactstrap"
import { connect } from "react-redux"
import LoginImg from "../../../assets/images/Faspro24_illustrations_login_3.png";
import MetaTagComp from "../../../components/Common/MetaTags";
import Form from './Components/Form';
import Slide from "../../../components/Slide";

const ForgetPasswordPage = () => {

  return (
    <React.Fragment>
      <MetaTagComp meta_tags="FASPRO24 | Forget Password" />

      <div className="account-pages">
        <Row className="login">
          <Col md={6} className="login-left">
            <Form />
          </Col>

          <Col md={6} className="login-container">
            <div className="login-img-container">
              <Slide />
            </div>
          </Col>

        </Row>
      </div>
    </React.Fragment>
  )
}

ForgetPasswordPage.propTypes = {
  forgetError: PropTypes.any,
  forgetSuccessMsg: PropTypes.any,
  history: PropTypes.object,
  userForgetPassword: PropTypes.func
}

const mapStatetoProps = state => {
  const { forgetError, forgetSuccessMsg } = state.ForgetPassword
  return { forgetError, forgetSuccessMsg }
}

export default ForgetPasswordPage
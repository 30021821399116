import React from "react";
import { Row, Col, Spinner } from "reactstrap";
import { AvField } from "availity-reactstrap-validation";

function BankAccount(props) {
  return (
    <Row className="m-5 w-50">
      <Col md={12} lg={12}>
        <div className="mb-3">
          <AvField name="accountName" label="Bank Name" type="text" required />
        </div>
        <div className="mb-3">
          <AvField name="branchName" label="Branch Name" type="text" required />
        </div>
        <div className="mb-3">
          <AvField
            name="branchCode"
            label="SWIFT code / IBAN"
            className="form-control"
            type="text"
            required
          />
        </div>

        <div className="mb-3">
          <AvField
            name="accountNumber"
            label="Account Number"
            type="number"
            required
          />
        </div>
        <div className="mb-3">
          <AvField
            name="accountType"
            label="Account Type"
            type="select"
            required
          >
            <option> Select... </option>
            <option> Savings </option>
            <option> Current/Cheque </option>
          </AvField>
        </div>

        <div className="d-flex">
          <AvField
            type="checkbox"
            name="privacy"
            label="I accept the terms of the FASPRO "
          />
          <a href="https://faspro24.com/privacy-policy" className="px-1">
            {" "}
            privacy policy{" "}
          </a>
        </div>

        <div className="mb-3 row">
          <div className="col-12 text-end">
            <button
              className="btn login-btn w-100 waves-effect waves-light"
              type="submit"
            >
              {!props.loadBtn ? <span className="me-2">Submit</span> : null}
              {!props.loadBtn ? null : (
                <span>
                  {" "}
                  <Spinner as="span" animation="border" size="sm" /> Loading...
                </span>
              )}
            </button>
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default BankAccount;

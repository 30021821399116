import React, { useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Col, Row, Button, Spinner, Modal } from "reactstrap";
import {
  AvForm,
  AvField,
  AvCheckboxGroup,
  AvCheckbox,
} from "availity-reactstrap-validation";
import { countryList } from "../../components/Countries";
import Img_600 from "./../../assets/images/img-size/600x400.gif";
import { useStore1Selector } from "../../index";
import { loginUser } from "../../Redux/Slices/userSlice";
import usePost from "../../hooks/usePost";
import useFecth from "../../hooks/useFecth";

function ResetPasswordForm() {
  let history = useHistory();
  const userDet = useStore1Selector(loginUser);
  const { execute, pending, data } = usePost();
  const token = userDet?.token;

  const userId = userDet?.data?._id;
  const { data: getMydetails, reFetch: reFetchGetMydetails } = useFecth(
    `https://gh.prop.faspro24.com/api/v1/users/me`,
    token
  );

  const handleValidSubmit = (e, v) => {
    e.preventDefault();
    const Method = "PATCH",
      endPoint = `auth/updateMyPassword`,
      isJSON = true;
    const raw = JSON.stringify({
      passwordCurrent: v.passwordCurrent,
      password: v.password,
      passwordConfirm: v.passwordConfirm,
    });
    execute(endPoint, raw, Method, "Password successfully updated", token);
  };

  return (
    <React.Fragment>
      <AvForm
        className="form-horizontal"
        onValidSubmit={(e, v) => {
          handleValidSubmit(e, v);
        }}
      >
        <Row>
          <Col md={4}>
            <div className="mb-3">
              <AvField
                name="passwordCurrent"
                label="Current Password"
                className="form-control"
                type="password"
                required
              />
            </div>
          </Col>
          <Col md={4}>
            <div className="mb-3">
              <AvField
                name="password"
                label="New Password"
                className="form-control"
                type="password"
                required
              />
            </div>
          </Col>
          <Col md={4}>
            <div className="mb-3">
              <AvField
                name="passwordConfirm"
                label="Confirm Password"
                className="form-control"
                type="password"
                required
              />
            </div>
          </Col>
        </Row>

        <div className="mb-3 row ">
          <div className="col-12 d-flex justify-content-center">
            <button
              className="btn btn-primary w-md waves-effect waves-light"
              type="submit"
            >
              {!pending ? <span className="me-2"> Confirm</span> : null}

              {!pending ? null : (
                <span>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    className="me-2"
                  />
                  Loading...
                </span>
              )}
            </button>
          </div>
        </div>
      </AvForm>
    </React.Fragment>
  );
}

export default ResetPasswordForm;

import { BsFillArrowRightCircleFill } from 'react-icons/bs';
import { AiOutlineArrowRight } from 'react-icons/ai';

const signUp_arr = [
    {
        id: 1,
        icon: BsFillArrowRightCircleFill,
        name: "Property Owner",
        link: "signup/property-owner",
        btn_icon: AiOutlineArrowRight
    },
    {
        id: 2,
        icon: BsFillArrowRightCircleFill,
        name: "Property Agency",
        link: "signup/property-agency",
        btn_icon: AiOutlineArrowRight
    },
]

export const signUp = () => {
    return signUp_arr
}
import React, { useState, useRef } from "react";
import { useHistory, Link } from "react-router-dom";
import { Col, Row, Button, Spinner, Modal } from "reactstrap";
import {
  AvForm,
  AvField,
  AvCheckboxGroup,
  AvCheckbox,
} from "availity-reactstrap-validation";
import { countryList } from "./../../../components/Countries";
import Img_600 from "./../../../assets/images/img-size/600x400.gif";
import { useStore1Selector } from "./../../../index";
import { loginUser } from "../../../Redux/Slices/userSlice";
import {
  warningMessage,
  successMessage,
} from "../../../components/Notifications";

function PropertyForm() {
  let history = useHistory();
  const userDet = useStore1Selector(loginUser);
  const propId = userDet?.original?.userDetails?.OwnerID;
  const role = userDet?.original?.RoleID;
  const ownerType = userDet?.original?.userDetails?.OwnerType;

  const [loadBtn, setIloadBtn] = useState(false);
  const [profile, setProfile] = useState();
  const [value, setValue] = useState();
  const [OwnerType, setOwnerType] = useState();
  const [propCat, setPropCat] = useState();
  const [profileServer, setProfileServer] = useState();

  const handleValidSubmit = (e, values) => {
    e.preventDefault();
    setIloadBtn(true);

    const formdata = new FormData();
    formdata.append("OwnerID", propId);
    // formdata.append("OwnerID", role === parseInt(0) ? propId : null);

    formdata.append("created_by", propId);
    // formdata.append("created_by", role === parseInt(0) ? propId : null);
    formdata.append("PropertyType", value);
    formdata.append("PropertyCategory", propCat);
    formdata.append("Country", values.Country);
    formdata.append("StateProvince", values.state);
    formdata.append("PropertyCode", values.PropertyCode);
    formdata.append("Name", values.buildingName);
    formdata.append("Suburb", values.Suburb);
    formdata.append("PostalCode", values.postalCode);
    formdata.append("Address", values.address);
    formdata.append("City", values.city);
    formdata.append("Picture", profileServer);
    formdata.append(
      "are_blocks_available",
      !values.checkboxCustomInputExample[0]
        ? 0
        : values.checkboxCustomInputExample[0]
    );

    const requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(
      "https://gh.prop.faspro24.com/api/property/NewProperty",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          successMessage("You have successfully added a new property");
          setIloadBtn(false);

          window.setTimeout(() => {
            history.push("/my-properties");
          }, 3000);
        }
        if (result.status === "fail") {
          warningMessage("try again something went wrong");
          setIloadBtn(false);
        }
      })
      .catch((error) => {
        warningMessage(`try again something went wrong${error.message}`);
        setIloadBtn(false);
      });
  };

  const selectHandle = (e) => {
    setValue(e.target.value);
  };
  const selectHandle2 = (e) => {
    setOwnerType(e.target.value);
  };
  function handleChange(event) {
    setPropCat(event.target.value);
  }

  const refFileUpload = useRef(null);
  const onThumbChangeClick = () => {
    if (refFileUpload) {
      refFileUpload.current.dispatchEvent(new MouseEvent("click"));
    }
  };

  const changeThumb = (event) => {
    if (event.target.files && event.target.files[0]) {
      setProfileServer(event.target.files[0]);

      const reader = new FileReader();
      reader.onload = (loadEvent) => {
        setProfile(loadEvent.target.result);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <AvForm
          className="form-horizontal"
          onValidSubmit={(e, v) => {
            handleValidSubmit(e, v);
          }}
        >
          <h5 className="text-center mb-5">Upload property front image</h5>
          <Row>
            <div className="d-flex justify-content-center align-items-center">
              <img
                src={profile === undefined ? Img_600 : profile}
                alt="user"
                width={150}
                height={150}
                className="rounded"
              />
              <Button
                size="sm"
                variant="separator-light"
                className="btn-icon btn-icon-only position-absolute rounded s-0 b-0 mt-5"
                onClick={onThumbChangeClick}
              >
                <i className="ion ion-md-image"></i>
              </Button>
              <input
                type="file"
                ref={refFileUpload}
                className="file-upload d-none"
                accept="image/*"
                onChange={changeThumb}
              />
            </div>
          </Row>

          <Row>
            <Col md={2}>
              <div className="mb-3">
                <AvField
                  name="categories"
                  value={value}
                  onChange={selectHandle}
                  label="Property type"
                  className="form-control"
                  type="select"
                  required
                >
                  <option> Select... </option>
                  <option value="1"> Residential </option>
                  <option value="2"> Commercial </option>
                </AvField>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              {value === "2" ? (
                <div className="mb-3">
                  <AvField
                    name="categories"
                    onChange={handleChange}
                    label="Property Categories"
                    className="form-control"
                    type="select"
                    required
                  >
                    <option> Select... </option>
                    <option value="1"> Offices </option>
                    <option value="2"> Warehouse </option>
                    <option value="3"> Hotels & Restaurant </option>
                    <option value="4"> Shopping Malls </option>
                    <option value="5"> Industrial Buildings </option>
                    <option value="6"> Retail House </option>
                    <option value="7"> Others </option>
                  </AvField>
                </div>
              ) : null}

              {value === "1" ? (
                <div className="mb-3">
                  <AvField
                    name="categories"
                    onChange={handleChange}
                    label="Property Categories"
                    className="form-control"
                    type="select"
                    required
                  >
                    <option> Select... </option>
                    <option value="1"> Apartments </option>
                    <option value="2"> Duplex </option>
                    <option value="3"> Single family homes </option>
                    <option value="4"> Vacation homes </option>
                    <option value="5"> condominiums </option>
                    <option value="6"> Townhouses </option>
                    <option value="7"> Others </option>
                  </AvField>
                </div>
              ) : null}

              <Row>
                <Col md={6}>
                  <div className="mb-3">
                    <AvField
                      name="PropertyCode"
                      label="Property Code(optional)"
                      className="form-control"
                      type="text"
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-3">
                    <AvField
                      name="buildingName"
                      label="Building Name"
                      className="form-control"
                      type="text"
                      required
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <div className="mb-3">
                    <AvField
                      name="address"
                      label="Address"
                      className="form-control"
                      type="text"
                      required
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-3">
                    <AvField
                      name="city"
                      label="City"
                      className="form-control"
                      type="text"
                      required
                    />
                  </div>
                </Col>
              </Row>
            </Col>
            <Col md={6}>
              <Row>
                <Col md={6}>
                  <div className="mb-3">
                    <AvField
                      name="Country"
                      label="Country"
                      className="form-control"
                      type="select"
                      required
                    >
                      {countryList.map((country) => (
                        <option> {country} </option>
                      ))}
                    </AvField>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-3">
                    <AvField
                      name="state"
                      label="State/Province(optional)"
                      className="form-control"
                      type="text"
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <div className="mb-3">
                    <AvField
                      name="Suburb"
                      label="Suburb"
                      className="form-control"
                      type="text"
                    />
                  </div>
                </Col>

                <Col md={6}>
                  <div className="mb-3">
                    <AvField
                      name="postalCode"
                      label="Postal Code(optional)"
                      className="form-control"
                      type="text"
                    />
                  </div>
                </Col>
              </Row>

              {ownerType === "individual" ? null : (
                <Row>
                  <h4 className="my-5">Property owner details : </h4>

                  <Col md={12}>
                    <div className="mb-3">
                      <AvField
                        name="categories"
                        onChange={selectHandle2}
                        label="Property owner type"
                        className="form-control"
                        type="select"
                      >
                        <option> Select... </option>
                        <option value="1"> Individual </option>
                        <option value="2"> Company </option>
                      </AvField>
                    </div>
                  </Col>

                  <Col md={6}>
                    <div className="mb-3">
                      <AvField
                        name="firstName"
                        label="First Name"
                        className="form-control"
                        type="text"
                      />
                    </div>
                  </Col>

                  <Col md={6}>
                    <div className="mb-3">
                      <AvField
                        name="lastName"
                        label="last Name"
                        className="form-control"
                        type="text"
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <AvField
                        name="email"
                        label="Email"
                        className="form-control"
                        type="email"
                      />
                    </div>
                  </Col>

                  <Col md={6}>
                    <div className="mb-3">
                      <AvField
                        name="phoneNumber"
                        label="Phone Number"
                        className="form-control"
                        type="text"
                      />
                    </div>
                  </Col>
                  {OwnerType === "2" ? (
                    <Col md={12}>
                      <div className="mb-3">
                        <AvField
                          name="companyName"
                          label="Company Name"
                          className="form-control"
                          type="text"
                        />
                      </div>
                    </Col>
                  ) : null}
                </Row>
              )}

              <AvCheckboxGroup
                className="bg-white mt-4"
                name="checkboxCustomInputExample"
              >
                <AvCheckbox
                  customInput
                  label="My property has blocks"
                  value="1"
                  className="bg-white"
                />
              </AvCheckboxGroup>
            </Col>
          </Row>
          <div className="mb-3 row">
            <div className="col-12">
              <button
                className="btn login-btn w-md waves-effect waves-light"
                type="submit"
              >
                {!loadBtn ? <span className="me-2"> Submit </span> : null}

                {!loadBtn ? null : (
                  <span>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      className="me-2"
                    />
                    Loading...
                  </span>
                )}
              </button>
            </div>
          </div>
        </AvForm>
      </div>
    </React.Fragment>
  );
}

export default PropertyForm;

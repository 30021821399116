import PropTypes from "prop-types";
import React from "react";
import { Col, Row, Card, CardBody } from "reactstrap";
import { withRouter, Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { BsEye } from "react-icons/bs";
import { BsFillHouseFill } from "react-icons/bs";
import { FaUsers } from "react-icons/fa";

function TopMenu({
  viewNewLeads,
  newLeads,
  viewPendingLeads,
  pending,
  viewQualifiedLeads,
  qualify,
  viewUnqualifiedLeads,
  unqualify,
}) {
  const newHandle = (e) => {
    if (e) {
      newLeads(true);
      pending(false);
      qualify(false);
      unqualify(false);
    }
  };
  const pendingHandle = (e) => {
    newLeads(false);
    pending(true);
    qualify(false);
    unqualify(false);
  };
  const qualifyHandle = (e) => {
    newLeads(false);
    pending(false);
    qualify(true);
    unqualify(false);
  };
  const unqualifyHandle = (e) => {
    newLeads(false);
    pending(false);
    qualify(false);
    unqualify(true);
  };
  return (
    <React.Fragment>
      <Row>
        <Col xl={3} md={3}>
          {/* <Link to={`/Leads`}> */}
          <Card
            className={
              viewNewLeads
                ? `mini-stat  bd-rds border border-danger`
                : `mini-stat  bd-rds`
            }
            onClick={newHandle}
          >
            <CardBody>
              <div className="mb-1">
                <div className="float-start mini-stat-img me-2 font-size-22">
                  <FaUsers size={28} className="text-danger" />
                </div>
                <h6 className="font-size-14 text-uppercase text-dark mt-0">
                  New Leads
                </h6>
                {/* <BsEye size={25} className="text-dark" /> */}
                <span className="text-dark">View new leads</span>
              </div>
            </CardBody>
          </Card>
          {/* </Link> */}
        </Col>
        <Col xl={3} md={3}>
          {/* <Link to={`/pending-leads`}> */}
          <Card
            className={
              viewPendingLeads
                ? `mini-stat bd-rds border border-warning`
                : `mini-stat bd-rds`
            }
            onClick={pendingHandle}
          >
            <CardBody>
              <div className="mb-1 text-light">
                <div className="float-start mini-stat-img me-2 font-size-22">
                  <FaUsers size={32} className="text-warning" />
                </div>
                <h6 className="font-size-14 text-uppercase text-dark mt-0">
                  Pending Leads
                </h6>
                {/* <BsEye size={25} className="text-dark" /> */}
                <span className="text-dark">View pending leads</span>
              </div>
            </CardBody>
          </Card>
          {/* </Link> */}
        </Col>
        <Col xl={3} md={3}>
          {/* <Link to={`/qualified-leads`}> */}
          <Card
            className={
              viewQualifiedLeads
                ? `mini-stat  text-light bd-rds border border-success`
                : "mini-stat  text-light bd-rds"
            }
            onClick={qualifyHandle}
          >
            <CardBody>
              <div className="mb-1">
                <div className="float-start mini-stat-img me-2 font-size-22">
                  <FaUsers size={32} className="text-success" />
                </div>
                <h6 className="font-size-14 text-uppercase text-dark mt-0">
                  Qualified Leads
                </h6>
                {/* <BsEye size={25} className="text-dark" />{" "} */}
                <span className="text-dark">View qualified leads</span>
              </div>
            </CardBody>
          </Card>
          {/* </Link> */}
        </Col>

        <Col xl={3} md={3}>
          {/* <Link to={`/unqualified-leads`}> */}
          <Card
            className={
              viewUnqualifiedLeads
                ? `mini-stat  text-dark bd-rds border border-dark`
                : "mini-stat  text-dark bd-rds "
            }
            onClick={unqualifyHandle}
          >
            <CardBody>
              <div className="mb-1 text-light">
                <div className="float-start mini-stat-img me-2 font-size-22">
                  <FaUsers size={32} className="text-dark" />
                </div>
                <h6 className="font-size-14 text-uppercase text-dark mt-0">
                  Unqualified Leads
                </h6>
                {/* <BsEye size={25} className="text-dark" /> */}
                <span className="text-dark">View unqualified leads</span>
              </div>
            </CardBody>
          </Card>
          {/* </Link> */}
        </Col>
      </Row>
    </React.Fragment>
  );
}
TopMenu.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(TopMenu));

import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Container, Row, Card, CardBody, Button } from "reactstrap";
import { Modal } from "react-bootstrap";
import { MDBDataTable } from "mdbreact";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import MetaTagComp from "../../../components/Common/MetaTags";
import { CSVLink } from "react-csv";
// import ViewModal from "./components/ViewModal";
import Loading from "components/Loading";
import { useStore1Selector } from "./../../../index";
import { loginUser } from "../../../Redux/Slices/userSlice";
// import TenantDetails from "./components/TenantDetails";
// import { warningMessage, successMessage } from "../../components/Notifications";
// import Empty from "components/Empty";
// import Cards from "./components/Cards";

import useFetch from "../../../hooks/useFecth";
import usePost from "../../../hooks/usePost";
import { refresh } from "aos";

function AllTeams() {
  const userDet = useStore1Selector(loginUser);
  const { execute, pending } = usePost();
  const token = userDet?.token;

  console.log("userDet....", userDet);

  // https://gh.prop.faspro24.com/api/v1/users/team?fieldName=adminId

  const { data: getTeamMember, reFetch: reFetchGetTeamMember } = useFetch(
    `https://gh.prop.faspro24.com/api/v1/users/team?fieldName=65630ab6e6e0d0c67b011376`,
    userDet?.token
  );

  const [userID, setUserID] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [suspendModal, setSuspendModal] = useState(false);
  const [unSuspendModal, setUnSuspendModal] = useState(false);
  let list = [];

  getTeamMember?.forEach((res) => {
    list.push({
      ...res,
      role:
        res?.roles[0] === "admin_sales"
          ? "Sales"
          : res?.roles[0] === "admin_marketing"
          ? "Marketing"
          : res?.roles[0] === "admin_finance"
          ? "Finance"
          : res?.roles[0] === "admin_support"
          ? "Support"
          : res?.roles[0] === "admin_assistant"
          ? "Assistant"
          : null,
      suspendBtn:
        res.status === true ? (
          <button
            className="btn btn-dark"
            onClick={() => suspendFunc(res?._id)}
          >
            Suspend
          </button>
        ) : (
          <button
            className="btn btn-info"
            onClick={() => unsuspendFunc(res?._id)}
          >
            Unsuspend
          </button>
        ),
      deleteBtn: (
        <button className="btn btn-danger" onClick={() => deleteFunc(res?._id)}>
          Delete
        </button>
      ),
    });
  });

  function suspendFunc(id) {
    setUserID(id);
    setSuspendModal(true);
  }

  function unsuspendFunc(id) {
    setUserID(id);
    setUnSuspendModal(true);
  }

  function deleteFunc(id) {
    setUserID(id);
    setDeleteModal(true);
  }
  const deleteUserFunc = () => {
    const Method = "DELETE",
      endPoint = `users/${userID}`;
    const raw = "";
    execute(endPoint, raw, Method, "User Successfully Deleted", token);
    setDeleteModal(false);
  };

  const suspendUserFunc = () => {
    const Method = "PATCH",
      endPoint = `users/${userID}/suspended`;
    const raw = "";
    execute(endPoint, raw, Method, "User Successfully Suspended", token);
    setSuspendModal(false);
  };

  const unSuspendUserFunc = () => {
    const Method = "PATCH",
      endPoint = `users/${userID}/un-suspended`;
    const raw = "";
    execute(endPoint, raw, Method, "User Successfully Unsuspended", token);
    setUnSuspendModal(false);
  };

  if (pending) {
    setTimeout(() => {
      reFetchGetTeamMember();
    }, 2000);
  }

  const column = [
    { label: "First Name", field: "firstName", sort: "asc", width: 150 },
    { label: "Last Name", field: "lastName", sort: "asc", width: 150 },
    { label: "Email", field: "email", sort: "asc", width: 150 },
    { label: "Role", field: "role", sort: "asc", width: 150 },
    { label: "Action", field: "suspendBtn", sort: "asc", width: 150 },
    { label: "Delete", field: "deleteBtn", sort: "asc", width: 150 },
  ];

  const tbdata = {
    columns: column,
    rows: list,
  };

  console.log("getTeamMember...", getTeamMember);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTagComp meta_tags="FASPRO24 | Team" />
        <Breadcrumb default="Teams" title="View Team" />
        <CardBody>
          <Container fluid>
            {/* <Cards /> */}
            <Row className="d-flex justify-content-around align-items-center">
              <CardBody data-aos="fade-bottom">
                <Card className="bd-rds">
                  <CardBody>
                    <MDBDataTable
                      entries={5}
                      entriesOptions={[5, 10, 50]}
                      responsive
                      bordered
                      striped
                      hover
                      data={tbdata}
                    />
                  </CardBody>
                </Card>
              </CardBody>
            </Row>
          </Container>
        </CardBody>
      </div>

      <Modal
        show={suspendModal}
        onHide={() => setSuspendModal(false)}
        size="sm"
      >
        <Modal.Body className="text-danger">
          <h6> Are you sure you want to Suspend this user ? </h6>
        </Modal.Body>
        <Modal.Footer>
          <Button className="bg-success text-white" onClick={suspendUserFunc}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={unSuspendModal}
        onHide={() => setUnSuspendModal(false)}
        size="sm"
      >
        <Modal.Body className="text-danger">
          <h6> Are you sure you want to un Suspend this user ? </h6>
        </Modal.Body>
        <Modal.Footer>
          <Button className="bg-success text-white" onClick={unSuspendUserFunc}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={deleteModal} onHide={() => setDeleteModal(false)} size="sm">
        <Modal.Body className="text-danger">
          <h6> Are you sure you want to delete this user ? </h6>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="bg-success text-white w-50"
            onClick={deleteUserFunc}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

AllTeams.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};
export default withRouter(withTranslation()(AllTeams));

import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Container, Row, Card, CardBody, Button, Spinner } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Modal } from "react-bootstrap";
import { MDBDataTable } from "mdbreact";
import { withRouter, Link, useParams } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import MetaTagComp from "../../../components/Common/MetaTags";
import { CSVLink } from "react-csv";
import { useStore1Selector } from "../../../index";
import { loginUser } from "../../../Redux/Slices/userSlice";
import usePost from "../../../hooks/usePost";
import useFecth from "../../../hooks/useFecth";
import LeadProfile from "../../Lead/ClientProfile";
import CustomerProfile from "./components/CustomerProfile";

function RegisterCustomer() {
  const userDet = useStore1Selector(loginUser);
  const token = userDet?.token;
  const { execute, pending } = usePost();
  const { data: getAllInvoices, reFetch: reFetchGetAllInvoices } = useFecth(
    `https://gh.prop.faspro24.com/api/v1/leadInvoices`,
    token
  );

  const [editModal, setEditModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [sendModal, setSendModal] = useState(false);
  const [sendID, setSendID] = useState();
  const [viewID, setViewID] = useState();
  const [viewCustomer, setViewCustomer] = useState();
  const [delteID, setDeleteID] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedUserType, setSelectedUserType] = useState("Select...");
  const listLeads = [];
  getAllInvoices?.forEach((res) => {
    listLeads.push({
      ...res,

      firstName: res?.leadId?.firstName,
      lastName: res?.leadId?.lastName,
      email: res?.leadId?.email,
      Phone_Number: `+${res?.leadId?.phoneNumber}`,
      btnSendLink: (
        <button
          className="btn btn-primary"
          onClick={() => sendRegFunc(res?.leadId?._id)}
        >
          Send Registration Link
        </button>
      ),
      viewBtn: (
        <button
          className="btn btn-success"
          onClick={() => viewFunc(res?._id, res?.leadId)}
        >
          view
        </button>
      ),
      editBtn: (
        <button
          className="btn btn-primary"
          onClick={() => editFunc(res?.leadId?._id)}
        >
          Edit
        </button>
      ),
      deleteBtn: (
        <button className="btn btn-danger" onClick={() => deleteFunc(res?._id)}>
          Delete
        </button>
      ),
    });
  });

  function sendRegFunc(id) {
    setSendModal(true);
    setSendID(id);
  }
  function viewFunc(id, customer) {
    setViewModal(true);
    setViewID(id);
    setViewCustomer(customer);
  }

  function editFunc(id) {
    setEditModal(!editModal);
    setViewID(id);
  }

  function deleteFunc(id) {
    setDeleteID(id);
    setDeleteModal(true);
  }

  const deleteLeads = () => {
    delteID;
    const Method = "DELETE",
      endPoint = `leadInvoices/${delteID}`,
      isJSON = true;
    var formdata = new FormData();
    execute(
      endPoint,
      formdata,
      Method,
      "Invoice successfully deleted",
      token,
      isJSON
    );
    setTimeout(() => {
      reFetchGetAllInvoices();
    }, 2000);
    setDeleteModal(false);
  };

  const column = [
    { label: "First name", field: "firstName", sort: "asc", width: 150 },
    { label: "Last name", field: "lastName", sort: "asc", width: 150 },

    { label: "Email", field: "email", sort: "asc", width: 150 },
    { label: "Phone number", field: "Phone_Number", sort: "asc", width: 150 },
    // { label: "Company size", field: "companySize", sort: "asc", width: 150 },
    // { label: "Status", field: "qualified_status", sort: "asc", width: 150 },
    { label: "Action", field: "btnSendLink", sort: "asc", width: 150 },
    { label: "View", field: "viewBtn", sort: "asc", width: 150 },
    // { label: "Edit", field: "editBtn", sort: "asc", width: 150 },
    { label: "Delete", field: "deleteBtn", sort: "asc", width: 150 },
  ];

  const newData = {
    columns: column,
    rows: listLeads.reverse(),
  };
  const handleSelectStatus = (event) => {
    setSelectedUserType(event.target.value);
  };
  const handleValidSubmit = () => {
    const Method = "POST",
      endPoint = `leads/register/${sendID}?status=${selectedUserType}`,
      isJSON = true;
    var formdata = new FormData();
    execute(
      endPoint,
      formdata,
      Method,
      "Registration link successfully sent",
      token,
      isJSON
    );
    setSendModal(false);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTagComp meta_tags="FASPRO 24 | Team" />
        <Breadcrumb default="Qualified Customer" title="Customer" />
        <CardBody>
          <Container fluid>
            {/* <Link to={`/property-detail/${id}`} className="btn login-btn mb-2">
              {" "}
              Back{" "}
            </Link> */}
            <Row className="d-flex justify-content-around align-items-center">
              <CardBody data-aos="fade-bottom">
                <Card className="bd-rds">
                  <CardBody>
                    <MDBDataTable
                      entries={5}
                      entriesOptions={[5, 10, 50]}
                      responsive
                      bordered
                      striped
                      hover
                      data={newData}
                    />
                  </CardBody>
                </Card>
              </CardBody>
            </Row>
          </Container>
        </CardBody>
      </div>

      <Modal show={viewModal} onHide={() => setViewModal(false)} size="md">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <CustomerProfile viewCustomer={viewCustomer} viewID={viewID} />
        </Modal.Body>
      </Modal>
      <Modal show={sendModal} onHide={() => setSendModal(false)} size="sm">
        <Modal.Header closeButton>
          <Modal.Title>Send Registration link</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div>
            <AvForm
              className="form-horizontal"
              onValidSubmit={(e, v) => {
                handleValidSubmit(e, v);
              }}
            >
              <Row>
                <div className="mb-4">
                  <label htmlFor="selectOption">Register as:</label>
                  <select
                    id="selectOption"
                    className="btn btn-outline-success mx-2"
                    value={selectedUserType}
                    onChange={handleSelectStatus}
                  >
                    <option value="Select...">Select...</option>
                    <option value="Individual">Individual</option>
                    <option value="Company">Company</option>
                  </select>
                </div>
              </Row>
              <div className="mb-3 row ">
                <div className="col-12 d-flex justify-content-center">
                  <button
                    className="btn login-btn w-md waves-effect waves-light"
                    type="submit"
                  >
                    {!pending ? (
                      <span className="me-2"> Send Registration Link </span>
                    ) : null}

                    {!pending ? null : (
                      <span>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          className="me-2"
                        />
                        Loading...
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </AvForm>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={editModal} onHide={() => setEditModal(false)} size="lg">
        <Modal.Body>
          {/* <ViewModal id={viewID} tenantList={myTenant} /> */}
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-end p-2">
          <Button
            className="bg-danger text-white"
            onClick={() => setEditModal(false)}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={deleteModal} onHide={() => setDeleteModal(false)} size="sm">
        <Modal.Body className="text-danger">
          <h6> Are you sure you want to delete this ? </h6>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="bg-danger text-white"
            onClick={() => setDeleteModal(false)}
          >
            Cancel
          </Button>
          <Button className="bg-success text-white" onClick={deleteLeads}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

RegisterCustomer.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};
export default withRouter(withTranslation()(RegisterCustomer));

import React, { useState, useEffect } from "react";
import { Container, Row, Card, CardBody, Button, Spinner } from "reactstrap";
import { useStore1Selector } from "../../../../index";
import { loginUser } from "../../../../Redux/Slices/userSlice";
import useFetch from "../../../../hooks/useFecth";
import usePost from "../../../../hooks/usePost";

function LicenseForm({ user_ID, closeModal }) {
  const userDet = useStore1Selector(loginUser);
  const { execute, pending } = usePost();
  const token = userDet?.token;

  const { data: getUserdetails, reFetch: reFetchGetUserdetails } = useFetch(
    `https://gh.prop.faspro24.com/api/v1/users/${user_ID}`,
    token
  );

  const handleBtnLicense = () => {
    const Method = "POST",
      endPoint = `packages/${user_ID}?iid=${getUserdetails?.invoice_Id?._id}`;

    const raw = JSON.stringify({
      name: "Standard",
      description: "Description",
      duration: "yearly",
    });
    execute(endPoint, raw, Method, "License successfully assigned", token);
    closeModal(false);
  };

  return (
    <div className="col-12">
      <div>
        <div className="p-2">
          <h3 className="font-size-16">
            {/* <strong>Order summary</strong> */}
          </h3>
        </div>
        <div>
          <address>
            <strong>Bill To:</strong>
            <br />
            {getUserdetails?.firstName} {getUserdetails?.lastName}
            <br />
            {getUserdetails?.email}
            <br />
            {`+${getUserdetails?.phoneNumber}`}
            <br />
          </address>
        </div>

        <div className="">
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <td>
                    <strong>Items(s)</strong>
                  </td>
                  <td className="text-center">
                    <strong>Price per Unit</strong>
                  </td>
                  <td className="text-end">
                    <strong>
                      Amount (X{getUserdetails?.invoice_Id?.numberOfUnits}{" "}
                      Units)
                    </strong>
                  </td>
                </tr>
              </thead>
              <tbody>
                {getUserdetails?.invoice_Id?.moduleAndPrice?.map((item, i) => {
                  return (
                    <tr key={i}>
                      <td>{item?.module}</td>
                      <td className="text-center">${item?.price}</td>
                      <td className="text-end">
                        $
                        {item?.price *
                          parseInt(getUserdetails?.invoice_Id?.numberOfUnits)}
                      </td>
                    </tr>
                  );
                })}

                <tr>
                  <td className="no-line"></td>
                  <td className="no-line text-center">
                    <strong>Subtotal</strong>
                  </td>
                  <td className="no-line text-end">
                    ${getUserdetails?.invoice_Id?.total?.toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td className="no-line"></td>
                  <td className="no-line text-center">
                    <strong>Discount</strong>
                  </td>
                  <td className="no-line text-end">
                    ${getUserdetails?.invoice_Id?.discountedPrice}
                  </td>
                </tr>
                {/* <tr>
                  <td className="no-line"></td>
                  <td className="no-line text-center">
                    <strong>Vat</strong>
                  </td>
                  <td className="no-line text-end">${getUserdetails?.}</td>
                </tr> */}
                <tr>
                  <td className="no-line"></td>
                  <td className="no-line text-center">
                    <strong>
                      Total ({getUserdetails?.invoice_Id?.vatPercentage}%) Inc.{" "}
                    </strong>
                  </td>
                  <td className="no-line text-end">
                    <h4 className="m-0">
                      ${getUserdetails?.invoice_Id?.subTotal?.toFixed(2)}
                    </h4>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="d-flex justify-content-center">
              <button
                className="btn login-btn w-md waves-effect waves-light"
                type="submit"
                onClick={handleBtnLicense}
              >
                {!pending ? <span className="me-2"> Send </span> : null}

                {!pending ? null : (
                  <span>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      className="me-2"
                    />
                    Loading...
                  </span>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LicenseForm;

{
  /* <div className="">
  <div className="table-responsive">
    <div className="d-flex justify-content-center">
      <button
        className="btn login-btn w-md waves-effect waves-light"
        type="submit"
      >
        {!pending ? <span className="me-2"> Update Quotation </span> : null}

        {!pending ? null : (
          <span>
            <Spinner as="span" animation="border" size="sm" className="me-2" />
            Loading...
          </span>
        )}
      </button>
    </div>
  </div>
</div>; */
}

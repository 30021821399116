import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Card, Modal, Button, Spinner } from "react-bootstrap";
import { MDBDataTable } from "mdbreact";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

import { CSVLink } from "react-csv";
import { useStore1Selector } from "../../../index";
import { loginUser } from "../../../Redux/Slices/userSlice";

// to delete this ....
import usePost from "../../../hooks/usePost";

import useCustomQuery from "../../../hooks/useCustomQuery";
import useCustomMutation from "../../../hooks/useCustomMutation";
import LoadingTable from "../../../components/LoadingTable";
import PropertyForm from "./PropertyForm";

function PropertyTable() {
  const userDet = useStore1Selector(loginUser);
  const token = userDet?.token;
  const userID = userDet?.data?.id;

  const key = ["myProperties", userID];
  const mutation = useCustomMutation(key);

  const {
    data: getAllProperties,
    error,
    isLoading,
  } = useCustomQuery(
    ["myProperties", userID],
    `https://gh.prop.faspro24.com/api/v1/properties`,
    token
  );

  // console.log("userID....", userID);
  // console.log("getAllProperties....", getAllProperties);
  // console.log("token....", token);

  const [viewID, setViewID] = useState();
  const [delteID, setDeleteID] = useState();
  const [propertyDetails, setPropertyDetails] = useState();
  const [largeRightModalExample, setLargeRightModalExample] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const listProperties = [];

  const viewFunc = (id, property) => {
    setPropertyDetails(property);
    setViewModal(true);
  };
  getAllProperties?.forEach((res) => {
    // console.log("res...", res);
    listProperties.push({
      ...res,
      propAddress: `${res?.address}`,
      ownerName: `${res?.userId?.firstName} ${res?.userId?.lastName}`,
      ownerEmail: `${res?.userId?.email}`,
      phoneNumber: `+${res?.userId?.phoneNumber}`,
      viewBtn: (
        <button
          className="btn btn-success"
          onClick={() => viewFunc(res?._id, res)}
        >
          view
        </button>
      ),
      deleteBtn: (
        <button className="btn btn-danger" onClick={() => deleteFunc(res?._id)}>
          Delete
        </button>
      ),
    });
  });
  function deleteFunc(id) {
    setDeleteID(id);
    setDeleteModal(true);
  }

  const deleteProperty = () => {
    const Method = "DELETE",
      endPoint = `properties/${delteID}`,
      isJSON = true;
    var formdata = new FormData();
    const raw = formdata;
    const data = {
      endPoint: endPoint,
      bodyContent: raw,
      Method: Method,
      displayMessage: "Property Successfully Deleted",
      token: token,
      isJSON: isJSON,
    };
    mutation.mutate(data);
  };

  const column = [
    { label: "Property Name", field: "propertyName", sort: "asc", width: 150 },
    { label: "Property Type", field: "propertyType", sort: "asc", width: 150 },
    { label: "Address", field: "propAddress", sort: "asc", width: 150 },
    { label: "City", field: "city", sort: "asc", width: 150 },
    { label: "Country", field: "country", sort: "asc", width: 150 },
    { label: "Owner", field: "ownerName", sort: "asc", width: 150 },
    { label: "Email", field: "ownerEmail", sort: "asc", width: 150 },
    { label: "Phone Number", field: "phoneNumber", sort: "asc", width: 150 },
    { label: "View", field: "viewBtn", sort: "asc", width: 150 },
    { label: "Delete", field: "deleteBtn", sort: "asc", width: 150 },
  ];

  const newData = {
    columns: column,
    rows: listProperties?.reverse(),
  };

  useEffect(() => {
    if (mutation.isSuccess) {
      setDeleteModal(false);
    }
  }, [mutation.isSuccess]);

  return (
    <React.Fragment>
      <Card className="bd-rds">
        <Card.Body>
          <div className="d-flex justify-content-between">
            <button className="btn login-btn w-md waves-effect waves-light mb-4 d-flex justify-content-end report-warning">
              <CSVLink
                data={listProperties.reverse()}
                separator={";"}
                className="text-white"
              >
                {" "}
                Download in Excel{" "}
              </CSVLink>{" "}
            </button>
          </div>
          {isLoading ? (
            <LoadingTable />
          ) : (
            <MDBDataTable
              entries={5}
              entriesOptions={[5, 10, 50]}
              responsive
              bordered
              striped
              hover
              data={newData}
              fullPagination
            />
          )}
        </Card.Body>
      </Card>

      <Modal show={viewModal} onHide={() => setViewModal(false)} size="lg">
        <Modal.Body className="text-dark">
          <PropertyForm propertyDetails={propertyDetails} />
        </Modal.Body>
      </Modal>

      <Modal show={deleteModal} onHide={() => setDeleteModal(false)} size="sm">
        <Modal.Body className="text-danger">
          <h5> Are you sure you want to delete this property ? </h5>{" "}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setDeleteModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={deleteProperty}>
            {!mutation.isLoading ? (
              <>{"Yes"}</>
            ) : (
              <>
                <span>
                  <Spinner as="span" animation="border" size="sm" /> Loading...
                </span>
              </>
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

PropertyTable.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(PropertyTable));

import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Container, Row, Card, CardBody, Button } from "reactstrap";
import { Modal } from "react-bootstrap";
import { MDBDataTable } from "mdbreact";
import { withRouter, Link, useParams } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Breadcrumb from "../../components/Common/Breadcrumb";
import MetaTagComp from "../../components/Common/MetaTags";
import { CSVLink } from "react-csv";
import { useStore1Selector } from "./../../index";
import { loginUser } from "../../Redux/Slices/userSlice";
// import ViewModal from "./components/ViewModal";
// import TenantDetails from "./components/TenantDetails";
import Loading from "components/Loading";
import { warningMessage, successMessage } from "../../components/Notifications";

function TenantTable() {
  const { id } = useParams();
  const userDet = useStore1Selector(loginUser);
  const ownerId = userDet?.original?.userDetails?.OwnerID;
  const [myTenant, setMyTenant] = useState();
  const [modal_center, setmodal_center] = useState(false);
  const [viewmodal, setViewmodal] = useState(false);
  const [viewID, setViewID] = useState();
  const [delteID, setDeleteID] = useState();
  const [deleteModal, setDeleteModal] = useState(false);

  function viewFunc(id) {
    setViewmodal(true);
    setViewID(id);
  }

  function editFunc(id) {
    setmodal_center(!modal_center);
    setViewID(id);
  }

  function deleteFunc(id) {
    setDeleteID(id);
    setDeleteModal(true);
  }

  const getTenantList = () => {
    let list = [];
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `https://gh.prop.faspro24.com/api/tenant/GetTenantByOwnerID/${ownerId}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        result.forEach((res) => {
          list.push({
            ...res,
            viewBtn: (
              <button
                className="btn btn-success"
                onClick={() => viewFunc(res.TenantID)}
              >
                view
              </button>
            ),
            editBtn: (
              <button
                className="btn btn-primary"
                onClick={() => editFunc(res.TenantID)}
              >
                {" "}
                Edit{" "}
              </button>
            ),
            deleteBtn: (
              <button
                className="btn btn-danger"
                onClick={() => deleteFunc(res.TenantID)}
              >
                {" "}
                Delete{" "}
              </button>
            ),
          });
        });
        setMyTenant(list.sort().reverse());
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    getTenantList();
  }, []);

  const deleteProperty = () => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `https://gh.prop.faspro24.com/api/tenant/DeleteTenant/${delteID}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          successMessage("You have successful deleted this tenant");
          getTenantList();
          setDeleteModal(false);
        }
      })
      .catch((error) => {
        warningMessage("Something went wrong deleting this tenant try again");
      });
  };

  if (!myTenant) {
    return <Loading />;
  }

  const TenantDetailRes = myTenant?.filter((tenant) => {
    return tenant.TenantID === parseInt(viewID);
  });

  const column = [
    { label: "ID", field: "TenantID", sort: "asc", width: 150 },
    { label: "First Name", field: "Name", sort: "asc", width: 150 },
    { label: "Last Name", field: "Surname", sort: "asc", width: 150 },
    { label: "Email", field: "Email", sort: "asc", width: 150 },
    { label: "Phone Number", field: "Phone_Number", sort: "asc", width: 150 },
    { label: "Role", field: "role", sort: "asc", width: 150 },
    { label: "View", field: "viewBtn", sort: "asc", width: 150 },
    { label: "Edit", field: "editBtn", sort: "asc", width: 150 },
    { label: "Delete", field: "deleteBtn", sort: "asc", width: 150 },
  ];

  const data = {
    columns: column,
    rows: myTenant,
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTagComp meta_tags="FASPRO 24 | Tenant" />
        <Breadcrumb default="Dashboard" title="Tenant" />
        <CardBody>
          <Container fluid>
            <Link to={`/property-detail/${id}`} className="btn login-btn mb-2">
              {" "}
              Back{" "}
            </Link>
            <Row className="d-flex justify-content-around align-items-center">
              <CardBody data-aos="fade-bottom">
                <Card className="bd-rds">
                  <CardBody>
                    <MDBDataTable
                      entries={5}
                      entriesOptions={[5, 10, 50]}
                      responsive
                      bordered
                      striped
                      hover
                      data={data}
                    />
                  </CardBody>
                </Card>
              </CardBody>
            </Row>
          </Container>
        </CardBody>
      </div>

      <Modal show={viewmodal} onHide={() => setViewmodal(false)} size="lg">
        <Modal.Body>
          {/* <TenantDetails TenantDetail={TenantDetailRes} /> */}
        </Modal.Body>
      </Modal>

      <Modal
        show={modal_center}
        onHide={() => setmodal_center(false)}
        size="lg"
      >
        <Modal.Body>
          {/* <ViewModal id={viewID} tenantList={myTenant} /> */}
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-end p-2">
          <Button
            className="bg-danger text-white"
            onClick={() => setmodal_center(false)}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={deleteModal} onHide={() => setDeleteModal(false)} size="sm">
        <Modal.Body className="text-danger">
          <h6> Are you sure you want to delete this ? </h6>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="bg-danger text-white"
            onClick={() => setDeleteModal(false)}
          >
            Cancel
          </Button>
          <Button className="bg-success text-white" onClick={deleteProperty}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

TenantTable.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};
export default withRouter(withTranslation()(TenantTable));

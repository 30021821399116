import PropTypes from "prop-types";
import React, { useState } from "react";
import { Container, CardBody } from "reactstrap";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Breadcrumb from "../../components/Common/Breadcrumb";
import MetaTagComp from "../../components/Common/MetaTags";

// import ViewModal from "./components/ViewModal";
import { useStore1Selector } from "../../index";
import { loginUser } from "../../Redux/Slices/userSlice";

import UsersNav from "./components/UsersNav";
import AllUsersTab from "./components/AllUsers/AllUsers";
import AllPropertyOwners from "./components/AllPropertyOwners/AllPropertyOwners";
import AllTenants from "./components/AllTenants/AllTenants";

const Users = () => {
  const userDet = useStore1Selector(loginUser);
  const token = userDet?.token;
  const user = userDet?.data?.id;

  const [isAllUsersActive, setIsAllUsersActive] = useState(true);
  const [isAllPropertyOwnerActive, setIsAllPropertyOwnerActive] =
    useState(false);
  const [isAllTenantActive, setIsAllTenantActive] = useState(false);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTagComp meta_tags="FASPRO24 | users" />
        <Breadcrumb default="Dashboard" title="Users" />
        <CardBody>
          <Container fluid>
            <UsersNav
              isAllUsersActive={isAllUsersActive}
              setIsAllUsersActive={setIsAllUsersActive}
              isAllPropertyOwnerActive={isAllPropertyOwnerActive}
              setIsAllPropertyOwnerActive={setIsAllPropertyOwnerActive}
              isAllTenantActive={isAllTenantActive}
              setIsAllTenantActive={setIsAllTenantActive}
            />
            {isAllUsersActive ? (
              <AllUsersTab user={user} token={token} />
            ) : null}
            {isAllPropertyOwnerActive ? (
              <AllPropertyOwners user={user} token={token} />
            ) : null}
            {isAllTenantActive ? (
              <AllTenants user={user} token={token} />
            ) : null}
          </Container>
        </CardBody>
      </div>
    </React.Fragment>
  );
};

Users.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};
export default withRouter(withTranslation()(Users));

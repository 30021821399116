import PropTypes from 'prop-types'
import React from "react"
import "./Style.scss"
import { Row, Col } from "reactstrap"
import LoginImg from "../../../assets/images/Faspro24_illustrations_login_1.png";
import MetaTagComp from "../../../components/Common/MetaTags";
import BackOfficeForm from "./Components/BackOfficeForm";
import Slide from "../../../components/Slide";

const BackOffice = () => {

    return (
        <React.Fragment>

            <MetaTagComp meta_tags="FASPRO 24 | back office" />

            <div className="account-pages">
                <Row className="login">
                    <Col md={6} className="login-left">
                        <BackOfficeForm />
                    </Col>

                    <Col md={6} className="login-container">
                        <div className="login-img-container">
                            <Slide />
                        </div>
                    </Col>

                </Row>
            </div>
        </React.Fragment >
    )
}

export default BackOffice 
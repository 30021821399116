import React from "react";
import { Container, Row, Col, Card, CardBody, Button } from "reactstrap";
import User_Img from "../../../../assets/images/profile_Img.png";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useStore1Selector } from "./../../../..//index";
import { loginUser } from "../../../../Redux/Slices/userSlice";
import useFecth from "../../../../hooks/useFecth";

function CustomerProfile({ viewCustomer, viewID }) {
  const userDet = useStore1Selector(loginUser);
  const token = userDet?.token;
  const { data: getClientProfile, reFetch: reFetchGetClientProfile } = useFecth(
    `https://gh.prop.faspro24.com/api/v1/leads/${viewID}`,
    token
  );

  // console.log("viewCustomer....", viewCustomer);

  return (
    <div>
      <AvForm className="form-horizontal">
        <Row>
          <Row>
            <Col md={12} className="d-flex justify-content-center mb-4">
              <div
                style={{
                  width: "70px",
                }}
              >
                <img src={User_Img} className="w-100" />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className="mb-3">
                <AvField
                  name="First Name"
                  value={`${viewCustomer?.firstName} ${viewCustomer?.lastName}`}
                  label="Full Name"
                  className="form-control"
                  type="text"
                  disabled
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className="mb-3">
                <AvField
                  name="Email"
                  value={viewCustomer?.email}
                  label="Email"
                  className="form-control"
                  type="text"
                  disabled
                />
              </div>
            </Col>
            <Col md={12}>
              <div className="mb-3">
                <AvField
                  name="Cellphone"
                  value={`+${viewCustomer?.phoneNumber}`}
                  label="Cellphone"
                  className="form-control"
                  type="text"
                  disabled
                />
              </div>
            </Col>
          </Row>
          {/* <Row>
            <Col md={6}>
              <div className="mb-3">
                <AvField
                  name="companyName"
                  value={
                    getClientProfile?.companyName
                      ? getClientProfile?.companyName
                      : "N/A"
                  }
                  label="Company name"
                  className="form-control"
                  type="text"
                  disabled
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="mb-3">
                <AvField
                  name="companySize"
                  value={getClientProfile?.companySize}
                  label="Company size"
                  className="form-control"
                  type="text"
                  disabled
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="mb-3">
                <b>Interested modules :</b>
                <div></div>
                {getClientProfile?.interestedModules?.map((item, i) => {
                  return (
                    <span className="badge bg-success p-2 m-1" key={i}>
                      {item}
                    </span>
                  );
                })}
              </div>
            </Col>
          </Row> */}
        </Row>
      </AvForm>
    </div>
  );
}

export default CustomerProfile;

import React from "react";
import { Row, Spinner } from "reactstrap";

const ViewInvoice = ({ viewInvoice }) => {
  console.log("viewInvoice....", viewInvoice);
  return (
    <div>
      <Row>
        <div className="col-12">
          <Row>
            <div className="col-6">
              <address>
                vatPercentage
                <strong>Bill To:</strong>
                <br />
                {viewInvoice?.leadId?.firstName} {viewInvoice?.leadId?.lastName}
                <br />
                {viewInvoice?.leadId?.email}
                <br />
                {`+${viewInvoice?.leadId?.phoneNumber}`}
                <br />
                {/* {todayDate} */}
              </address>
            </div>
            <div className="col-6 text-end">
              <strong></strong>
              <address></address>
            </div>
          </Row>
          <Row>
            <div className="col-6">
              {/* <strong>Date: {currentDate}</strong> */}
              <address></address>
            </div>
          </Row>
        </div>
      </Row>

      <Row>
        <div className="col-12">
          <div>
            <div className="p-2">
              <h3 className="font-size-16"></h3>
            </div>
            <div className="">
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <td>
                        <strong>Items(s)</strong>
                      </td>
                      <td className="text-center">
                        <strong>Price per Unit</strong>
                      </td>
                      <td className="text-end">
                        <strong>Amount (X{viewInvoice?.units} Units)</strong>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {viewInvoice?.moduleAndPrice?.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td>{item?.module}</td>
                          <td className="text-center">${item?.price}</td>
                          <td className="text-end">
                            $
                            {item?.price * parseInt(viewInvoice?.numberOfUnits)}
                          </td>
                        </tr>
                      );
                    })}

                    <tr>
                      <td className="no-line"></td>
                      <td className="no-line text-center">
                        <strong>Subtotal</strong>
                      </td>
                      <td className="no-line text-end">
                        ${viewInvoice?.total}
                      </td>
                    </tr>
                    <tr>
                      <td className="no-line"></td>
                      <td className="no-line text-center">
                        <strong>Discount</strong>
                      </td>
                      <td className="no-line text-end">
                        ${viewInvoice?.discountAmount}
                      </td>
                    </tr>
                    <tr>
                      <td className="no-line"></td>
                      <td className="no-line text-center">
                        <strong>
                          Total (${viewInvoice?.vatPercentage}%) Vat Inc.{" "}
                        </strong>
                      </td>
                      <td className="no-line text-end">
                        <h4 className="m-0">
                          ${viewInvoice?.subTotal?.toFixed(2)}
                        </h4>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </Row>
      {/* <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <td>
                <strong>Description</strong>
              </td>
              <td className="text-center">
                <strong></strong>
              </td>
              <td className="text-center">
                <strong>Number</strong>
              </td>
              <td className="text-end">
                <strong>Price</strong>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Number of Units</td>
              <td className="text-center"></td>
              <td className="text-center">{viewInvoice?.numberOfUnits}</td>
              <td className="text-end">${viewInvoice?.numberOfUnitsAmount}</td>
            </tr>
            <tr>
              <td>Manage users</td>
              <td className="text-"></td>
              <td className="text-center">{viewInvoice?.numberOfUsers}</td>
              <td className="text-end">${viewInvoice?.numberOfUsersAmount}</td>
            </tr>
            {viewInvoice?.facility === "facility" ? (
              <tr>
                <td>Facility Management</td>

                <td className="text-center"></td>
                <td className="text-center"></td>
                <td className="text-end">${viewInvoice?.facilityPrice}</td>
              </tr>
            ) : null}
            {viewInvoice?.maintenance === "maintenance" ? (
              <tr>
                <td>Maintenance Management</td>

                <td className="text-center"></td>
                <td className="text-center"></td>
                <td className="text-end">${viewInvoice?.maintenancePrice}</td>
              </tr>
            ) : null}
            {viewInvoice?.security === "security" ? (
              <tr>
                <td>Security Management</td>

                <td className="text-center"></td>
                <td className="text-center"></td>
                <td className="text-end">${viewInvoice?.securityPrice}</td>
              </tr>
            ) : null}
            {viewInvoice?.asset === "asset" ? (
              <tr>
                <td>Asset Management</td>

                <td className="text-center"></td>
                <td className="text-center"></td>
                <td className="text-end">${viewInvoice?.assetPrice}</td>
              </tr>
            ) : null}
            {viewInvoice?.fileManagement === "fileManagement" ? (
              <tr>
                <td>File Management</td>

                <td className="text-center"></td>
                <td className="text-center"></td>
                <td className="text-end">
                  ${viewInvoice?.fileManagementPrice}
                </td>
              </tr>
            ) : null}
            {viewInvoice?.bookings === "booking" ? (
              <tr>
                <td>Booking Mangement</td>

                <td className="text-center"></td>
                <td className="text-end"></td>
                <td className="text-center">${viewInvoice?.bookingsPrice}</td>
              </tr>
            ) : null}
            <tr>
              <td className="no-line"></td>
              <td className="no-line"></td>
              <td className="no-line text-center">
                <strong>Subtotal</strong>
              </td>
              <td className="no-line text-end"></td>
            </tr>
            <tr>
              <td className="no-line"></td>
              <td className="no-line"></td>
              <td className="no-line text-center">
                <strong>Discount</strong>
              </td>
              <td className="no-line text-end"></td>
            </tr>
            <tr>
              <td className="no-line"></td>
              <td className="no-line"></td>
              <td className="no-line text-center">
                <strong>Vat</strong>
              </td>
              <td className="no-line text-end">${viewInvoice?.vatAmount}</td>
            </tr>

            <tr>
              <td className="no-line"></td>
              <td className="no-line"></td>
              <td className="no-line text-center">
                <strong>Total</strong>
              </td>
              <td className="no-line text-end">
                <h4 className="m-0">${viewInvoice?.totalAmount}</h4>
              </td>
            </tr>
          </tbody>
        </table>
      </div> */}
    </div>
  );
};

export default ViewInvoice;

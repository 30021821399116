import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Container, Row, Card, CardBody, Button, Spinner } from "reactstrap";
import { Modal } from "react-bootstrap";
import { MDBDataTable } from "mdbreact";
import { withRouter, Link, useParams } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import MetaTagComp from "../../../components/Common/MetaTags";
import { CSVLink } from "react-csv";
import { useStore1Selector } from "../../../index";
import { loginUser } from "../../../Redux/Slices/userSlice";
import usePost from "../../../hooks/usePost";
import useFecth from "../../../hooks/useFecth";
// import ClientForm from "../ClientProfile";
import QuotationForm from "./viewForm";

// import TopMenu from "./Components/TopMenu";

function AllQuotation() {
  const userDet = useStore1Selector(loginUser);
  const token = userDet?.token;
  const { execute, pending } = usePost();
  const { data: getAllQuotation, reFetch: reFetchGetAllQuotation } = useFecth(
    `https://gh.prop.faspro24.com/api/v1/quotations`,
    token
  );

  // console.log("getAllQuotation....", getAllQuotation);

  const [EditModal, setEditModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [delteID, setDeleteID] = useState();
  const [paidID, setPaidID] = useState();
  const [cancelID, setCancelID] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [paidModal, setPaidModal] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const [leadID, setLeadID] = useState();

  const [viewID, setViewID] = useState();
  const [viewQuotation, setViewQuotation] = useState();
  const listQuotations = [];
  getAllQuotation?.forEach((res) => {
    // console.log("list...", res);
    listQuotations.push({
      ...res,
      firstName: res?.leadId?.firstName,
      lastName: res?.leadId?.lastName,
      email: res?.leadId?.email,
      generatedBy: `${
        res?.leadId?.salesId?.firstName
      } ${res?.leadId?.salesId?.lastName?.substring(0, 1)}.`,
      quotation_status: (
        <label
          className={
            res?.status === "paid"
              ? "text-success"
              : res?.status === "pending"
              ? "text-warning"
              : res?.status === "unpaid"
              ? "text-danger"
              : res?.status === "canceled"
              ? "text-danger"
              : null
          }
        >
          {res?.status === "paid"
            ? "Complete"
            : res?.status === "pending"
            ? "Pending"
            : res?.status === "unpaid"
            ? "Unpaid"
            : res?.status === "canceled"
            ? "Canceled"
            : null}
        </label>
      ),

      viewBtn: (
        <button
          className="btn btn-dark"
          onClick={() => viewFunc(res?._id, res)}
        >
          view
        </button>
      ),
      editBtn: (
        <Link
          to={`/edit-quotation/${res?.leadId?.id}/${res?._id}
`}
        >
          <button
            className="btn btn-primary"
            // onClick={() => EditFunc(res?.leadId?.id)}
          >
            Edit
          </button>
        </Link>
      ),
      DeleteBtn: (
        <button className="btn btn-danger" onClick={() => deleteFunc(res?._id)}>
          Delete
        </button>
      ),
      PaidBtn: (
        <button
          className="btn btn-success"
          onClick={() => paidFunc(res?._id, res?.leadId?._id)}
        >
          {paidModal && paidID === res?._id && leadID === res?.leadId?._id ? (
            <>
              <Spinner as="span" animation="border" size="sm" />{" "}
              <span>Loading</span>
            </>
          ) : (
            "Confirm"
          )}
        </button>
      ),
      CancelBtn: (
        <button
          className="btn btn-warning"
          onClick={() => cancelFunc(res?._id, res?.leadId?._id)}
        >
          Cancel
        </button>
      ),
    });
  });

  function viewFunc(id, quotation) {
    setViewModal(true);
    setViewID(id);
    setViewQuotation(quotation);
  }

  // function EditFunc(id) {
  //   const Method = "PATCH",
  //     endPoint = `leads/${id}`;
  //   const raw = JSON.stringify({
  //     status: "pending",
  //   });
  //   execute(endPoint, raw, Method, "Successfully moved to pending", token);
  //   setTimeout(() => {
  //     reFetchGetAllQuotation();
  //   }, 2000);
  // }
  function deleteFunc(id) {
    setDeleteID(id);
    setDeleteModal(true);
  }
  const deleteQuotation = () => {
    const Method = "DELETE",
      endPoint = `quotations/${delteID}`,
      isJSON = true;
    var formdata = new FormData();
    execute(
      endPoint,
      formdata,
      Method,
      "Quotation successfully deleted",
      token,
      isJSON
    );
    setTimeout(() => {
      reFetchGetAllQuotation();
    }, 2000);
    setDeleteModal(false);
  };

  function paidFunc(QuotId, idLead) {
    setPaidID(QuotId);
    setLeadID(idLead);
    setPaidModal(true);
  }
  const paidQuotation = () => {
    const Method = "PATCH",
      endPoint = `quotations/${paidID}/${leadID}`;
    const raw = JSON.stringify({
      quotationStatus: "paid",
      leadStatus: "qualify",
    });
    execute(endPoint, raw, Method, "Quotation successfully paid", token);
    setTimeout(() => {
      reFetchGetAllQuotation();
    }, 2000);
    setPaidModal(false);
  };
  function cancelFunc(QuotId, idLead) {
    setCancelID(QuotId);
    setLeadID(idLead);
    setCancelModal(true);
  }
  const cancelQuotation = () => {
    const Method = "PATCH",
      endPoint = `quotations/${cancelID}/${leadID}`;
    const raw = JSON.stringify({
      quotationStatus: "canceled",
      leadStatus: "un-qualify",
    });
    execute(endPoint, raw, Method, "Quotation successfully canceled", token);
    setTimeout(() => {
      reFetchGetAllQuotation();
    }, 2000);
    setCancelModal(false);
  };

  const column = [
    { label: "First name", field: "firstName", sort: "asc", width: 150 },
    { label: "Last name", field: "lastName", sort: "asc", width: 150 },
    { label: "Email", field: "email", sort: "asc", width: 150 },
    { label: "Generated By", field: "generatedBy", sort: "asc", width: 150 },
    {
      label: "Quotation Number",
      field: "quotationNumber",
      sort: "asc",
      width: 150,
    },
    { label: "Status", field: "quotation_status", sort: "asc", width: 150 },
    { label: "View", field: "viewBtn", sort: "asc", width: 150 },
    { label: "Edit", field: "editBtn", sort: "asc", width: 150 },
    { label: "Delete", field: "DeleteBtn", sort: "asc", width: 150 },
    { label: "Action", field: "PaidBtn", sort: "asc", width: 150 },
    { label: "cancel", field: "CancelBtn", sort: "asc", width: 150 },
  ];

  const newData = {
    columns: column,
    rows: listQuotations.reverse(),
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTagComp meta_tags="FASPRO 24 | Leads" />
        <Breadcrumb default="Leads" title="Quotation" />

        <CardBody>
          <Container fluid>
            {/* <Link to={`/property-detail/${id}`} className="btn login-btn mb-2">
              {" "}
              Back{" "}
            </Link> */}
            <Row className="d-flex justify-content-around align-items-center">
              <CardBody data-aos="fade-bottom">
                <Card className="bd-rds">
                  <CardBody>
                    <MDBDataTable
                      entries={5}
                      entriesOptions={[5, 10, 50]}
                      responsive
                      bordered
                      striped
                      hover
                      data={newData}
                    />
                  </CardBody>
                </Card>
              </CardBody>
            </Row>
          </Container>
        </CardBody>
      </div>
      <Modal show={viewModal} onHide={() => setViewModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>View Quotation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <QuotationForm
            // viewID={viewID}
            viewQuotation={viewQuotation}
          />
        </Modal.Body>
      </Modal>

      <Modal show={deleteModal} onHide={() => setDeleteModal(false)} size="sm">
        <Modal.Body className="text-danger">
          <h6> Are you sure you want to delete this ? </h6>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="bg-danger text-white"
            onClick={() => setDeleteModal(false)}
          >
            Cancel
          </Button>
          <Button className="bg-success text-white" onClick={deleteQuotation}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={paidModal} onHide={() => setPaidModal(false)} size="sm">
        <Modal.Body className="text-success">
          <h6> You are about to set this quotation to complete </h6>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="bg-danger text-white"
            onClick={() => setPaidModal(false)}
          >
            Cancel
          </Button>
          <Button className="bg-success text-white" onClick={paidQuotation}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={cancelModal} onHide={() => setCancelModal(false)} size="sm">
        <Modal.Body className="text-danger">
          <h6> Are you sure you want to cancel this ? </h6>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="bg-danger text-white"
            onClick={() => setCancelModal(false)}
          >
            Cancel
          </Button>
          <Button className="bg-success text-white" onClick={cancelQuotation}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

AllQuotation.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};
export default withRouter(withTranslation()(AllQuotation));

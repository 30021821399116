import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Col, Row, Button, Spinner, Modal } from "reactstrap";
import {
  AvForm,
  AvField,
  AvCheckboxGroup,
  AvCheckbox,
} from "availity-reactstrap-validation";
import { countryList } from "../../components/Countries";
import Img_600 from "./../../assets/images/img-size/600x400.gif";
import { useStore1Selector, useStore1Dispatch } from "../../index";
import { loginUser } from "../../Redux/Slices/userSlice";
import { Login } from "../../Redux/Slices/userSlice";
import usePost from "../../hooks/usePost";
import useFecth from "../../hooks/useFecth";

function ProfileDetailsForm({ profileServer }) {
  let history = useHistory();
  const userDet = useStore1Selector(loginUser);
  const dispatch = useStore1Dispatch();
  const { execute, pending, data: postDetails } = usePost();
  const token = userDet?.token;

  const userId = userDet?.data?._id;
  const { data: getMydetails, reFetch: reFetchGetMydetails } = useFecth(
    `https://gh.prop.faspro24.com/api/v1/users/me`,
    token
  );

  const handleValidSubmit = (e, v) => {
    profileServer;
    e.preventDefault();
    const Method = "PATCH",
      endPoint = `users/updateMe`,
      isJSON = true;
    const formdata = new FormData();
    {
      !profileServer ? null : formdata.append("profilePic", profileServer);
    }
    {
      !v.firstName ? null : formdata.append("firstName", v.firstName);
    }
    {
      !v.lastName ? null : formdata.append("lastName", v.lastName);
    }
    {
      !v.email ? null : formdata.append("email", v.email);
    }
    execute(
      endPoint,
      formdata,
      Method,
      "Profile successfully updated",
      token,
      isJSON
    );
  };

  const Logout = () => {
    const Method = "POST",
      endPoint = "auth/logout";
    const raw = "";
    execute(
      endPoint,
      raw,
      Method,
      "You will be redirected to login page",
      token
    );
    dispatch(Login(""));
    localStorage.removeItem("persist:users");
    setTimeout(() => {
      history.push(`/login`);
    }, 3000);
  };

  if (postDetails?.status === "success") {
    Logout();
  }

  return (
    <React.Fragment>
      <AvForm
        className="form-horizontal"
        onValidSubmit={(e, v) => {
          handleValidSubmit(e, v);
        }}
      >
        <Row>
          <Col md={6}>
            <div className="mb-3">
              <AvField
                name="firstName"
                value={getMydetails?.firstName}
                label="First Name"
                className="form-control"
                type="text"
                required
              />
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-3">
              <AvField
                name="lastName"
                value={getMydetails?.lastName}
                label="Last Name"
                className="form-control"
                type="text"
                required
              />
            </div>
          </Col>
          <Col md={12}>
            <div className="mb-3">
              <AvField
                name="email"
                value={getMydetails?.email}
                label="Email"
                className="form-control"
                type="text"
                required
              />
            </div>
          </Col>
        </Row>

        <div className="mb-3 row ">
          <div className="col-12 d-flex justify-content-center">
            <button
              className="btn login-btn w-md waves-effect waves-light"
              type="submit"
            >
              {!pending ? <span className="me-2"> Update</span> : null}

              {!pending ? null : (
                <span>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    className="me-2"
                  />
                  Loading...
                </span>
              )}
            </button>
          </div>
        </div>
      </AvForm>
    </React.Fragment>
  );
}

export default ProfileDetailsForm;

import React from "react";
import { Row, Col } from "reactstrap";

const PropertyForm = ({ propertyDetails }) => {
  return (
    <div className="w-100">
      <Row>
        <Col md={6}>
          <div className="w-100" style={{ height: 200 }}>
            <img
              src={propertyDetails?.propertyImage}
              className="w-100 h-100 rounded-4"
            />
          </div>
        </Col>
        <Col md={6}>
          <div className="mb-3 d-flex justify-content-center">
            <h2>Property Details</h2>
          </div>
          <div className="w-100">
            <span className="fw-bold">Property Name :</span>
            <span className="mx-2">{propertyDetails?.propertyName}</span>
          </div>
          <div className="w-100">
            <span className="fw-bold ">Property Type :</span>
            <span className="mx-2 text-primary">
              {propertyDetails?.propertyType}
            </span>
          </div>
          <div className="w-100">
            <span className="fw-bold ">Property Category :</span>
            <span className="mx-2">{propertyDetails?.propertyCategory}</span>
          </div>
          <div className="w-100">
            <span className="fw-bold ">Address :</span>
            <span className="mx-2">{propertyDetails?.address}</span>
          </div>
          <div className="w-100">
            <span className="fw-bold ">City :</span>
            <span className="mx-2">{propertyDetails?.city}</span>
          </div>
          <div className="w-100">
            <span className="fw-bold ">Country :</span>
            <span className="mx-2">{propertyDetails?.country}</span>
          </div>
          <div className="w-100">
            <span className="fw-bold">Owner :</span>
            <span className="mx-2">
              {propertyDetails?.userId?.firstName}{" "}
              {propertyDetails?.userId?.lastName}
            </span>
          </div>
        </Col>
      </Row>
      <hr></hr>
      {/* <div className="w-100 d-flex justify-content-center"> */}
      <Row>
        <Col md={6}>
          <div className="mt-2">
            <h3>Owner Details</h3>
            <div className="mt-2 ">
              <span className="fw-bold">Full Name :</span>
              <span className="mx-2">
                {propertyDetails?.userId?.firstName}{" "}
                {propertyDetails?.userId?.lastName}
              </span>
            </div>
            <div>
              <span className="fw-bold">Cellphone :</span>
              <span className="mx-2">
                {`+${propertyDetails?.userId?.phoneNumber}`}
              </span>
            </div>
            <div>
              <span className="fw-bold">Email :</span>
              <span className="mx-2">{propertyDetails?.userId?.email}</span>
            </div>
          </div>
        </Col>
        <Col md={6}>
          <div className="mt-2">
            <h3>Property Features</h3>
            <div className="mt-3 d-flex" style={{ flexWrap: "wrap" }}>
              {propertyDetails?.propertyFeatures?.map((feature, i) => {
                return (
                  <div
                    key={i}
                    style={{
                      backgroundColor: "#26A958",
                      color: "white",
                      paddingLeft: 10,
                      paddingRight: 10,
                      paddingTop: 4,
                      paddingBottom: 4,
                      borderRadius: 15,
                      marginLeft: 3,
                      marginRight: 3,
                      marginTop: 7,
                    }}
                  >
                    {feature}
                  </div>
                );
              })}
            </div>
          </div>
        </Col>
      </Row>
      {/* </div> */}
    </div>
  );
};

export default PropertyForm;
